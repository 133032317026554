import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import checkPageUrl from "../../../helpers";
import "./Footer.css"
function Footer() {
    const content = useSelector((state) => state.Home.HomeDetail);
    let urlFlag = checkPageUrl(window.location.hostname);
    const { cityName, userType } = useParams();
    const currentYear = new Date().getFullYear();
    return (
        <Fragment>
            <hr className="m-0" />
            <div className="container footer mt-5">
                <div className="row">
                    <div className="col-lg-5 col-md-6 col-sm-12 mb-4">
                        <div className="footer-image mb-3">
                            <img 
                            // src="/Assets/img/header/Daksh-logo.png" 
                            src={content?.footerLogo}
                            alt="Footer Logo"
                            />
                        </div>
                        <div class="our-dedication-and footer-size text-start">{content?.motto}</div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 mb-3">
                        <div className="mb-2">
                            <b className="footer-size">Quick Links</b>
                        </div>
                        <div>
                            <ol className="Quick-Links p-0 text-nowrap">
                                <li>
                                    <i class="fa-solid fa-chevron-right footer-icon-size mx-2"></i>
                                    <Link to={`${urlFlag ? `/${cityName}/${userType}` : ``}/about-us`} className="footer-size">About Us</Link>
                                    {/* <a className="footer-size">About Us</a> */}
                                </li>
                                <li>
                                    <i class="fa-solid fa-chevron-right footer-icon-size  mx-2"></i>
                                    <Link to={`${urlFlag ? `/${cityName}/${userType}` : ``}/terms`} className="footer-size">Terms and Conditions</Link>
                                    {/* <a className="footer-size">Terms and Conditions</a> */}
                                </li>
                                <li>
                                    <i class="fa-solid fa-chevron-right footer-icon-size  mx-2"></i>
                                    <Link to={`${urlFlag ? `/${cityName}/${userType}` : ``}/policy`} className="footer-size">Privacy Policy</Link>
                                    {/* <a className="footer-size">Priavte Policy</a> */}
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="col-lg-5  col-md-6 col-sm-12 mb-3">
                        <div className="mb-1">
                            <b className="footer-size">Contact Us</b>
                        </div>
                        <div>
                            <ul className="Contact-Us p-0">
                                <div className="d-flex mb-1">
                                    <img class="tabler-icon-map-pin mx-1" alt={content?.copyright} src="/Assets/img/footer/tabler-icon-map-pin.svg" />
                                    <div class="daksh-india-garhi  footer-size p-1">{content?.address} {content?.city} {content?.state},  {content?.pincode}</div>
                                </div>
                                <div className="d-flex mb-1">
                                    <img class="tabler-icon-mail mx-1" alt={content?.copyright} src="/Assets/img/footer/tablericonmail.svg" />
                                    <div class="dakshindiaproductsgmailcom  footer-size p-1">{content?.email}</div>
                                </div>
                                <div className="d-flex">
                                    <img class="tabler-icon-phone footer-size mx-1" alt={content?.copyright} src="/Assets/img/footer/tablericonphone.svg" />

                                    <div class="div p-1 footer-size">+91 {content?.phone}</div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="m-0" />
                <div className="row mt-3 mb-4">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div class="daksh-india-all-container ">
                            <span className="footer-size">© {currentYear} </span>
                            <b className="footer-size"> {content?.copyright}</b>
                            <span className="footer-size">. All Rights Reserved.</span>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default Footer;
