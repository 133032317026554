import { Switch } from "antd";
import { Link } from "react-router-dom";
import { useState } from "react";
import '../Coupon/Coupon.css'
import CouponSwitch from "./CouponSwitch";
import "react-datepicker/dist/react-datepicker.css";
import AddCouponModal from "../Modal/AddCouponModal";


const Coupons = () => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);

    const openAddModal = () => {
        setShowAddModal(true);
    };

    const closeAddModal = () => {
        setShowAddModal(false);
    };

    const openEditModal = () => {
        debugger
        setShowEditModal(true);
    };

    const closeEditModal = () => {
        setShowEditModal(false);
    };

    const dummyData = [
        { id: 1, name: 'Mark', couponType: 'Otto', valueType: '@mdo', value: 200, status: "active" },
        { id: 2, name: 'John', couponType: 'Doe', valueType: '@jdoe', value: 150, status: "active" },
        { id: 3, name: 'Alice', couponType: 'Smith', valueType: '@asmith', value: 100, status: "active" },
        { id: 4, name: 'Bob', couponType: 'Johnson', valueType: '@bjohnson', value: 250, status: "inactive" },
        { id: 5, name: 'Emily', couponType: 'Williams', valueType: '@ewilliams', value: 300, status: "active" }
    ];

    return (
        <>
            <div className="col-12">
                <div className="row-title px-2">
                    <div>
                        <h6 className="fw-bold mt-3 mx-4">Manage Coupon</h6>
                    </div>
                    <div className="mt-3">
                        <Link
                        onClick={openAddModal}
                        className="btn btn-outline-warning text-dark">
                            <i className="fa fa-plus" />
                            Add
                        </Link>
                        <Link
                            to={"/admin/product-add-form"}
                            className="btn btn-outline-warning text-dark mx-2">
                            <i class="fa-solid fa-download"></i>
                            Report
                        </Link>
                    </div>
                </div>
                <AddCouponModal show={showAddModal} handleClose={closeAddModal} />
                </div>
            <div className="row mx-3 my-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <input
                                type="text" class="form-control mx-2 mt-4"
                                aria-label="Text input with checkbox"
                                placeholder='Name'
                                maxLength={150}
                            />
                            <div className="col-2 mx-3">
                                <label className="form-label">
                                </label>
                                <select
                                    name=""
                                    id=""
                                    className="form-select form-control"
                                    required>
                                    <option value="0">--Coupon Type--</option>
                                    <option value="0">Discount</option>
                                    <option value="0">Gift</option>
                                </select>
                            </div>
                            <div className="col-2 mx-3">
                                <label className="form-label">
                                </label>
                                <select
                                    name=""
                                    id=""
                                    className="form-select form-control"
                                    required>
                                    <option value="0">--Value Type--</option>
                                    <option value="0">Amount</option>
                                    <option value="0">Percentage</option>
                                </select>
                            </div>
                            <div className="col-2 mx-3">
                                <label className="form-label">
                                </label>
                                <select
                                    name=""
                                    id=""
                                    className="form-select form-control"
                                    required>
                                    <option value="0">--Status--</option>
                                    <option value="0">Active</option>
                                    <option value="0">Inactive</option>
                                </select>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-3 ms-auto">
                                <div className="d-flex mx-3 mt-1">
                                    <a
                                        className="btn btn-sm btn-light-success me-2">
                                        Filter
                                    </a>
                                    <a
                                        className="btn btn-light-secondary btn-sm ">
                                        Clear
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mx-3">
                <div className="card">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">S. No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Coupon Type</th>
                                <th scope="col">Value Type</th>
                                <th scope="col">Value </th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {dummyData.map(item => (
                            <tr key={item.id}>
                                <th scope="row">{item.id}</th>
                                <td>{item.name}</td>
                                <td>{item.couponType}</td>
                                <td>{item.valueType}</td>
                                <td>{item.value}</td>
                                <td><CouponSwitch status={item.status} /></td>
                                <td>
                                    <div>
                                        <a onClick={openEditModal}>
                                            <i style={{ color: '#007185' }} className="fa-regular fa-pen-to-square mx-1"></i>
                                        </a>
                                        <a><i style={{ color: '#007185' }} className="fa-regular fa-copy mx-1"></i></a>
                                        <a><i style={{ color: 'red' }} className="fa-solid fa-xmark mx-1"></i></a>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <AddCouponModal show={showEditModal} handleClose={closeEditModal} className="custom-modal"/>
                    </div>
            </div>
        </>
    )
}
export default Coupons;
