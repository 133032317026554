import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { useSelector } from "react-redux";
import { CheckValidUser } from "./CheckForValidUser";
import checkPageUrl from "../../helpers";
import { getCartCount } from "../../redux/features/cartSlice";

const UserLayout = () => {
    const [isLoader, setIsLoader] = useState(false);
    const { cityName, userType } = useParams();
    const [validUser, setValidUser] = useState(false);
    const [headerCityName, setHeaderCityName] = useState("");
    const [headerUserType, setHeaderUserType] = useState("");
    const [cityId, setCityId] = useState();
    const [userTypeId, setUserTypeId] = useState();
    const [searchText, setSearchText] = useState("");
    const [categoryId, setCategoryId] = useState(0);
    let urlFlag = checkPageUrl(window.location.hostname);
    const [flagForPage, setFlagForPage] = useState(urlFlag);
    const navigate = useNavigate();
    const CartCount = useSelector(state => state.Cart.CartCount)

    useEffect(() => {
        if (flagForPage === true) {
            checkForValidUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getCartCount();
    }, [CartCount])


    const content = useSelector((state) => state.Home.HomeDetail);

    const checkForValidUser = async () => {
        setIsLoader(true);
        const checkValidUser = await CheckValidUser(cityName, userType);
        // if (urlFlag && !checkValidUser){
        //     navigate("/AboutUs");
        //   }
        setValidUser(checkValidUser);

        if (!checkValidUser) {
            navigate("/about-us");
        } else {
            setCityId(localStorage.getItem("cityId"));
            setUserTypeId(localStorage.getItem("userTypeId"));
            setHeaderCityName(localStorage.getItem("headerCityName"));
            setHeaderUserType(localStorage.getItem("headerUserType"));
        }
        setIsLoader(false);
    };

    const navigateToProductDetails = (productId) => {
        if (urlFlag === true && content?.sellerId) {
            window.open(`/${cityName}/${userType}/product-details/${productId}`, "_blank", "noopener");
        } else if (urlFlag === false && content?.sellerId) {
            window.open(`/product-details/${productId}`, "_blank", "noopener");
        }
    };

    const handleClick = () => {
        setIsLoader(true);
        // if (searchText.trim().length !== 0) {
        // // debugger;
        // console.log(urlFlag + " " + content?.sellerId);
        if (urlFlag && content?.sellerId) {
            navigate(`/${cityName}/${userType}/category/${categoryId}?searchText=${searchText}`, {
                state: {
                    searchText: searchText,
                    isGlobalSearchNavigation: true,
                },
            });
        } else if (!urlFlag && content?.sellerId) {
            navigate(`category/${categoryId}?searchText=${searchText}`, {
                state: {
                    searchText: searchText,
                    isGlobalSearchNavigation: true,
                },
            });
        }
        // }
        setIsLoader(false);
    };

    const handleChange = (e) => {
        setSearchText(e.target.value);
    };

    return (
        <>
            <Header
                item={CartCount}
                searchText={searchText}
                cityName={cityName}
                userType={userType}
                headerCityName={headerCityName}
                headerUserType={headerUserType}
                handleClick={handleClick}
                handleChange={handleChange}
                validUser={validUser}
                setCategoryId={setCategoryId}
                categoryId={categoryId}

            />
            <Outlet />
            <Footer />
        </>
    );
};

export default UserLayout;
