import ApiConstants from "../Constants/ApiConstants";
import { apiGet, apiPut, apiPost, apiDelete, apiPostWithAuthorization } from "./apiCall";

export const GetDashboardQuickLink = async () => {
    const response = await apiGet(ApiConstants.GET_DASHBOARD_QUICK_LINK);
    return response;
};

export const GetProfile = async (sellerId) => {
    const response = await apiGet(ApiConstants.GET_ADMIN_PROFILE + "?sellerId=" + sellerId);
    return response;
};

export const GetDashboardSummary = async () => {
    const response = await apiGet(ApiConstants.GET_DASHBOARD_SUMMARY);
    return response;
};

export const GetProductCode = async () => {
    const response = await apiGet(ApiConstants.GET_PRODUCT_CODE);
    return response;
};

export const GetProductList = async (pageNum, pageLimit, searchCode, searchProductName, searchCategoryId, searchCityId, searchUserTypeId, searchStatusId, searchVendorId, sortColumn, sortOrder, source) => {
    const promise = await apiGet(ApiConstants.GET_PRODUCT_LIST + "?pageNum=" + pageNum + "&pageSize=" + pageLimit + "&name=" + searchProductName + "&code=" + searchCode + "&categoryId=" + searchCategoryId + "&cityId=" + searchCityId + "&userTypeId=" + searchUserTypeId + "&status=" + searchStatusId + "&vendor=" + searchVendorId + "&sortColumn=" + sortColumn + "&sortOrder=" + sortOrder + "&source=" + source);
    return promise;
};

export const GetProductListForSeller = async (pageNum, pageLimit, searchCode, searchProductName, searchCategoryId, searchCityId, searchUserTypeId, searchStatusId, searchVendorId, sortColumn, sortOrder, sellerId, source) => {
    const promise = await apiGet(ApiConstants.GET_PRODUCT_LIST_FOR_SELLER + "?pageNum=" + pageNum + "&pageSize=" + pageLimit + "&name=" + searchProductName + "&code=" + searchCode + "&categoryId=" + searchCategoryId + "&cityId=" + searchCityId + "&userTypeId=" + searchUserTypeId + "&status=" + searchStatusId + "&vendor=" + searchVendorId + "&sortColumn=" + sortColumn + "&sortOrder=" + sortOrder + "&sellerId=" + sellerId + "&source=" + source);
    return promise;
};

export const GetQuoteList = async (pageNum, pageLimit, searchQuoteNo, searchCustomerName, searchCityId, searchUserTypeId, selectedStatus, startDate, endDate, sortField, sortOrder) => {
    const promise = await apiGet(ApiConstants.GET_QUOTE_LIST + "?pageNum=" + pageNum + "&pageSize=" + pageLimit + "&customerName=" + searchCustomerName + "&rfqStatus=" + selectedStatus + "&startDate=" + startDate + "&endDate=" + endDate + "&quotationNo=" + searchQuoteNo + "&cityId=" + searchCityId + "&userTypeId=" + searchUserTypeId + "&sortColumn=" + sortField + "&sortOrder=" + sortOrder);
    return promise;
};

export const GetQuoteListOld = async (pageNum, pageLimit, searchQuoteNo, searchCustomerName, searchCityId, searchUserTypeId, startDate, endDate, sortField, sortOrder) => {
    const promise = await apiGet(ApiConstants.GET_QUOTE_LIST_OLD + "?pageNum=" + pageNum + "&pageSize=" + pageLimit + "&customerName=" + searchCustomerName + "&startDate=" + startDate + "&endDate=" + endDate + "&quotationNo=" + searchQuoteNo + "&cityId=" + searchCityId + "&userTypeId=" + searchUserTypeId + "&sortColumn=" + sortField + "&sortOrder=" + sortOrder);
    return promise;
};

export const GetProductDetails = async (id) => {
    const response = await apiGet(ApiConstants.GET_PRODUCT_DETAILS + id);
    return response;
};

export const GetProductDetailsBySeller = async (sellerId, id) => {
    const response = await apiGet(ApiConstants.GET_PRODUCT_DETAILS_BY_SELLER_ID + "?sellerId=" + sellerId + "&productId=" + id);
    return response;
};


export const UpdateProductDetails = async (id, body) => {
    const response = await apiPut(ApiConstants.UPDATE_PRODUCT_DETAILS + id, body);
    return response;
};

export const saveProduct = async (body) => {
    const config = {
        headers: {
            Authorization: localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    };
    const response = await apiPost(ApiConstants.ADD_PRODUCT, body, config);
    return response;
};

export const GetQuoteDetails = async (id) => {
    const response = await apiGet(ApiConstants.QUOTE_DETAILS + id);
    return response;
};

export const GetQuoteDetailsOld = async (id) => {
    const response = await apiGet(ApiConstants.QUOTE_DETAILS_OLD + id);
    return response;
};

export const DeleteProduct = async (id) => {
    const response = await apiDelete(ApiConstants.GET_PRODUCT_LIST + id);
    return response.data;
};

export const ValidateProductCode = async (code) => {
    const response = await apiGet(ApiConstants.CHECK_PRODUCT_CODE_EXISTS + code);
    return response;
};

export const PostAnnouncement = async(body) =>{
    const response= await apiPostWithAuthorization(ApiConstants.POST_ANNOUNCEMENT , body );
    return response;
}
export const apiGetAnnouncements = async (id) => {
    const response = await apiGet(ApiConstants.GET_ANNOUNCEMENTS + id + '/announcement') ;
    return response;
}

export const apiGetBannerImages = async (id) => {
    const response = await apiGet(ApiConstants.GET_BANNER_IMAGES + id + '/get-banner-images'  )
    return response;
}

export const apiPostBannerImages = async (body, id) =>{
    const response = await apiPostWithAuthorization(ApiConstants.POST_BANNER_IMAGES + id  + '/add-banner-images', body)
    return response
}

export const apiUpdateBannerImages = async (body , id) => {
    const response = await apiPut(ApiConstants.UPDATE_BANNER_IMAGES + id + '/edit-banner-images', body)
    return response ;
}

export const apiDeleteBannerImages = async (sellerId , bannerID) => {
    const response = await apiPut(ApiConstants.DELETE_BANNER_IMAGES + sellerId +'/delete-banner-images/' + bannerID )
    return response ;
}