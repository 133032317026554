import { useEffect } from "react";
import { GeneratePaymentOrder, GetOrderStatus } from "../../../services/paymentService";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Loader from "../../Common/Loader";
import { useState } from "react";
import { toast } from "react-toastify";

function PaymentConfirmation() {
    const cashfree = window.Cashfree({
        // mode: "production",
        mode: process.env.REACT_APP_CASHFREE_MODE,
    });
    const dispatch = useDispatch();
    const [paymentMessage, setPaymentMessage] = useState("Pending");
    const [isLoader, setIsLoader] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const { dakshOrderId, requestBody, orderId, sellerUrl } = useParams();
    const [hold, setHold] = useState(0);

    const deserializeRequestBody = (base64String) => {
        if (dakshOrderId && dakshOrderId !== "null") {
            getOrderStatus(dakshOrderId);
        } else {
            const urlEncodedData = JSON.parse(decodeURIComponent(base64String));
            generatePaymentOrder(urlEncodedData?.dakshOrderId, urlEncodedData?.paymentRequest, urlEncodedData?.sellerUrl);
        }
    };

    const generatePaymentOrder = async (dakshOrderId, paymentRequest, sellerUrl) => {
        showLoader();
        try {
            const response = await GeneratePaymentOrder(dakshOrderId, paymentRequest);
            if (response?.statusCode === 200) {
                response?.result?.order_status === "ACTIVE" && cashfreeCheckout(response?.result?.payment_session_id, response?.result?.order_id, sellerUrl, dakshOrderId);
            } else {
                window.location.href = `${sellerUrl}/my-cart/failed`;
            }
        } catch (error) {
            window.location.href = `${sellerUrl}/my-cart/failed`;
        }
        showLoader();
    };

    useEffect(() => {
        deserializeRequestBody(requestBody);
    }, []);

    const cashfreeCheckout = async (payment_session_id, order_id, seller_url, dakshOrderId) => {
        showLoader();

        let checkoutOptions = {
            paymentSessionId: payment_session_id,
            return_url: `${process.env.REACT_APP_PAYMENT_GATEWAY}sometext/${order_id}/${btoa(seller_url)}/${dakshOrderId}`,
        };
        console.log(checkoutOptions);

        try {
            await cashfree.checkout(checkoutOptions).then(function (result) {
                if (result?.error) {
                    window.location.href = `${seller_url}/my-cart/failed`;
                }
                if (result?.redirect) {
                    console.log("Redirection");
                }
            });
        } catch (error) {
            console.log("SOMETHING WENT WRONG WITH THE PAYMENT GATEWAY", error);
            window.location.href = `${seller_url}/my-cart/failed`;
        }
        showLoader();
    };

    var counter = 0;
    const getOrderStatus = async (dakshOrderId) => {
        showLoader();
        //setHold(hold + 1);
        try {
            const response = await GetOrderStatus(dakshOrderId);

            if (!response?.isError) {
                let payment_status = response?.result?.status;
                let baseUrl = `https://${response?.result?.webUrl}`;
                // baseUrl = `http://localhost:7140`;      //Dev
                if (payment_status === "Cancel" && counter < 3) {
                    setTimeout(() => {
                        setPaymentMessage("Pending.");
                        counter = counter + 1;
                    }, 3000);
                    getOrderStatus(dakshOrderId);
                } else if (payment_status === "Success") {
                    let url = `${baseUrl}/my-cart/${payment_status?.toLowerCase()}`;
                    setPaymentMessage("Success.");
                    return (window.location.href = url);
                } else if (payment_status === "Cancel" || payment_status === "Cancelled") {
                    let url = `${baseUrl}/my-cart/cancelled`;
                    return (window.location.href = url);
                } else if (payment_status === "Failed") {
                    let url = `${baseUrl}/my-cart/failed`;
                    return (window.location.href = url);
                } else {
                    let url = `${baseUrl}/my-cart/pending`;
                    return (window.location.href = url);
                }
            } else {
                toast.error("Some error occured.");
            }
        } catch (error) {
            toast.error("Something went wrong while fetching order status.");
        }
    };

    const showLoader = () => {
        setIsLoader(!isLoader);
    };
    return (
        <>
            {isLoader && <Loader />}
            {/* {isLoaded && ( */}
            <div>
                <p>Please wait while we confirm your payment...</p>
                <p>Do not press back button...</p>
                <h1>Your Payment Status is</h1>
                {paymentMessage}
            </div>
            {/* )} */}
        </>
    );
}

export default PaymentConfirmation;
