/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DeleteProduct, GetProductDetails, GetProductList, GetProductListForSeller } from "../../services/adminService";
import { GetUserType, GetCity, GetCategory, GetVendor } from "../../services/dropdownService";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ProductAddForm from "../ProductAddForm/ProductAddForm";
import "../ProductManagement/ProductManagement.css";
import DeleteAskModal from "../Modal/DeleteAskModal";
import Loader from "../Common/Loader";
import { useSelector } from "react-redux";
import { Pagination, Switch } from "antd";
import { UpdateProductMoqForSeller, UpdateProductStatusForSeller } from "../../services/sellerOnboardingService";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";

function ProdManagement() {
    const [category, setCategory] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [userType, setUserType] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [cityData, setCityData] = useState([]);
    const [productListData, setProductListData] = useState([{ userTypeAndCities: { cities: [] } }]);
    const [code, setCode] = useState("");
    const [productName, setProductName] = useState("");
    const [categoryId, setCategoryId] = useState(0);
    const [cityId, setCityId] = useState(0);
    const [userId, setUserId] = useState(0);
    const [searchCode, setSearchCode] = useState("");
    const [searchProductName, setSearchProductName] = useState("");
    const [searchCategoryId, setSearchCategoryId] = useState(0);
    const [searchCityId, setSearchCityId] = useState(0);
    const [searchUserTypeId, setSearchUserTypeId] = useState(0);
    const [sortColumn, setSortColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [productData, setProductData] = useState({});
    const [totalPages, setTotalPages] = useState();
    const [totalRecords, setTotalRecords] = useState();
    const [pageSize, setPageSize] = useState(50);
    const [pageNum, setPageNum] = useState(1);
    // const [userCities, setUserCities] = useState([]);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [deleteProductId, setDeleteProductId] = useState();
    const [vendor, setVendor] = useState([]);
    const [vendorId, setVendorId] = useState(0);
    const [searchVendorId, setSearchVendorId] = useState(0);
    const [statusId, setStatusId] = useState(-1);
    const [searchStatusId, setSearchStatusId] = useState(-1);
    const [isLoader, setIsLoader] = useState(false);
    const { urlSellerId } = useParams();
    const location = useLocation();
    const urlSellerName = location.state?.sellerName;
    let isDaksh = useSelector((state) => state.auth.isDaksh);
    const sellerId = useSelector((state) => state.auth.sellerId);
    const checkToken = () => {
        if (!localStorage.getItem("token")) {
            return navigate("/SignIn");
        }
    };
    const source = isDaksh ? "admin" : "selleradmin";
    if (window.location.pathname?.substring(0, 32) === "/admin/seller-product-management") {
        isDaksh = false;
    }
    useEffect(() => {
        checkToken();
    });
    const handleSort = (field) => {
        setIsLoader(true);

        // If the same field is clicked again, toggle the sorting order
        if (field === sortColumn) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(field);
            setSortOrder("asc");
        }
        setIsLoader(false);
    };

    useEffect(() => {
        cityHandler();
        categorylist();
        userTypeHandler();
        vendorList();
    }, []);

    const handleCategory = (event) => {
        const getCategoryId = event.target.value;
        setCategoryId(getCategoryId);
    };

    // const handleCity = (event) => {
    //     const getCityId = event.target.value;
    //     setCityId(getCityId);
    // };

    // const handleUser = (event) => {
    //     const getUserId = event.target.value;
    //     setUserId(getUserId);
    // };

    const categorylist = async () => {
        try {
            const response = await GetCategory();
            setCategory(response);
        } catch (error) {
            console.error("Something went wrong", error.message);
        }
    };

    const vendorList = async () => {
        try {
            const response = await GetVendor();
            setVendor(response);
        } catch (error) {
            console.error("Something went wrong", error.message);
        }
    };

    const userTypeHandler = async () => {
        try {
            const response = await GetUserType();
            setUserType(response);
        } catch (error) {
            console.error("Something went wrong", error.message);
        }
    };

    const productListHandler = async () => {
        setIsLoader(true);
        try {
            if (isDaksh) {
                const response = await GetProductList(pageNum, pageSize, searchCode, searchProductName, searchCategoryId, searchCityId, searchUserTypeId, searchStatusId, searchVendorId, sortColumn, sortOrder, source);
                setProductListData(response?.productList);
                // setUserCities(response.prod);
                let page = response?.paginationResponse?.totalRecord;
                setTotalRecords(page);
                setTotalPages(Math.floor(page / pageSize) + 1);
            } else {
                const response = await GetProductListForSeller(pageNum, pageSize, searchCode, searchProductName, searchCategoryId, searchCityId, searchUserTypeId, searchStatusId, searchVendorId, sortColumn, sortOrder, urlSellerId !== undefined ? urlSellerId : sellerId, source);
                setProductListData(response?.sellerProductList);
                // setUserCities(response.prod);
                let page = response?.paginationResponse?.totalRecord;
                setTotalRecords(page);
                setTotalPages(Math.floor(page / pageSize) + 1);
            }
        } catch (error) {
            console.warn("Something went wrong", error.message);
        }
        setIsLoader(false);
    };

    const handleClear = () => {
        setPageNum(1);
        setCode("");
        setProductName("");
        setVendorId(0);
        setStatusId("-1");
        setCategoryId(0);
        setCityId(0);
        setUserId(0);
        setSearchCode("");
        setSearchProductName("");
        setSearchVendorId(0);
        setSearchStatusId("-1");
        setSearchCategoryId(0);
        setSearchCityId(0);
        setSearchUserTypeId(0);
        setSortOrder("");
        setSortColumn("");
    };

    const handleFilterData = () => {
        setPageNum(1);
        if (code !== "") {
            setSearchCode(code);
        } else {
            setSearchCode("");
        }
        setSearchProductName(productName);
        setSearchCategoryId(categoryId);
        setSearchCityId(cityId);
        setSearchUserTypeId(userId);
        setSearchVendorId(vendorId);
        setSearchStatusId(statusId);
        // setSortOrder("");
        // setSortColumn("");
    };

    const cityHandler = async () => {
        try {
            const response = await GetCity();
            setCityData(response);
        } catch (error) {
            console.warn("Something went wrong", error.message);
        }
    };

    const handleDeleteModal = (id) => {
        setShow(true);
        setDeleteProductId(id);
    };

    const DeleteProductHandler = async (id) => {
        try {
            const response = await DeleteProduct(id);
            return response;
        } catch (error) {
            console.warn("Something went wrong", error.message);
        }
    };

    const handleModalClose = () => {
        setShow(false);
    };

    const productGet = async (id) => {
        try {
            const response = await GetProductDetails(id);
            setProductData(response);
        } catch (error) {
            console.warn("Something went wrong", error.message);
        }
        return <ProductAddForm productData={productData} />;
    };

    const pageHandler = (number, size) => {
        setPageNum(number || pageNum);
        setPageSize(size || pageSize);
    };

    const updateProductStatusForSeller = async (status, productId) => {
        try {
            const response = await UpdateProductStatusForSeller(urlSellerId, productId, status);
            
            if (!response?.isError && response?.statusCode === 204) {
                productListHandler();
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateProductMoqForSeller = async (moq, productId) => {
        try {
            const response = await UpdateProductMoqForSeller(urlSellerId, productId, moq);
            
            if (!response?.isError && response?.statusCode === 204) {
                productListHandler();
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const changeMoq = async (productId) => {
        const response = await Swal.fire({
            text: "Please add Minimum Order Quantity for selected product.",
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: "Are you sure?",
            cancelButtonText: "No",
            input: "text",
            customClass: {
                confirmButton: "btn btn-danger bg-danger",
                cancelButton: "btn btn-active-light",
            },
        });
        if (response?.isConfirmed) {
            updateProductMoqForSeller(response?.value, productId);
        }
    };

    useEffect(() => {
        productListHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNum, searchCode, searchProductName, searchCategoryId, searchCityId, searchUserTypeId, searchVendorId, searchStatusId, sortColumn, sortOrder, pageSize]);
    //#region HTML Code
    return (
        <Fragment>
            <DeleteAskModal
                show={show}
                handleClose={handleModalClose}
                apiCall={DeleteProductHandler}
                id={deleteProductId}
                productListHandler={productListHandler}
            />
            <ToastContainer />
            <div className="page-body px-0 py-lg-2 py-1 mt-0 mt-lg-3 ms-3">
                <div className="container-fluid p-0">
                    <div className="row g-3">
                        {/* Product Management header + Add button */}
                        {window.location.pathname.substring(0, 32) === "/admin/seller-product-management" && (
                            <div className="col-12">
                                <div className="row-title px-2">
                                    <div>
                                        <h6 className="fw-bold">{urlSellerName}</h6>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isDaksh && (
                            <div className="col-12">
                                <div className="row-title px-2">
                                    <div>
                                        <h6 className="fw-bold">Product Management</h6>
                                    </div>
                                    <Link
                                        to={isDaksh ? "/admin/product-add-form" : "/seller-admin/product-add-form"}
                                        className="btn btn-outline-warning text-dark">
                                        <i className="fa fa-plus" />
                                        Add
                                    </Link>
                                </div>
                            </div>
                        )}

                        {/* Dash Card 1 */}
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-lg-1 col-md-4 col-sm-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Code"
                                                        value={code}
                                                        onChange={(e) => setCode(e.target.value)}
                                                        // onChange={(e) => searchItems(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-lg-5 col-md-4 col-sm-3">
                                                    <input
                                                        type="search"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        value={productName}
                                                        onChange={(e) => setProductName(e.target.value)}
                                                    />
                                                </div>
                                                {/* Vendor Dropdown */}
                                                {isDaksh && (
                                                    <div className="col-lg-2 col-md-4 col-sm-3">
                                                        <select
                                                            className="form-control form-select"
                                                            tabIndex={-98}
                                                            onChange={(e) => setVendorId(e.target.value)}
                                                            value={vendorId}>
                                                            <option value={0}>-- Vendor --</option>
                                                            {vendor.map((data) => (
                                                                <option
                                                                    value={data.id}
                                                                    key={data.id}>
                                                                    {data.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                )}

                                                {/* Status Dropdown */}
                                                <div className="col-lg-2 col-md-4 col-sm-3">
                                                    <select
                                                        disabled={!isDaksh && window.location.pathname?.substring(0, 32) === "/admin/product-management"}
                                                        name=""
                                                        id=""
                                                        value={statusId}
                                                        className="form-select form-control"
                                                        onChange={(e) => setStatusId(e.target.value)}>
                                                        <option value="-1">--Status--</option>
                                                        <option value="1">Active</option>
                                                        <option value="0">Inactive</option>
                                                    </select>
                                                </div>
                                                {/* categorylist api consume */}
                                                <div className="col-lg-2 col-md-4 col-sm-3">
                                                    <select
                                                        className="form-control form-select"
                                                        tabIndex={-98}
                                                        onChange={(e) => handleCategory(e)}
                                                        value={categoryId}>
                                                        <option value={0}>-- Category --</option>
                                                        {category.map((category) => (
                                                            <option
                                                                value={category.id}
                                                                key={category.id}>
                                                                {category.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                {/* userlist api consume */}
                                                {/* <div className="col-lg-2 col-md-4 col-sm-3"> */}
                                                    {/* <select
                                                        className="form-control form-select"
                                                        tabIndex={-98}
                                                        onChange={(e) => handleUser(e)}
                                                        value={userId}>
                                                        <option value={0}>-- UserType --</option>
                                                        {userType.map((user) => (
                                                            <option
                                                                value={user.id}
                                                                key={user.id}>
                                                                {user.name}
                                                            </option>
                                                        ))}
                                                    </select> */}
                                                {/* </div> */}
                                                {/* City list api consume */}
                                                {/* <div className="col-lg-2 col-md-4 col-sm-3"> */}
                                                    {/* <select
                                                        className="form-control form-select"
                                                        tabIndex={-98}
                                                        onChange={(e) => handleCity(e)}
                                                        value={cityId}>
                                                        <option value={0}>-- City --</option>
                                                        {cityData.map((cityData) => (
                                                            <option
                                                                key={cityData.id}
                                                                value={cityData.id}>
                                                                {cityData.name}
                                                            </option>
                                                        ))}
                                                    </select> */}
                                                {/* </div> */}
                                                <div className="col-lg-2 col-md-2 col-sm-3 ms-auto">
                                                    <div className="d-flex justify-content-end">
                                                        <a
                                                            onClick={handleFilterData}
                                                            className="btn btn-sm btn-light-success me-2">
                                                            Filter
                                                        </a>
                                                        <a
                                                            className="btn btn-light-secondary btn-sm "
                                                            onClick={handleClear}>
                                                            Clear
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Dash Card 2 - Product Table */}
                    <div className="col-12 mt-1">
                        {isLoader && <Loader />}
                        <div className="card">
                            <div className="card-body">
                                <div
                                    id="DataTables_Table_2_wrapper"
                                    className="dataTables_wrapper dt-bootstrap5 no-footer">
                                    <div className="row">
                                        <div className="col-sm-12 px-0 table-responsive">
                                            <div className="col-sm-12 px-0 table-responsive">
                                                <table
                                                    className="myDataTable table table-hover mb-0 nowrap dataTable no-footer dtr-inline"
                                                    id="DataTables_Table_2">
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="DataTables_Table_2"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                style={{ width: "1rem" }}
                                                                aria-label="Code: activate to sort column ascending"
                                                                onClick={() => handleSort("code")}>
                                                                Code
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="DataTables_Table_2"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                style={{ width: "2rem" }}
                                                                aria-label="Product Name: activate to sort column ascending"
                                                                onClick={() => handleSort("name")}>
                                                                Title
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="DataTables_Table_2"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                style={{ width: "2rem" }}
                                                                aria-label="Product Name: activate to sort column ascending"
                                                                onClick={() => handleSort("vendor")}>
                                                                Vendor
                                                            </th>
                                                            {!isDaksh && window.location.pathname?.substring(0, 32) === "/admin/product-management" ? (
                                                                <></>
                                                            ) : (
                                                                <th
                                                                    className="sorting"
                                                                    tabIndex={0}
                                                                    aria-controls="DataTables_Table_2"
                                                                    rowSpan={1}
                                                                    colSpan={1}
                                                                    style={{ width: "2rem" }}
                                                                    aria-label="Product Name: activate to sort column ascending"
                                                                    onClick={() => handleSort("status")}>
                                                                    Status
                                                                </th>
                                                            )}
                                                            <th
                                                                tabIndex={0}
                                                                aria-controls="DataTables_Table_2"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                style={{ width: "2rem" }}
                                                                aria-label="Total Buying: activate to sort column ascending" /*onClick={() => handleSort("basePrice")}*/
                                                            >
                                                                {isDaksh ? "Base Price" : "Selling Price"}
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="DataTables_Table_2"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                style={{ width: "2rem" }}
                                                                aria-label="Type: activate to sort column ascending"
                                                                onClick={() => handleSort("category")}>
                                                                Category
                                                            </th>
                                                            {window.location.pathname?.substring(0, 32) === "/admin/seller-product-management" && (
                                                                <th
                                                                    className="sorting"
                                                                    tabIndex={0}
                                                                    aria-controls="DataTables_Table_2"
                                                                    rowSpan={1}
                                                                    colSpan={1}
                                                                    style={{ width: "2rem" }}
                                                                    aria-label="Type: activate to sort column ascending"
                                                                    onClick={() => handleSort("moq")}>
                                                                    MOQ
                                                                </th>
                                                            )}
                                                            {isDaksh && (
                                                                <th
                                                                    tabIndex={0}
                                                                    aria-controls="DataTables_Table_2"
                                                                    rowSpan={1}
                                                                    colSpan={1}
                                                                    style={{ width: "2rem" }}
                                                                    aria-label="Total Buying: activate to sort column ascending">
                                                                    <center>Actions</center>
                                                                </th>
                                                            )}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {productListData?.length > 0 ? (
                                                            productListData?.map((data) => (
                                                                <tr
                                                                    key={data.id}
                                                                    className="odd">
                                                                    <td>{data?.code}</td>
                                                                    <td>
                                                                        {window.location.pathname?.substring(0, 32) === "/admin/seller-product-management" ? (
                                                                            <> {data?.name}</>
                                                                        ) : (
                                                                            <Link
                                                                                to={isDaksh ? `/admin/product-add-form/${data.id}` : `/seller-admin/product-add-form/${data.id}`}
                                                                                onClick={() => productGet(data.id)}>
                                                                                {data?.name}
                                                                            </Link>
                                                                        )}
                                                                    </td>
                                                                    <td>{data?.vendor !== 0 ? data?.vendor : <p></p>}</td>
                                                                    {!isDaksh && window.location.pathname?.substring(0, 32) === "/admin/product-management" ? (
                                                                        <></>
                                                                    ) : (
                                                                        <td style={{ color: data?.status && data?.status.toLowerCase() === "active" ? "green" : "red" }}>
                                                                            {data?.status}
                                                                            {window.location.pathname?.substring(0, 32) === "/admin/seller-product-management" && (
                                                                                <Switch
                                                                                    style={{ color: data?.status && data?.status.toLowerCase() === "active" ? "green" : "red" }}
                                                                                    className={`mx-1 ${data?.status && data?.status.toLowerCase() === "active" ? "bg-success" : "bg-danger"}`}
                                                                                    loading={false}
                                                                                    checked={data?.status && data?.status.toLowerCase() === "active" ? true : false}
                                                                                    size="small"
                                                                                    onChange={(e) => updateProductStatusForSeller(e, data?.id)}
                                                                                />
                                                                            )}
                                                                        </td>
                                                                    )}
                                                                    {isDaksh ? <td>{data?.basePrice}</td> : <td>{data?.sellingPrice}</td>}
                                                                    <td>{data?.category}</td>
                                                                    {window.location.pathname?.substring(0, 32) === "/admin/seller-product-management" && (
                                                                        <td>
                                                                            {data?.moq}
                                                                            {/* <Link
                                                                                className="mx-3"
                                                                                onClick={() => changeMoq(data?.id)}>
                                                                                <i className="fa fa-pencil text-teritary " />
                                                                            </Link> */}
                                                                        </td>
                                                                    )}
                                                                    {isDaksh && (
                                                                        <td>
                                                                            <center>
                                                                                {data.id != null ? (
                                                                                    <i
                                                                                        className="fa fa-trash"
                                                                                        onClick={() => handleDeleteModal(data.id)}
                                                                                        style={{ cursor: "pointer" }}
                                                                                        aria-hidden="true"></i>
                                                                                ) : (
                                                                                    <p></p>
                                                                                )}
                                                                            </center>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <p className="text-center">No Record Found</p>
                                                        )}
                                                    </tbody>
                                                </table>
                                                <nav className="page-nav">
                                                    <Pagination
                                                        defaultCurrent={pageNum}
                                                        total={totalRecords}
                                                        hideOnSinglePage
                                                        defaultPageSize={pageSize}
                                                        responsive
                                                        onChange={(page, pageSize) => pageHandler(page, pageSize)}
                                                        showQuickJumper
                                                    />
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
    //#endregion
}

export default ProdManagement;
