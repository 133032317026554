import "./ProductFilters.css";
import { useEffect, useRef, useState } from "react";
import {
  GlobalProductCatalog,
  ProductsFilters,
  GlobalProductCatalogBySeller,
} from "../../services/filterService";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Rating } from "react-simple-star-rating";
import "../Home/Featured-Products/Featured-Products.css";
import { useParams } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import checkPageUrl from "../../helpers";
import { AddItemsToCart } from "../../services/handleCartService";
import { getCartCount } from "../../redux/features/cartSlice";
import { Button, Modal, Card } from "react-bootstrap";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { toast, ToastContainer } from "react-toastify";
export const GlobalProductFilter = () => {
  const [Filters, setFilters] = useState({});
  const [priceRange, setPriceRange] = useState([0, 10000]); // Initial price range [min, max]
  const [sizeRange, setSizeRange] = useState([1, 100]);
  const [ratings, setRatings] = useState("");
  const { categoryId } = useParams();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get("searchText");
  const [selectedMaterial, setSelectedMaterial] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedShapes, setSelectedShapes] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [resultCount, setresultCount] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const categoriesString = useRef("");
  const materialsString = useRef("");
  const shapesString = useRef("");
  const selectedPriceRange = useRef("");
  const selectedSizeRange = useRef("");
  const filterFlag = useRef(false);
  const cityId = localStorage.getItem("cityId");
  const userTypeId = localStorage.getItem("userTypeId");
  let urlFlag = checkPageUrl(window.location.hostname);
  const dispatch = useDispatch();
  const content = useSelector((state) => state.Home);
  const [currentPage, setCurrentPage] = useState(1);
  const { cityName, userType } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [filterKey, setFilterKey] = useState(0);
  const itemsPerPage = 12;
  let InitialPageNo = 1;
  const [isOpenStates, setIsOpenStates] = useState([
    { id: "categories", isOpen: false },
    { id: "ratings", isOpen: false },
    { id: "materials", isOpen: false },
    { id: "size", isOpen: false },
    { id: "shapes", isOpen: false },
    { id: "price", isOpen: false },
  ]);
  const toggleAccordion = (id) => {
    setIsOpenStates((prevStates) =>
      prevStates.map((item) => ({
        ...item,
        isOpen: item.id === id ? !item.isOpen : false,
      })),
    );
  };
  const closeAllAccordions = () => {
    setIsOpenStates((prevStates) =>
      prevStates.map((item) => ({
        ...item,
        isOpen: false,
      })),
    );
  };
  //#region Filters handling
  const handleSizeSliderChange = (value) => {
    setSizeRange(value);
    selectedSizeRange.current = value[0] + " AND " + value[1];
  };
  const handlePriceSliderChange = (value) => {
    setPriceRange(value);
    selectedPriceRange.current = value[0] + " AND " + value[1];
  };
  const handleCategoryChange = (value) => {
    let arrayLength = selectedCategories.length;
    let isCategoryAvailable = false;
    for (let i = 0; i < arrayLength; i++) {
      if (selectedCategories[i] === value) {
        selectedCategories.splice(i, 1);
        isCategoryAvailable = true;
        break;
      }
    }
    if (!isCategoryAvailable) {
      selectedCategories.push(value);
    }
    categoriesString.current = selectedCategories.join(",");
  };
  const handleMaterialChange = (value) => {
    let arrayLength = selectedMaterial.length;
    let isMaterialAvailable = false;
    for (let i = 0; i < arrayLength; i++) {
      if (selectedMaterial[i] === value) {
        selectedMaterial.splice(i, 1);
        isMaterialAvailable = true;
        break;
      }
    }
    if (!isMaterialAvailable) {
      selectedMaterial.push(value);
    }
    materialsString.current = selectedMaterial.join(",");
  };
  const handleShapeChange = (value) => {
    let arrayLength = selectedShapes.length;
    let isShapeAvailable = false;
    for (let i = 0; i < arrayLength; i++) {
      if (selectedShapes[i] === value) {
        selectedShapes.splice(i, 1);
        isShapeAvailable = true;
        break;
      }
    }
    if (!isShapeAvailable) {
      selectedShapes.push(value);
    }
    shapesString.current = selectedShapes.join(",");
  };
  function handleApplyClick() {
    GetProductsFiltered(InitialPageNo, "Filters");
    if (showModal) {
      setShowModal(false);
    }
  }
  function handleClearClick() {
    setPriceRange([0, 10000]);
    setSizeRange([1, 100]);
    setRatings("");
    setSelectedMaterial([]);
    setSelectedCategories([]);
    setSelectedShapes([]);
    setFilteredProducts([]);
    setresultCount(0);
    setCurrentPage(1);
    setPageSize(12);
    categoriesString.current = "0";
    materialsString.current = "";
    shapesString.current = "";
    selectedPriceRange.current = "";
    selectedSizeRange.current = "";
    setFilterKey((prevKey) => prevKey + 1);
    closeAllAccordions();
    GetProductsFiltered(InitialPageNo, "Others");
  }
  //#endregion
  const handlePageChange = (event, pageNo) => {
    let paginationFilterParam =
      filterFlag.current === false ? "Others" : "Filters";
    GetProductsFiltered(pageNo, paginationFilterParam);
  };
  const handleAddToCartButton = (
    productId,
    itemMinimumOrderQuantity,
    itemPrice,
  ) => {
    const response = AddItemsToCart(
      productId,
      itemMinimumOrderQuantity,
      itemPrice,
    );
    dispatch(getCartCount());
    if (response.includes("info")) {
      toast.info(response.replace("info", ""));
    } else {
      toast.success(response);
    }
  };
  // Common function
  function GetProductsFiltered(pageNo, requestSource) {
    filterFlag.current = requestSource === "Filters" ? true : false;
    let isFilterApplied = filterFlag.current;
    let category = !isFilterApplied ? categoryId : categoriesString.current;
    let searchInput = !isFilterApplied ? searchText || "" : "";
    let materials = !isFilterApplied ? "" : materialsString.current;
    let shapes = !isFilterApplied ? "" : shapesString.current;
    let price = !isFilterApplied ? "" : selectedPriceRange.current;
    let size = !isFilterApplied ? "" : selectedSizeRange.current;
    let rating = !isFilterApplied ? "" : ratings;
    if (urlFlag === true && content?.sellerId !== undefined) {
      // For Daksh
      GetProductCatalog(
        cityId,
        userTypeId,
        category,
        rating,
        materials,
        shapes,
        size,
        price,
        searchInput,
        pageNo,
        pageSize,
      );
    } else if (urlFlag === false && content?.sellerId !== undefined) {
      // For all sellers
      GetProductCatalogBySeller(
        content?.sellerId,
        category,
        rating,
        materials,
        shapes,
        size,
        price,
        searchInput,
        pageNo,
        pageSize,
      );
    }
  }
  const GetProductCatalog = async (
    cityId,
    userTypeId,
    categories,
    rating,
    materials,
    shapes,
    sizeRange,
    priceRange,
    searchItem,
    pageNum,
    pageSize,
  ) => {
    try {
      const response = await GlobalProductCatalog(
        cityId,
        userTypeId,
        categories,
        rating,
        materials,
        shapes,
        sizeRange,
        priceRange,
        searchItem,
        pageNum,
        pageSize,
      );
      setFilteredProducts(response?.filteredProducts);
      setresultCount(response?.paginationResponse?.totalRecord);
      setPageSize(response?.paginationResponse?.pageSize);
      setCurrentPage(response?.paginationResponse?.pageNum);
    } catch (error) {
      console.log(error);
    }
  };
  const GetProductCatalogBySeller = async (
    sellerId,
    categories,
    rating,
    materials,
    shapes,
    sizeRange,
    priceRange,
    searchItem,
    pageNum,
    pageSize,
  ) => {
    try {
      const response = await GlobalProductCatalogBySeller(
        sellerId,
        categories,
        rating,
        materials,
        shapes,
        sizeRange,
        priceRange,
        searchItem,
        pageNum,
        pageSize,
      );
      setFilteredProducts(response?.filteredProducts);
      setresultCount(response?.paginationResponse?.totalRecord);
      setPageSize(response?.paginationResponse?.pageSize);
      setCurrentPage(response?.paginationResponse?.pageNum);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetProductsFiltered(InitialPageNo, "Others");
  }, [categoryId, searchText, content?.sellerId]);
  useEffect(() => {
    const getProductFilters = async (searchText) => {
      try {
        const response = await ProductsFilters(searchText);
        setFilters(response);
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };
    getProductFilters("");
  }, [searchText]);
  const renderStars = (rating) => {
    const totalStars = 5;
    const starArray = Array.from({ length: totalStars }, (_, index) => {
      const starStyle = {
        color: index < rating ? "#FFBC0B" : "#CBCBCB",
      };
      return (
        <span key={index} className="star-icon" style={starStyle}>
          ★
        </span>
      );
    });
    return starArray;
  };
  return (
    <>
      {/* <ToastContainer /> */}
      <section className="global-product-filter mt-5" key={filterKey}>
        <div className="container">
          <div className="row ">
            <button
              onClick={() => setShowModal(true)}
              className="filter-m mb-3"
              style={{
                backgroundColor: "transparent", // Transparent background
                border: "2px solid #ecedef", // Blue outline
                borderRadius: "20px", // Rounded border radius
                padding: "7px 16px", // Padding
                fontSize: "12px",
                textTransform: "uppercase",
                fontWeight: "bold",
                cursor: "pointer", // Cursor on hover
              }}
            >
              Filters
            </button>
          </div>
          <div className="row">
            <div className=" col-lg-3 col-md-3 col-sm-3 col-3 filterer ">
              <div className="d-flex justify-content-between filters">
                <div>
                  <div class="filters-heading p-1">Filters</div>
                </div>
                <div>
                  <button class="apply btn " onClick={handleApplyClick}>
                    Apply
                  </button>
                  <button class="clear btn " onClick={handleClearClick}>
                    Clear
                  </button>
                </div>
              </div>
              <hr className="m-0 mb-1"></hr>
              {Filters && (
                <>
                  <Accordion allowZeroExpanded={true}>
                    {/* Categories Accordion */}
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span
                            className="d-flex align-items-center justify-content-between"
                            onClick={() => toggleAccordion("categories")}
                          >
                            <span className="acccordion-span-labels">
                              Categories
                            </span>
                            <i
                              className={`fa-solid ${isOpenStates.find(
                                (item) => item.id === "categories",
                              )?.isOpen
                                  ? "fa-minus"
                                  : "fa-angle-down"
                                }`}
                            />
                          </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="accordion-body accordion-body-margin-top">
                          {Filters?.categories?.length > 0 &&
                            Filters?.categories?.map((item, index) => {
                              if (item.id !== 0) {
                                return (
                                  <div className="form-check">
                                    <input
                                      className="form-check-input filters-checkboxes"
                                      type="checkbox"
                                      name="category"
                                      value={item.id}
                                      onChange={() =>
                                        handleCategoryChange(item.id)
                                      }
                                    />
                                    <label
                                      className="form-check-label filters-checkboxes-labels"
                                      htmlFor="flexCheckDefault"
                                      key={`category_${index}`}
                                    >
                                      {item.name}
                                    </label>
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    {/* Ratings Accordion */}
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span
                            className="d-flex align-items-center justify-content-between"
                            onClick={() => toggleAccordion("ratings")}
                          >
                            <span className="acccordion-span-labels">
                              Ratings
                            </span>
                            <i
                              className={`fa-solid ${isOpenStates.find(
                                (item) => item.id === "ratings",
                              )?.isOpen
                                  ? "fa-minus"
                                  : "fa-angle-down"
                                }`}
                            />
                          </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="accordion-body accordion-body-margin-top">
                          <Rating
                            name="size-small"
                            size={20}
                            defaultValue={2}
                            value={ratings}
                            onClick={(e) => setRatings(e)}
                          />
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    {/* Materials Accordion */}
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span
                            className="d-flex align-items-center justify-content-between"
                            onClick={() => toggleAccordion("materials")}
                          >
                            <span className="acccordion-span-labels">
                              Materials
                            </span>
                            <i
                              className={`fa-solid ${isOpenStates.find(
                                (item) => item.id === "materials",
                              )?.isOpen
                                  ? "fa-minus"
                                  : "fa-angle-down"
                                }`}
                            />
                          </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="accordion-body accordion-body-margin-top">
                          {Filters?.materials?.length > 0 &&
                            Filters?.materials?.map((item, index) => {
                              return (
                                <div className="form-check">
                                  <input
                                    className="form-check-input filters-checkboxes "
                                    type="checkbox"
                                    name="category"
                                    value={item.id}
                                    onChange={() =>
                                      handleMaterialChange(item.id)
                                    }
                                  />
                                  <label
                                    className="form-check-label filters-checkboxes-labels"
                                    htmlFor="flexCheckDefault"
                                    key={`material_${index}`}
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              );
                            })}
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    {/* Shapes Accordion */}
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span
                            className="d-flex align-items-center justify-content-between"
                            onClick={() => toggleAccordion("shapes")}
                          >
                            <span className="acccordion-span-labels">
                              Shapes
                            </span>
                            <i
                              className={`fa-solid ${isOpenStates.find(
                                (item) => item.id === "shapes",
                              )?.isOpen
                                  ? "fa-minus"
                                  : "fa-angle-down"
                                }`}
                            />
                          </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="accordion-body accordion-body-margin-top">
                          {Filters?.shapes?.length > 0 &&
                            Filters?.shapes?.map((item, index) => {
                              return (
                                <div className="form-check">
                                  <input
                                    className="form-check-input filters-checkboxes "
                                    type="checkbox"
                                    name="category"
                                    value={item.id}
                                    onChange={() => handleShapeChange(item.id)}
                                  />
                                  <label
                                    className="form-check-label filters-checkboxes-labels"
                                    htmlFor="flexCheckDefault"
                                    key={`shape_${index}`}
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              );
                            })}
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    {/* Size Accordion */}
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span
                            className="d-flex align-items-center justify-content-between"
                            onClick={() => toggleAccordion("size")}
                          >
                            <span className="acccordion-span-labels">Size</span>
                            <i
                              className={`fa-solid ${isOpenStates.find((item) => item.id === "size")
                                  ?.isOpen
                                  ? "fa-minus"
                                  : "fa-angle-down"
                                }`}
                            />
                          </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="accordion-body accordion-body-margin-top">
                          <div className="price-slider p-2 d-flex justify-content-between">
                            <div>{sizeRange[0]} "</div>
                            <div>{sizeRange[1]} "</div>
                          </div>
                          <Slider
                            min={0}
                            max={100}
                            value={sizeRange}
                            onChange={handleSizeSliderChange}
                            range
                          />
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    {/* Price Accordion */}
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span
                            className="d-flex align-items-center justify-content-between"
                            onClick={() => toggleAccordion("price")}
                          >
                            <span className="acccordion-span-labels">
                              Price
                            </span>
                            <i
                              className={`fa-solid ${isOpenStates.find((item) => item.id === "price")
                                  ?.isOpen
                                  ? "fa-minus"
                                  : "fa-angle-down"
                                }`}
                            />
                          </span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="accordion-body accordion-body-margin-top">
                          <div className="price-slider p-2 d-flex justify-content-between">
                            <div>₹ {priceRange[0]}</div>
                            <div>₹ {priceRange[1]}</div>
                          </div>
                          <Slider
                            min={0}
                            max={10000}
                            value={priceRange}
                            onChange={handlePriceSliderChange}
                            range
                          />
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </>
              )}
              <hr className="m-0 product-filter-bottom-hr"></hr>
            </div>
            {/*  Mobile filter */}
            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
              animation={false}
              className="fade-slide-in"
            >
              <Modal.Header closeButton>
                <Modal.Title>Filters</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div class="accordion" id="accordionExample">
                  {Filters && (
                    <>
                      <Accordion allowZeroExpanded={true}>
                        {/* Categories Accordion */}
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <span
                                className="d-flex align-items-center justify-content-between"
                                onClick={() => toggleAccordion("categories")}
                              >
                                <span className="acccordion-span-labels">
                                  Categories
                                </span>
                                <i
                                  className={`fa-solid ${isOpenStates.find(
                                    (item) => item.id === "categories",
                                  )?.isOpen
                                      ? "fa-minus"
                                      : "fa-angle-down"
                                    }`}
                                />
                              </span>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="accordion-body accordion-body-margin-top">
                              {Filters?.categories?.length > 0 &&
                                Filters?.categories?.map((item, index) => {
                                  if (item.id !== 0) {
                                    return (
                                      <div className="form-check">
                                        <input
                                          className="form-check-input filters-checkboxes"
                                          type="checkbox"
                                          name="category"
                                          value={item.id}
                                          onChange={() =>
                                            handleCategoryChange(item.id)
                                          }
                                        />
                                        <label
                                          className="form-check-label filters-checkboxes-labels"
                                          htmlFor="flexCheckDefault"
                                          key={`category_${index}`}
                                        >
                                          {item.name}
                                        </label>
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        {/* Ratings Accordion */}
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <span
                                className="d-flex align-items-center justify-content-between"
                                onClick={() => toggleAccordion("ratings")}
                              >
                                <span className="acccordion-span-labels">
                                  Ratings
                                </span>
                                <i
                                  className={`fa-solid ${isOpenStates.find(
                                    (item) => item.id === "ratings",
                                  )?.isOpen
                                      ? "fa-minus"
                                      : "fa-angle-down"
                                    }`}
                                />
                              </span>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="accordion-body accordion-body-margin-top">
                              <Rating
                                name="size-small"
                                size={20}
                                defaultValue={2}
                                value={ratings}
                                onClick={(e) => setRatings(e)}
                              />
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        {/* Materials Accordion */}
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <span
                                className="d-flex align-items-center justify-content-between"
                                onClick={() => toggleAccordion("materials")}
                              >
                                <span className="acccordion-span-labels">
                                  Materials
                                </span>
                                <i
                                  className={`fa-solid ${isOpenStates.find(
                                    (item) => item.id === "materials",
                                  )?.isOpen
                                      ? "fa-minus"
                                      : "fa-angle-down"
                                    }`}
                                />
                              </span>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="accordion-body accordion-body-margin-top">
                              {Filters?.materials?.length > 0 &&
                                Filters?.materials?.map((item, index) => {
                                  return (
                                    <div className="form-check">
                                      <input
                                        className="form-check-input filters-checkboxes "
                                        type="checkbox"
                                        name="category"
                                        value={item.id}
                                        onChange={() =>
                                          handleMaterialChange(item.id)
                                        }
                                      />
                                      <label
                                        className="form-check-label filters-checkboxes-labels"
                                        htmlFor="flexCheckDefault"
                                        key={`material_${index}`}
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        {/* Shapes Accordion */}
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <span
                                className="d-flex align-items-center justify-content-between"
                                onClick={() => toggleAccordion("shapes")}
                              >
                                <span className="acccordion-span-labels">
                                  Shapes
                                </span>
                                <i
                                  className={`fa-solid ${isOpenStates.find(
                                    (item) => item.id === "shapes",
                                  )?.isOpen
                                      ? "fa-minus"
                                      : "fa-angle-down"
                                    }`}
                                />
                              </span>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="accordion-body accordion-body-margin-top">
                              {Filters?.shapes?.length > 0 &&
                                Filters?.shapes?.map((item, index) => {
                                  return (
                                    <div className="form-check">
                                      <input
                                        className="form-check-input filters-checkboxes "
                                        type="checkbox"
                                        name="category"
                                        value={item.id}
                                        onChange={() =>
                                          handleShapeChange(item.id)
                                        }
                                      />
                                      <label
                                        className="form-check-label filters-checkboxes-labels"
                                        htmlFor="flexCheckDefault"
                                        key={`shape_${index}`}
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        {/* Size Accordion */}
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <span
                                className="d-flex align-items-center justify-content-between"
                                onClick={() => toggleAccordion("size")}
                              >
                                <span className="acccordion-span-labels">
                                  Size
                                </span>
                                <i
                                  className={`fa-solid ${isOpenStates.find(
                                    (item) => item.id === "size",
                                  )?.isOpen
                                      ? "fa-minus"
                                      : "fa-angle-down"
                                    }`}
                                />
                              </span>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="accordion-body accordion-body-margin-top">
                              <div className="price-slider p-2 d-flex justify-content-between">
                                <div>{sizeRange[0]} "</div>
                                <div>{sizeRange[1]} "</div>
                              </div>
                              <Slider
                                min={0}
                                max={100}
                                value={sizeRange}
                                onChange={handleSizeSliderChange}
                                range
                              />
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        {/* Price Accordion */}
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <span
                                className="d-flex align-items-center justify-content-between"
                                onClick={() => toggleAccordion("price")}
                              >
                                <span className="acccordion-span-labels">
                                  Price
                                </span>
                                <i
                                  className={`fa-solid ${isOpenStates.find(
                                    (item) => item.id === "price",
                                  )?.isOpen
                                      ? "fa-minus"
                                      : "fa-angle-down"
                                    }`}
                                />
                              </span>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="accordion-body accordion-body-margin-top">
                              <div className="price-slider p-2 d-flex justify-content-between">
                                <div>₹ {priceRange[0]}</div>
                                <div>₹ {priceRange[1]}</div>
                              </div>
                              <Slider
                                min={0}
                                max={10000}
                                value={priceRange}
                                onChange={handlePriceSliderChange}
                                range
                              />
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </>
                  )}
                </div>
                <div>
                  <div>
                    <div style={{ marginBottom: "10px" }}>
                      <button
                        className="apply btn btn-primary"
                        onClick={handleApplyClick}
                        style={{
                          color: "#fff",
                          background: "#ff6b6b",
                          border: "none",
                          width: "100%",
                        }}
                      >
                        Show Results
                      </button>
                    </div>
                    <div>
                      <button
                        className="clear btn btn-primary"
                        onClick={handleClearClick}
                        style={{
                          border: "solid 1px #e8e9eb",
                          background: "#fff",
                          color: "#ff6b6b",
                          width: "100%",
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <div className="col-lg-9 col-md-12 col-sm-12 col-12">
              <div className="row">
                <div className="d-flex align-items-center">
                  <div class="search-result-heading mx-2">
                    {filterFlag.current
                      ? "Showing Filtered Results"
                      : searchText
                        ? `Search Result`
                        : Filters?.categories?.map((item) =>
                          item.id == categoryId
                            ? 'Products For "' + item.name + '"'
                            : "",
                        )}
                  </div>
                  {/* <div class="search-result-heading mx-2">Search Result</div> */}
                  <div class="showing-results mt-2">
                    {searchText && !filterFlag.current
                      ? `Showing ${resultCount} results for "${searchText}"`
                      : ""}
                  </div>
                </div>
              </div>
              <div className="row">
                {filteredProducts &&
                  filteredProducts.map((item, index) => {
                    return (
                      <>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-6 p-0">
                          <div className="product-main-card">
                            <div className="Product p-2 border m-2">
                              <Link
                                onClick={() => {
                                  urlFlag && content?.sellerId
                                    ? window.open(
                                      `/${cityName}/${userType}/product-details/${item.id}`,
                                      "_blank",
                                    )
                                    : window.open(
                                      `/product-details/${item.id}`,
                                      "_blank",
                                    );
                                }}
                              >
                                <div className="Product-image mb-1">
                                  <img src={item.imageURL} />
                                  {item.discount > 0 ? (
                                    <div className="Product-discount px-1">
                                      <div>Save {item.discount}%</div>
                                    </div>
                                  ) : null}
                                </div>
                              </Link>
                              <div>
                                <div className="Product-price-and-ratings d-flex justify-content-between">
                                  {item.discount > 0 ? (
                                    <>
                                      <div className="Product-price">
                                        ₹ {item.actualPrice}
                                      </div>
                                      <div className="ratings">
                                        {renderStars(item.ratings)}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="discounted-price">
                                        ₹ {item.price}
                                      </div>
                                      <div className="ratings">
                                        {renderStars(item.ratings)}
                                      </div>
                                    </>
                                  )}
                                </div>
                                {item.discount > 0 ? (
                                  <div className="discounted-price">
                                    ₹ {item.price}
                                  </div>
                                ) : null}
                                <div className="Product-name">{item.name}</div>
                                <div className="Product-material mb-1">
                                  {item.material}
                                </div>
                              </div>
                              <button
                                className="add-to-cart p-2"
                                onClick={(e) =>
                                  handleAddToCartButton(
                                    item.id,
                                    item.minimumOrderQuantity,
                                    item.price,
                                  )
                                }
                              >
                                <b className="add-to-cart1">Add to cart</b>
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
              <div className="d-flex my-3 text-center justify-content-center">
                {resultCount > 12 && (
                  <Pagination
                    count={Math.ceil(resultCount / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    //variant="outlined"
                    color="secondary"
                    shape="rounded"
                    size="small"
                  // className="css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
