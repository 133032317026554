import { createSlice } from "@reduxjs/toolkit";
// global state
const initialState = {
    paymentRequest: {
        order_amount: 0,
        order_currency: "string",
        customer_details: {
            customer_id: "string",
            customer_name: "string",
            customer_email: "string",
            customer_phone: "string",
        },
        order_meta: {
            notify_url: "string",
            return_url: "string",
        },
    },
    dakshOrderId: 0,
    sellerUrl: "string",
};

const paymentSlice = createSlice({
    name: "payment_request",
    initialState,

    // modifying global state
    reducers: {
        setPaymentRequest: (state, action) => {
            state.paymentRequest = action.payload.paymentRequest;
            state.dakshOrderId = action.payload.dakshOrderId;
            state.sellerUrl = action.payload.sellerUrl;
        },
    },
});

export const { setPaymentRequest } = paymentSlice.actions;
export default paymentSlice.reducer;
