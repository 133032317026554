import { useEffect, useState } from "react";
import "./Featured-Products.css";
import Carousel from 'react-multi-carousel';
import { GetFeaturedProducts } from "../../../services/Home/homeService";
import { useDispatch, useSelector } from "react-redux";
import { AddItemsToCart } from "../../../services/handleCartService";
import { getCartCount } from "../../../redux/features/cartSlice";
import { Link, useParams } from "react-router-dom";
import checkPageUrl from "../../../helpers";
import ApiConstants from "../../../Constants/ApiConstants";
import { getMostBoughtProductsApi, getMostBoughtProductsBySeller } from "../../../services/productServices";
import { toast, ToastContainer } from "react-toastify";

export const FeaturedProducts = ({ headerText, source }) => {
    const dispatch = useDispatch();
    const { cityName, userType } = useParams();
    const [carouselData, setCarouselData] = useState([]);
    const content = useSelector((state) => state.Home?.sellerId);

    let currentUrl = window.location.hostname;
    let urlFlag = checkPageUrl(currentUrl);
    let sellerId = content;

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 3// optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 425 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        smallMobile: {
            breakpoint: { max: 424, min: 100 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <button className="carousel-arrows-right" onClick={() => onClick()} >
            <i class="fa-solid fa-chevron-right"></i>
        </button>;
    };

    const CustomLeftArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <button className="carousel-arrows-left" onClick={() => onClick()} >
            <i class="fa-solid fa-chevron-left"></i>
        </button>;
    };

    const renderStars = (rating) => {
        const totalStars = 5;

        const starArray = Array.from({ length: totalStars }, (_, index) => {
            const starStyle = {
                color: index < rating ? '#FFBC0B' : '#CBCBCB',
            };

            return <span key={index} className="star-icon" style={starStyle}>★</span>;
        });

        return starArray;
    };

    const handleAddToCartButton = (productId, itemMinimumOrderQuantity, itemPrice) => {
        const response = AddItemsToCart(productId, itemMinimumOrderQuantity, itemPrice);
        dispatch(getCartCount());
        if (response.includes('info')) {
            toast.info(response.replace('info', ''));
          } else {
            toast.success(response);
          }
    }

    useEffect(() => {
        const getFeaturedProducts = async () => {
            let response;
            try {
                if (source === ApiConstants.HOME_CONST) {
                    if (urlFlag) {
                        response = await GetFeaturedProducts(
                            sellerId,
                            localStorage.getItem("cityId"),
                            localStorage.getItem("userTypeId")
                        );
                    } else {
                        response = await GetFeaturedProducts(sellerId, 0, 0);
                    }
                } else if (source === ApiConstants.PRODUCT_VIEW_CONST) {
                    if (urlFlag) {
                        response = await getMostBoughtProductsApi(localStorage.getItem("cityId"), localStorage.getItem("userTypeId"));
                    }
                    else {
                        response = await getMostBoughtProductsBySeller(sellerId);
                    }
                } else if (source === ApiConstants.RECENTLY_VIEWED_PRODUCTS) {
                    response = JSON.parse(localStorage.getItem("RecentlyViewedItem"));
                }
                setCarouselData(response);
            } catch (error) {
                console.error("Error fetching order data:", error);
            }
        };

        if (sellerId !== 0) {
            getFeaturedProducts();
        }
    }, [sellerId])

    return (
        <>
            {/* <ToastContainer /> */}
            <div className="container">
                <h3 className="discounts-offers1 text-center my-4">{headerText}</h3>
                <div className="our-dedication-frame mb-3">
                    <Carousel
                        responsive={responsive}
                        // autoPlay={true}
                        // swipeable={true}
                        customRightArrow={<CustomRightArrow />}
                        customLeftArrow={<CustomLeftArrow />}
                        draggable={true}
                        showDots={true}
                        infinite={true}
                        partialVisible={false}
                        dotListClass="custom-dot-list-style"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        renderButtonGroupOutside={true}
                        // focusOnSelect={true}
                    >
                        {carouselData?.map((item) => {
                            return (
                              <>
                                <div className="Product p-2 border m-2">
                                  <Link
                                    onClick={() => {
                                      urlFlag && sellerId
                                        ? window.open(
                                            `/${cityName}/${userType}/product-details/${item.id}`,
                                            "_blank"
                                          )
                                        : window.open(
                                            `/product-details/${item.id}`,
                                            "_blank"
                                          );
                                    }}
                                  >
                                    <div className="Product-image mb-1">
                                      <img src={item.imageURL} />
                                      {item.discount > 0 ? (
                                        <div className="Product-discount px-1">
                                          <div>Save {item.discount}%</div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </Link>

                                  <div>
                                    <div className="Product-price-and-ratings d-flex justify-content-between">
                                      {item.discount > 0 ? (
                                        <>
                                          <div className="Product-price">
                                            ₹ {item.actualPrice}
                                          </div>
                                          <div className="ratings">
                                            {renderStars(item.ratings)}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="discounted-price">
                                            ₹ {item.price}
                                          </div>
                                          <div className="ratings">
                                            {renderStars(item.ratings)}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    {item.discount > 0 ? (
                                      <div className="discounted-price">
                                        ₹ {item.price}
                                      </div>
                                    ) : null}
                                    <Link
                                      onClick={() => {
                                        urlFlag && sellerId
                                          ? window.open(
                                              `/${cityName}/${userType}/product-details/${item.id}`,
                                              "_blank"
                                            )
                                          : window.open(
                                              `/product-details/${item.id}`,
                                              "_blank"
                                            );
                                      }}
                                    >
                                      <div className="Product-name">
                                        {item.name}
                                      </div>
                                    </Link>
                                    <div className="Product-material mb-1">
                                      {item.material}
                                    </div>
                                  </div>
                                  <button
                                    className="add-to-cart p-2 "
                                    onClick={(e) =>
                                      handleAddToCartButton(
                                        item.id,
                                        item.minimumOrderQuantity,
                                        item.price
                                      )
                                    }
                                  >
                                    <b className="add-to-cart1">Add to cart</b>
                                  </button>
                                </div>
                              </>
                            );
                        })}
                    </Carousel>
                </div>
            </div>
        </>
    )
}