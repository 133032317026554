import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { PostAnnouncement, apiGetAnnouncements } from '../../services/adminService';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



const Announcements = () => {

  const [announcementText, setAnnouncementText] = useState('')

  const sellerId = useSelector((state) => state.auth.sellerId);


  const handleAdd = async (body) => {
    body = {
      sellerId: sellerId,
      announcementText: announcementText
    }
    try {
      const response = await PostAnnouncement(body);
      toast.success('Announcement Changed')
      console.log(response)
    }
    catch (error) {
      toast.error('Announcement Changed')
      console.error('Error making POST request:', error);
    }
  }


  const getSellerId = async (id) => {
    try {
      const response = await apiGetAnnouncements(id);
      id = sellerId
      setAnnouncementText(response)
    }
    catch {
      alert('error')
    }
  }


  const handleInputChange = (e) => {
    setAnnouncementText(e.target.value);
  };


  useEffect(() => {
    getSellerId(sellerId);
  }, [sellerId]);


  return (
    <>
      <ToastContainer />
      <div className="row-title " style={{ marginLeft: '-6px' }}>
      </div>
      <h6 className="fw-bold mx-4 mt-4">
        Add Announcement
      </h6>
      <div className="card m-4" style={{ borderRadius: '0' }}>
        <div className="card-body">
          <div className="col-12">
            <a className="minimize">
            </a>
          </div>

          <div className="row">
            <div>
              <label className="form-label mt-5 mb-3">
                Announcement <sup className="text-danger">*</sup>
              </label>
              <div class="input-group mb-3">
                <input
                  type="text" class="form-control"
                  aria-label="Text input with checkbox"
                  placeholder='Enter Your Announcement'
                  maxLength={150}
                  value={announcementText}
                  onChange={handleInputChange} />
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-warning"
            onClick={handleAdd}
            id="0">
            Save
          </button>
        </div>
      </div>
    </>
  )
}

export default Announcements;