import ApiConstants from "../Constants/ApiConstants";
import { apiGetWithoutAuth, apiPost } from "./apiCall";

export const GeneratePaymentOrder = async (endpoints, body) => {
    const response = await apiPost(`${ApiConstants.GENERATE_PAYMENT_ORDER}?dakshIndiaOrderId=${endpoints}`, body)
    return response;
}

export const GetOrderStatus = async (orderId, dakshOrderId) => {
    const response = await apiGetWithoutAuth(`${ApiConstants.GET_PAYMENT_STATUS}${orderId}`)
    return response;
}

export const GetOrderDiscounts= async()=>{
    const response= await apiGetWithoutAuth(`${ApiConstants.GET_DISCOUNT}`)
    return response;
}

export const GetPriceAfterDiscount=async(id,totalCartPrice)=>{
    const response=await apiGetWithoutAuth(`${ApiConstants.GET_PRICE_AFTER_DISCOUNT}?discountId=${id}&price=${totalCartPrice}`)
    return response;
}