/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import OrderReport from "./OrderReport";
import OrderSummary from "./OrderSummary";
import OrderTable from "./OrderTable";
import { useSelector } from "react-redux";

function Order() {
  const sellerId = useSelector((state) => state.auth.sellerId);

  return (
    <Fragment>
      <div className="page-body px-0 py-lg-2 py-1 mt-0 mt-lg-3 ms-3">
        <div className="container-fluid p-0">
          <div className="row g-3">
            <OrderReport/>
            <OrderSummary sellerId={sellerId}/>
            {/* Dash Card 1 */}
            <OrderTable sellerId={sellerId}/>
          </div>
          {/* Dash Card 2 - Product Table */}
        </div>
      </div>
    </Fragment>
  );
}

export default Order;
