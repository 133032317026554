

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Common/Header/Header";
import { useParams, useNavigate } from "react-router-dom";
import { getProductDetailId } from "../../services/customerService";
import { Rating } from "react-simple-star-rating";
import { CheckValidUser } from "../Common/CheckForValidUser";
import ItemsAddedModal from "../Modal/ItemsAddedModal";
import MinimunItemsModal from "../Modal/MinimumItemsModal";
import { toTitleCase } from "../../schema/CaseingConverter";
import Loader from "../Common/Loader";
import checkPageUrl from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import ProductMedia from "./ProductMedia";
import "./ProductDetail.css";
import ShareButtons from "./ShareButtons.jsx";
import { FeaturedProducts } from "../Home/Featured-Products/Featured-Products.jsx";
import {
  getProductDetailsBySeller,
  getProductDetailsById,
  saveRecentlyViewedItem,
} from "../../services/productServices.js";
import { AddItemsToCart } from "../../services/handleCartService.js";
import { getCartCount } from "../../redux/features/cartSlice.js";
import ApiConstants from "../../Constants/ApiConstants.js";
import { ToastContainer, toast } from "react-toastify";

function ProductDetails() {
  const [singleProductDetail, setSingleProductDetail] = useState({});
  const [productMedia, setProductMedia] = useState([]);
  const [image, setImage] = useState("");
  const [video, setVideo] = useState("");
  const [flag, setFlag] = useState(1);
  const { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [validUser, setValidUser] = useState(false);
  const [item, setItem] = useState();
  const [videoId, setVideoId] = useState("");
  const [headerCityName, setHeaderCityName] = useState(
    localStorage.getItem("headerCityName")
  );
  const [headerUserType, setHeaderUserType] = useState(
    localStorage.getItem("headerUserType")
  );
  const [searchText, setSearchText] = useState("");
  const [minimum, setMinimum] = useState();
  const { cityName, userType } = useParams();
  const [isLoader, setIsLoader] = useState(false);
  const [mostBroughtProducts, setMostBroughtProducts] = useState([]);
  const navigate = useNavigate();
  let urlFlag;
  const [flagForPageUrl, setFlagForPageUrl] = useState();
  const dispatch = useDispatch();
  let response;
  const cityId = localStorage.getItem("cityId") ?? 0;
  const userTypeId = localStorage.getItem("userTypeId") ?? 0;

  const content = useSelector((state) => state.Home.HomeDetail);
  const url = window.location.href;

  useEffect(() => {
    if (content?.sellerId) {
      checkForValidUser();
    }
  }, [content]);

  urlFlag = checkPageUrl(window.location.hostname);
  const checkForValidUser = async () => {
    setFlagForPageUrl(urlFlag);

    if (urlFlag) {
      const checkValidUser = await CheckValidUser(cityName, userType);
      if (urlFlag && !checkValidUser) {
        navigate("/AboutUs");
      }
      setValidUser(checkValidUser);

      if (!checkValidUser) {
        navigate("/not-found");
      } else {
        setHeaderCityName(localStorage.getItem("headerCityName"));
        setHeaderUserType(localStorage.getItem("headerUserType"));
        customerSingleProductDetail();
      }
    } else {
      customerSingleProductDetail();
    }
  };

  const customerSingleProductDetail = async () => {
    setIsLoader(true);
    try {
      if (urlFlag) {
        response = await getProductDetailsById(id, cityId, userTypeId);
      } else {
        response = await getProductDetailsBySeller(content?.sellerId, id);
      }
      saveRecentlyViewedItem(response);

      setSingleProductDetail(response?.productDetailCustomer);
      setMinimum(response?.productDetailCustomer?.minimumOrderQuantity);
      setProductMedia(response?.productMedia);
      response?.productMedia?.map((d) => {
        d?.isThumbnail && setImage(d?.url);
        d?.mediaType === "video" && setVideo(d?.url);
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoader(false);
  };

  const videoDisplay = (url) => {
    try {
      const urlObj = new URL(url);
      let videoId;
      if (urlObj.pathname.substring(0, 7) === "/watch") {
        // YouTube video link
        videoId = urlObj.searchParams.get("v");
      } else if (urlObj.hostname === "youtu.be") {
        // YouTube video link
        videoId = urlObj.pathname.substring(1, 12);
      } else if (urlObj.pathname.substring(0, 7) === "/shorts") {
        // YouTube shorts link
        videoId = urlObj.pathname.substring(8, 19);
      } else {
        console.error("Invalid YouTube link:", url);
        return;
      }
      setVideoId(videoId);
      return `https://youtube.com/embed/${videoId}`;
    } catch (error) {
      return null;
    }
  };

  const imageDisplay = (url, mediaType) => {
    if (mediaType === "image") {
      setImage(url);
      setFlag(mediaType);
    }
    if (mediaType === "video") {
      url = videoDisplay(url);
      setVideo(url);
      setFlag(mediaType);
    }
    if (mediaType === undefined) setImage(singleProductDetail.thumbnail);
  };

  var userCart;
  let temp;
  const [userCart1, setUserCart1] = useState([]);

  // useEffect(() => {
  //   if (JSON.parse(localStorage.getItem("DakshIndiaCart")) !== null) {
  //     setUserCart1(JSON.parse(localStorage.getItem("DakshIndiaCart")));
  //     //setting length
  //     setItem(JSON.parse(localStorage.getItem("DakshIndiaCart")).length);
  //   } else {
  //     //setting length
  //     setItem(0);
  //   }
  // }, []);

  // const addProductToCart = (productId, minimumQuan, price) => {
  //   if (JSON.parse(localStorage.getItem("DakshIndiaCart")) == null) {
  //     userCart = [];
  //     //userCart.push({ mediaType: productId, quantity: 1 });
  //     userCart.push({ id: productId, quantity: minimumQuan, price: price });
  //     setUserCart1(userCart);
  //     localStorage.setItem("DakshIndiaCart", JSON.stringify(userCart));
  //     //setting length
  //     setItem(1);
  //   } else {
  //     userCart = JSON.parse(localStorage.getItem("DakshIndiaCart"));
  //     var checkDuplicate = -1;
  //     var i = 0;
  //     while (i < userCart.length) {
  //       if (userCart[i].id == productId) {
  //         checkDuplicate = i;
  //         break;
  //       }
  //       i++;
  //     }

  //     if (checkDuplicate > -1) {
  //       //
  //       if (
  //         parseInt(document.getElementById("cart" + productId).value) >=
  //         minimumQuan
  //       ) {
  //         userCart[checkDuplicate].quantity = parseInt(
  //           document.getElementById("cart" + productId).value
  //         );
  //         //userCart[checkDuplicate].quantity = userCart1[findProductidFromLocalstorage(productId)].quantity;
  //         setUserCart1(userCart);
  //         localStorage.setItem("DakshIndiaCart", JSON.stringify(userCart));
  //         document.getElementById("add-product").click();
  //       } else {
  //         //minimum=minimumQuan;
  //         setMinimum(minimumQuan);
  //         document.getElementById("not-add-product").click();
  //       }
  //     } else {
  //       userCart.push({ id: productId, quantity: minimumQuan, price: price });
  //       setUserCart1(userCart);
  //       localStorage.setItem("DakshIndiaCart", JSON.stringify(userCart));
  //       setItem(userCart.length);
  //     }
  //   }
  // };

  const handleAddToCartButton = (
    productId,
    itemMinimumOrderQuantity,
    itemPrice
  ) => {
    const response = AddItemsToCart(productId, itemMinimumOrderQuantity, itemPrice);
    dispatch(getCartCount());
    if (response.includes('info')) {
      toast.info(response.replace('info', ''));
    } else {
      toast.success(response);
    }
  };

  function findProductidFromLocalstorage(id) {
    let j = 0;
    temp = -1;
    if (userCart1.length !== 0) {
      while (j < userCart1.length) {
        if (userCart1[j].id === id) {
          temp = j;
          break;
        }
        j++;
      }
      return temp;
    } else {
      return -1;
    }
  }

  const handleClick = () => {
    if (searchText.trim().length !== 0) {
      navigate(
        urlFlag && content?.sellerId
          ? `/${cityName}/${userType}/category?searchText=${searchText}`
          : `/category?searchText=${searchText}`,
        {
          state: {
            searchText: searchText,
          },
        }
      );
    }
  };

  const toCategoryPage = (id) => {
    navigate(`/${cityName}/${userType}/category/${id}`);
  };

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const renderStars = (rating) => {
    const totalStars = 5;

    const starArray = Array.from({ length: totalStars }, (_, index) => {
      const starStyle = {
        color: index < rating ? "#FFBC0B" : "#CBCBCB",
      };

      return (
        <span key={index} className="star-icon" style={starStyle}>
          ★
        </span>
      );
    });

    return starArray;
  };

  const [selectedThumbnail, setSelectedThumbnail] = useState(0);

  const handleThumbnailClick = (index) => {
    setSelectedThumbnail(index);
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <section className="product-details">
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-4 ps-3">
              <div className="product-details-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item ">
                      <Link to={`/${cityName}/${userType}`}>Home</Link>
                    </li>
                    <li class="breadcrumb-item">
                      <a
                        className="active"
                        onClick={() =>
                          toCategoryPage(singleProductDetail?.categoryId)
                        }
                      >
                        {singleProductDetail?.category}
                      </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      <a>
                        {singleProductDetail?.name &&
                          toTitleCase(singleProductDetail?.name)}
                      </a>{" "}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 mt-0 pe-0 order-md-1 order-2">
                    <div className="row">
                      {productMedia?.map((thumbnail, index) => (
                        <div
                          key={thumbnail.id}
                          className="col-lg-12 col-md-12 col-4 mb-2"
                        >
                          {" "}
                          {/* Adjust column width based on the number of thumbnails you want per row */}
                          <a
                            className={
                              selectedThumbnail === index
                                ? "selected thumbnail"
                                : "thumbnail"
                            }
                            onClick={() => handleThumbnailClick(index)}
                            data-big={thumbnail.url}
                          >
                            <div className={`p-1 ${index === 0 ? "pt-0" : ""}`}>
                              <img
                                className="product-details-img"
                                src={thumbnail.url}
                                alt="Thumbnail"
                              ></img>
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-12 col-12 pe-0 ms-2 ms-lg-0 order-md-2 order-1">
                    <img
                      class="product-details-img-main"
                      alt=""
                      src={productMedia[selectedThumbnail]?.url}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="product-name">{singleProductDetail?.name}</div>
                <div className="ratings-share-links d-flex justify-content-between">
                  <div className="ratings">
                    {renderStars(singleProductDetail?.rating)}
                  </div>
                  <div className="share-links">
                    <ShareButtons url={url} />
                  </div>
                </div>
                <div class="product-code">{singleProductDetail?.code}</div>
                <div className="d-flex align-items-center">
                  {singleProductDetail?.discount > 0 ? (
                    <>
                      <div className="discount p-0 px-2">
                        Save {singleProductDetail?.discount}%
                      </div>
                      <div className="price p-1 px-2 price-before-discount">
                        ₹ {singleProductDetail?.basePrice}
                      </div>
                    </>
                  ) : null}
                  <div className="discounted-price px-2 p-1 pe-0">
                    ₹ {singleProductDetail?.actualPrice}
                  </div>
                  <div className="per-peice px-2 p-1 ps-1">/per piece</div>
                </div>

                <div className="row mt-3">
                  <div class="col-lg-2 col-md-3 col-sm-3 col-3 size-inch-parent pe-0">
                    <div class="size-inch  product-properties ">
                      Size (Inch)
                    </div>
                    <div class="size product-properties-figure">
                      {singleProductDetail?.size}
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-3 col-sm-3 col-3 weight-kg-parent px-1">
                    <div class="weight-kg  product-properties">
                      Weight (kg)
                    </div>
                    <div class="weight product-properties-figure">
                      {singleProductDetail?.weight}
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-3 col-sm-3 col-3 shape-parent">
                    <div class="shape  product-properties">Shape</div>
                    <div class="shape-name product-properties-figure">
                      {singleProductDetail?.shape}
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-3 col-sm-3 col-3 material-parent">
                    <div class="material  product-properties">Material</div>
                    <div class="material-name product-properties-figure">
                      {singleProductDetail?.material}
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-5 col-sm-3 col-5 min-order-quantity-parent ">
                    <div class="min-order-quantity  product-properties">
                      Min. order quantity
                    </div>
                    <div class="figure product-properties-figure">
                      {singleProductDetail?.minimumOrderQuantity}
                    </div>
                  </div>
                </div>

                <div className="description mt-2 mb-4">
                  <div className="description-heading product-properties">
                    Description
                  </div>
                  <div className="description-text mt-1">
                    <p class="suitable-for mb-0">Dispatch time: 7-10 Days</p>
                    <p class="suitable-for mb-0">
                      Suitable for : Drawing Room, Living Room
                    </p>
                    <p class="suitable-for mb-0">
                      Care Instructions : Wipe with a clean, dry cloth without
                      scrubbing.
                    </p>
                    <p class="suitable-for mb-0">&nbsp;</p>
                    <p class="suitable-for mb-0">
                      Note: Please make sure you do not use rough material or
                      harsh cleaning solutions.
                    </p>
                    <p class="suitable-for mb-0">
                      For any questions or clarifications relating to the
                      product, feel free to contact our Customer Support.
                    </p>
                    <p class="suitable-for mb-0">&nbsp;</p>
                    <p class="suitable-for mb-0">&nbsp;</p>
                    <p class="suitable-for mb-0">Understanding Your Product:</p>
                    <p class="suitable-for mb-0">
                      Accessories shown in the photograph are meant for
                      representation purposes only, and are not part of the
                      actual product.
                    </p>
                    <p class="suitable-for mb-0">&nbsp;</p>
                    <p class="suitable-for mb-0">
                      Furniture products having intricate and hand-painted
                      details have been meticulously created by skilled
                      craftsmen, following on the footsteps of several
                      generations of accomplished artisans. Each piece of such
                      Furniture is, thus, unique, and there is a possibility of
                      slight differences between the picture and the actual
                      product.
                    </p>
                    <p class="suitable-for mb-0">&nbsp;</p>
                    <p class="suitable-for mb-0">Tags - Fountains , @ABH</p>
                  </div>
                </div>
                <div className="product-buttons d-flex  flex-start">
                  <div>
                    <div className="add-order-quantity d-flex align-items-center justify-content-between me-4">
                      {minimum <= singleProductDetail?.minimumOrderQuantity ? (
                        <div className="pe-4">
                          <i class="fa-solid fa-minus"></i>
                        </div>
                      ) : (
                        <div
                          className="pe-4"
                          onClick={() => setMinimum(minimum - 1)}
                        >
                          <i class="fa-solid fa-minus"></i>
                        </div>
                      )}
                      <div className="pe-4 product-min-order-quant">
                        {minimum}
                      </div>
                      <div onClick={() => setMinimum(minimum + 1)}>
                        <i class="fa-solid fa-plus"></i>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Link
                      to={flagForPageUrl && content?.sellerId ? `/${cityName}/${userType}/my-cart` : `/my-cart`}>
                      <button className="buy-now me-4"
                        onClick={() =>
                          handleAddToCartButton(
                            singleProductDetail.id,
                            minimum,
                            singleProductDetail.actualPrice
                          )
                        }>Buy Now</button>
                    </Link>
                  </div>
                  <div>
                    <button
                      className="product-add-to-cart"
                      onClick={() =>
                        handleAddToCartButton(
                          singleProductDetail.id,
                          minimum,
                          singleProductDetail.actualPrice
                        )
                      }
                    >
                      Add to cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
          <FeaturedProducts
            headerText="Customers also bought"
            source={ApiConstants.PRODUCT_VIEW_CONST}
          />
          <FeaturedProducts
            headerText="Recently Viewed"
            source={ApiConstants.RECENTLY_VIEWED_PRODUCTS}
          />
        </div>
      </section>
    </>
  );
}

export default ProductDetails;
