import axios from "axios";
import ApiConstants from "../Constants/ApiConstants";
import { apiGet, apiGetWithoutAuth, apiPostWithAuthorization } from "./apiCall";

const api = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

api.interceptors.request.use((req) => {
  const token = localStorage.getItem("token");
  token && (req.headers.Authorization = `Bearer ${token}`);

  return req;
});

export const getOrdersCounter = async (sellerId) => {
  try {
    const response = await api.get(
      `/order/orders-counter?sellerId=${sellerId}`
    );
    return response?.data?.result;
  } catch (error) {
    return "Something went wrong";
  }
};

export const getOrdersLIst = async (
  pageNum,
  pageSize,
  sellerId,
  orderNumber,
  customerName,
  stateId,
  statusId,
  startDate,
  endDate,
  sortColumn,
  sortOrder,
) => {
  try {
    const response = await api.get(
      `order/orders?pageSize=${pageSize}&pageNum=${pageNum}&sellerId=${sellerId}&orderNumber=${orderNumber}&customerName=${customerName}&stateId=${stateId}&statusId=${statusId}&startDate=${startDate}&endDate=${endDate}&sortOrder=${sortOrder}&sortColumn=${sortColumn}`
    );
    return response?.data?.result;
  } catch (error) {
    return "Something went wrong";
  }
};

export const exportToExcel = async (
  orderNumber,
  customerName,
  stateId,
  sellerId,
  startDate,
  endDate,
  statusId,
) => {
  try {
    const response = await api.get(
      `/order/orders-excel?sellerId=${sellerId}&stateId=${stateId}&statusId=${statusId}&orderNumber=${orderNumber}&customerName=${customerName}&startDate=${startDate}&endDate=${endDate}`
    );
    return response?.data;
  } catch (error) {
    return "Something went wrong";
  }
};

export const updateProductForSeller = async (
  sellerPriceInfo, sellerId
) => {
  try {
    const response = await api.post(
      `/seller/product?sellerId=${sellerId}&productId=${sellerPriceInfo?.productId}&margin=${sellerPriceInfo?.sellerMargin}`
    );
    return response;
  } catch (error) {
    return "Something went wrong";
  }
};

export const getSellerList = async (stateId) => {
  try {
    const response = await api.get(
      `/dropdown/seller?stateId=${stateId}`
    );
    return response?.data?.result;
  } catch (error) {
    return "Something went wrong";
  }
};

export const getOrderById = async (orderId) => {
  try {
    const response = await api.get(
      `/order/orders/${orderId}`
    );
    return response?.data?.result;
  } catch (error) {
    return "Something went wrong"
  }
};


export const getShippingSlipIntoPdf = async (orderId) => {
  try {
    const response = await api.get(`/order/shipping-slip-print/${orderId}`);
    return response?.data?.result;
  } catch (error) {
    return "something went wrong"
  }
}

export const GetOrderDetailsPdfView = async (orderId) => {
  try {
    const response = await api.get(`/order/order-details-pdf/${orderId}`);
    return response?.data;
  } catch (error) {
    return "something went wrong"
  }
}


export const changeOrderStatus = async (orderId, statusId) => {
  try {
    const response = await api.get(
      `/order/orders/${orderId}/status?statusId=${statusId}`
    );
    return response?.data;
  } catch (error) {
    return "Something went wrong"
  }
};

export const SaveTrackingInfo = async (
  orderId, body
) => {
  try {
    const response = await api.post(`${ApiConstants.SAVE_TRACKING_INFO}${orderId}/tracking`, body)
    return response.data;
  } catch (error) {
    return "Something went wrong";
  }
};

export const GetDailyOrderManagement = async () => {
      const response = await apiGetWithoutAuth(`${ApiConstants.DAILY_ORDER_MANAGEMENT}`)
      return response;
};


export const getStaffMembers = async () => {
  const response = await apiGetWithoutAuth(`${ApiConstants.GET_STAFF_MEMBERS}`)
  return response;

}

export const postOrderResponsibility = async (body) => {
  const response = await apiPostWithAuthorization(ApiConstants.POST_ORDER_RESPONSIBILITY, body);
  return response;
}

export const getOrderResponsibility = async (orderId) => {
  const response = await apiGet(ApiConstants.GET_ORDER_RESPONSIBILE_DATA + "?orderId=" + orderId);
  return response;
}

