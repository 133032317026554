import React, { Fragment, useState, useEffect, useRef } from "react";
import Header from "../../Common/Header/Header";
// import { GetListOfProductInCartService } from "../../../services/customerService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/css/bootstrap.min.css";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import MinimunItemsModal from "../../Modal/MinimumItemsModal";
import ItemsAddedModal from "../../Modal/ItemsAddedModal";
import Loader from "../../Common/Loader";
import { useSelector } from "react-redux";
import { getProductDetailsAtCheckout } from "../../../services/vendorService";
import OtpValidation from "./OtpValidation";
import checkPageUrl from "../../../helpers";
import { GetCustomerRequestForRequestOrOrder } from "../../../services/checkoutService";
import { useDispatch } from "react-redux";
import SuccessModal from "../../Modal/SuccessModal";
import { AddItemsToCart, DeleteProductFromCart, GetListOfProductInCartService, GetListOfProductInCartServiceBySeller } from "../../../services/handleCartService";
import { getCartCount } from "../../../redux/features/cartSlice";
import Swal from "sweetalert2";

function MyCart() {
  const [customerName, setCustomerName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [cityName, setCityName] = useState(localStorage.getItem("cityName"));
  const [userType, setUserType] = useState(
    localStorage.getItem("userTypeName")
  );
  const [headerCityName, setHeaderCityName] = useState(
    localStorage.getItem("headerCityName")
  );
  const [headerUserType, setHeaderUserType] = useState(
    localStorage.getItem("headerUserType")
  );
  const [cityId, setCityId] = useState(localStorage.getItem("cityId"));
  const [userTypeId, setUserTypeId] = useState(
    localStorage.getItem("userTypeId")
  );
  const [searchText, setSearchText] = useState("");
  const [minimum, setMinimum] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();
  const [flagForUrl, setFlagForUrl] = useState();
  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [discountPrice, setDiscoutedPrice] = useState(totalCartPrice);
  const [successModal, setSuccessModal] = useState(false);
  const { paymentResponse } = useParams();

  const itemsQuantity = useRef([]);
  const dispatch = useDispatch();
  let urlFlag;
  urlFlag = checkPageUrl(window.location.hostname);
  // urlFlag = false;
  // const {navigate} = useNavigate();
  const content = useSelector((state) => state.Home?.HomeDetail);

  const customerQuoteData = {
    customerName: "",
    mobile: "",
    email: "",
    address: "",
    cityId: "",
    userTypeId: "",
    productDetail: [],
  };
  let checkAddQuoteResponse;

  localStorage.setItem("reload", 1);

  const handlePlaceOrder = async (data) => {
    setIsLoader(true);
    try {
      if (
        JSON.parse(localStorage.getItem("CustomerCartDetails")) !== null &&
        JSON.parse(localStorage.getItem("CustomerCartDetails")).length !== 0
      ) {
        setProductDataForQuote();


        let body;
        body = {
          ...data,
          productOrderDetail: [...customerQuoteData.productDetail],
          cityId: parseInt(cityId),
          userTypeId: parseInt(userTypeId)
        };
        console.log(JSON.stringify(body));
        const response = await GetCustomerRequestForRequestOrOrder(body);
        if (response.statusCode === 201) {
          if (data.isRFQ) {
            showSuccessModal();
            checkAddQuoteResponse = true;
            localStorage.removeItem("DakshIndiaCart");
            setUserCart1([]);
            // setItem(0);
            setTotalItemsCart(0);
          } else if (!data.isRFQ && data.paymentMode === 3) {
            showSuccessModal();
            checkAddQuoteResponse = true;
            localStorage.removeItem("DakshIndiaCart");
            setUserCart1([]);
            // setItem(0);
            setTotalItemsCart(0);
          } else {
            const customer_details = {
              customer_id: response?.result?.customerId.toString(),
              customer_name: body?.customer?.name,
              customer_email: body?.customer?.email,
              customer_phone: body?.customer?.mobileNumber,
            };
            const daksh_order_id = response?.result?.orderId;
            const payment_request = {
              order_amount: discountPrice.toFixed(2),
              customer_details: customer_details,
              order_currency: "INR",
              order_meta: {
                notify_url: process.env.REACT_APP_NOTIFY_URL,
                return_url: `${process.env.REACT_APP_PAYMENT_GATEWAY}${response?.result?.orderId}`,
              },
            };
            const reduxData = {
              paymentRequest: payment_request,
              dakshOrderId: daksh_order_id,
              sellerUrl: window.location.protocol + "//" + window.location.host,
            };
            const encoded = encodeURIComponent(JSON.stringify(reduxData));
            window.location.href = `${process.env.REACT_APP_PAYMENT_GATEWAY}null/${encoded}`;
          }
        } else {
          checkAddQuoteResponse = false;
          toast.error(response?.message);
        }
      } else {
        toast.error("Your cart is empty");
      }
    } catch (error) {
      console.warn("Something went wrong", error);
    }
    setIsLoader(false);
  };
  //#region Cart Functions
  const setProductDataForQuote = () => {
    let localStorageCart = JSON.parse(localStorage.getItem("CustomerCartDetails"));
    let i = 0;
    customerQuoteData.productDetail.splice(
      0,
      customerQuoteData.productDetail.length
    );
    while (i < localStorageCart.length) {
      customerQuoteData.productDetail.push({
        productId: localStorageCart[i].productId,
        quantity: localStorageCart[i].quantity,
        price: parseFloat(localStorageCart[i].price),
      });
      i++;
    }

    customerQuoteData.customerName = customerName;
    customerQuoteData.email = email;
    customerQuoteData.mobile = mobile;
    customerQuoteData.address = address;
    customerQuoteData.cityId = cityId;
    customerQuoteData.userTypeId = userTypeId;
  };

  var ids = "";
  var totalPrice = 0;
  var totalItems = 0;
  var cityUserTypeData = { cityId: 1, userTypeId: 1 };
  const [productInCartList, setProductInCartList] = useState([]);
  const [userCart1, setUserCart1] = useState([]);
  // const [item, setItem] = useState();
  const [totalItemsCart, setTotalItemsCart] = useState();

  const finalList = [];
  let tempArray = [];

  const GetListOfProductInCart = async () => {
    // setIsLoader(true);
    if (JSON.parse(localStorage.getItem("CustomerCartDetails")) !== null && JSON.parse(localStorage.getItem("CustomerCartDetails")).length !== 0) {
      var localStorageCart = JSON.parse(localStorage.getItem("CustomerCartDetails"));
      //setting length
      // setItem(JSON.parse(localStorage.getItem("CustomerCartDetails")).length);

      // let productDetailForQuote = [];
      var i = 1;
      ids = localStorageCart[0]?.productId?.toString();
      while (i < localStorageCart.length) {
        ids = ids + "," + localStorageCart[i].productId;
        i++;
      }

      if (urlFlag === true && content?.sellerId) {
        const response = await GetListOfProductInCartService(cityId, userTypeId, ids);
        // setProductInCartList(response.cartProductDetail);
        tempArray = response?.cartProductDetail;
      } else if (urlFlag === false && content?.sellerId) {
        const response = await GetListOfProductInCartServiceBySeller(content?.sellerId, ids);
        //  setProductInCartList(response.cartProductDetail);
        tempArray = response?.cartProductDetail;
      }

      //   const ListLength = Object.keys(localStorageCart).length;
      localStorageCart?.map((list, i) => {
        finalList.push({
          productId: list.productId,
          code: "",
          quantity: list.quantity,
          description: "",
          imageURL1: "",
          price: 0.0,
          minimumOrderQuantity: list.quantity,
          productName: "",
          ratings: "",
          material: "",
        });
      });

      // Assigning product details in quote post body from api response
      let k = 0;
      let j = 0;

      while (k < tempArray.length) {
        j = 0;
        while (j < tempArray.length) {
          if (localStorageCart[j]?.productId == tempArray[k]?.id) {
            finalList[k].productId = tempArray[k].id;
            finalList[k].quantity = localStorageCart[j].quantity;
            finalList[k].description = tempArray[k].description;
            finalList[k].imageURL1 = tempArray[k].imageURL1;
            finalList[k].price = tempArray[k].price;
            finalList[k].minimumOrderQuantity = tempArray[k].minimumOrderQuantity;
            finalList[k].code = tempArray[k].code;
            finalList[k].material = tempArray[k].material;
            finalList[k].productName = tempArray[k].productName;
            finalList[k].ratings = tempArray[k].ratings;
            finalList[k].discount = tempArray[k].discount;
            finalList[k].actualPrice = tempArray[k].actualPrice;
            break;
          }
          j++;
        }

        k++;
      }
      setUserCart1(finalList);
      let minArray = finalList.map((data) => {
        return data.quantity;
      })
      itemsQuantity.current = minArray
      setMinimum(minArray);

      i = 0;
      totalItems = 0;
    } else {
      var localStorageCart = [];
      //setting length
      setTotalItemsCart(0);
      // setItem(localStorageCart.length);
    }
    getCartDetails();
    setIsLoader(false);
  };

  useEffect(() => {
    if (content?.sellerId) {
      GetListOfProductInCart();
    }
  }, [content]);


  const deleteProductFromCart = (productId) => {
    Swal.fire({
      text: "Are you sure you want to remove ?",
      icon: "warning",
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "btn confirm-btn",
        cancelButton: "btn btn-active-light",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // debugger;
        DeleteProductFromCart(productId);
        dispatch(getCartCount());
        GetListOfProductInCart();
        setUserCart1(finalList);
        toast.success("Item removed from cart")
      }
    });


  };

  const ChangeItemsQuantity = (productId, quantity, i, price) => {
    setMinimum(quantity);
    itemsQuantity.current = quantity;
    AddItemsToCart(productId, quantity[i], price);
    GetListOfProductInCart();
  }

  const getCartDetails = () => {
    // debugger;
    let i = 0;
    while (i < finalList.length) {
      var tempPrice = 0;
      totalItems = totalItems + finalList[i].quantity;
      tempPrice = finalList[i].price * finalList[i].quantity;
      totalPrice = totalPrice + tempPrice;
      i++;
    }
    setTotalCartPrice(totalPrice);
    setTotalItemsCart(totalItems);
    setDiscoutedPrice(totalPrice);
  };
  //#endregion

  const handleClick = () => {
    if (searchText.trim().length !== 0) {
      navigate(
        urlFlag && content?.sellerId
          ? `/${cityName}/${userType}/category?searchText=${searchText}`
          : `/category?searchText=${searchText}`,
        {
          state: {
            searchText: searchText,
          },
        }
      );
    }
  };

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const toTitleCase = (str) =>
    str
      ?.match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      ?.map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
      ?.join(" ");

  const showSuccessModal = () => {
    setSuccessModal(!successModal);
  };

  const checkPaymentResponse = () => {
    if (paymentResponse !== undefined) {
      if (paymentResponse === "success") {
        checkAddQuoteResponse = true;
        localStorage.removeItem("DakshIndiaCart");
        setUserCart1([]);
        // setItem(0);
        setTotalItemsCart(0);
        showSuccessModal();
      } else if (
        paymentResponse === "cancel" ||
        paymentResponse === "cancelled"
      ) {
        toast.error("Payment Not Received, Please try again.");
      } else if (paymentResponse === "failed") {
        toast.error("Payment Failed. Please try again.");
      } else if (paymentResponse === "pending") {
        toast.error(
          "Payment status is pending. You will get mail once order is confirmed."
        );
      }
    }
  };

  useEffect(() => {
    checkPaymentResponse();
  }, []);

  useEffect(() => {
    setMinimum(itemsQuantity.current);
  }, [itemsQuantity.current]);

  useEffect(() => {
    return () => {
      Swal.close();
    };
  }, []);
  
  return (
    <>
      {/* <Header
        item={item}
        searchText={searchText}
        cityName={cityName}
        userType={userType}
        headerCityName={headerCityName}
        headerUserType={headerUserType}
        handleClick={handleClick}
        handleChange={handleChange}
      /> */}
      {isLoader && <Loader />}
      {/* <Loader /> */}
      {/* <Loader /> */}

      {/* -------------------  Main Content - Product Page ----------------- */}
      <section className="main-content-wrapper cart">
        <div className="container">
          <div className="pt-4 pb-0"></div>
          <div className="d-flex justify-content-between item-card-category mt-1">
            <div className="h5 fw-bold">My Cart</div>
          </div>
          <div className="row mt-3">
            {/* Change below to 8 */}
            <div className="col-lg-8">
              {/* <ToastContainer /> */}
              <div className="card border-0">
                <div className="card-body style-1 pt-0 py-0 mt-0 p-1">
                  <div className="row mb-4">
                    {userCart1?.length !== 0 ? (
                      userCart1?.map((data, i) => (

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-2">
                          <div className="cart-product-detail">
                            <div className="row">

                              <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                                <img
                                  className="cart-img"
                                  src={data?.imageURL1}
                                ></img>
                              </div>
                              <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                                <div className="row">
                                  <Link
                                    onClick={() => {
                                      urlFlag && content?.sellerId ? window.open(`/${cityName}/${userType}/product-details/${data.productId}`, "_blank") : window.open(`/product-details/${data.productId}`, "_blank");
                                    }}>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                      <div className="cart-product-name">
                                        {data.productName}
                                      </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                      <div className="cart-product-material">
                                        {data.material}
                                      </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-1">
                                      <div className="d-flex align-items-center">
                                        {data.discount > 0 ? (
                                          <>
                                            <div className="cart-product-discount">
                                              Save {data.discount}%
                                            </div>
                                            <div className="cart-product-price p-1 px-2 price-before-discount">
                                              ₹ {data?.price}
                                            </div>
                                          </>
                                        ) : null}
                                        <div className="cart-product-discounted-price px-2 p-1 pe-0">
                                          ₹ {data?.price}
                                        </div>
                                        <div className="cart-product-per-peice px-2 p-1 ps-1">/per piece</div>
                                      </div>
                                    </div>
                                  </Link>
                                  <div className="col-lg-5 col-md-5 col-sm-5 col-5">
                                    <div className="cart-product-order-quantity d-flex align-items-center justify-content-between ">
                                      {data.quantity <= data?.minimumOrderQuantity ? (
                                        <div className="pe-2">
                                          <i class="fa-solid fa-minus"></i>
                                        </div>
                                      ) : (
                                        <div
                                          className="pe-2"
                                          onClick={() => {
                                            const newMinimum = [...minimum];
                                            newMinimum[i]--;
                                            ChangeItemsQuantity(data?.productId, newMinimum, i, 0);
                                          }}
                                        >
                                          <i class="fa-solid fa-minus"></i>
                                        </div>
                                      )}
                                      <div className="pe-2  cart-product-min-order-quant">
                                        {itemsQuantity.current[i]}
                                      </div>
                                      <div onClick={() => {
                                        const newMinimum = [...minimum];
                                        newMinimum[i]++;
                                        ChangeItemsQuantity(data?.productId, newMinimum, i, 0);
                                      }}>
                                        <i class="fa-solid fa-plus"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-5 col-md-5 col-sm-5 col-5 cart-product-delete-icon">
                                    <i class="fa-regular fa-trash-can" onClick={() => {
                                      // setDeleteItemId(data?.id);
                                      deleteProductFromCart(data?.productId);
                                    }}></i>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <OtpValidation
              urlFlag={urlFlag}
              content={content}
              toast={toast}
              setDiscoutedPrice={setDiscoutedPrice}
              handlePlaceOrder={handlePlaceOrder}
              totalItemsCart={totalItemsCart}
              totalCartPrice={totalCartPrice}
            />
          </div>
        </div>
      </section>

      <SuccessModal
        show={successModal}
        url={urlFlag ? `/${cityName}/${userType}` : `/`}
      />

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="successmsg2"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body custom_scroll p-lg-5 text-center">
              <button
                type="button"
                className="btn-close position-absolute top-0 end-0 mt-3 me-3"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <i className="bi bi-exclamation-circle-fill"></i>
              <p className="text-muted small mb-4">Your cart is empty</p>
              <Link
                className="btn btn-success"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Continue
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="successmsg1"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body custom_scroll p-lg-5 text-center">
              <button
                type="button"
                className="btn-close position-absolute top-0 end-0 mt-3 me-3"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <i className="fa fa-times-circle"></i>
              <h4>Unsuccess</h4>
              <p className="text-muted small mb-4">Some error occured</p>
              <a
                href="#"
                className="btn btn-success"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Continue
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="deletextra"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-3 py-5">
              <button
                type="button"
                className="btn-close position-absolute top-0 end-0 mt-3 me-3"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="info-icon">
                <p>!</p>
              </div>
              <p className="text-muted small mb-4">
                Are you sure you want to delete this item?
              </p>
              <div className="modal-buttons">
                <button
                  className="btn btn-danger me-2"
                  type="button"
                  onClick={() => {
                    DeleteProductFromCart();
                  }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Yes, Delete
                </button>
                <button
                  className="btn btn-outline-success"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <ItemsAddedModal />

      {/* <!-- Modal --> */}
      <MinimunItemsModal minimum={minimum} />
    </>
  );
}
export default MyCart;
