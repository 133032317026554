/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import "../../assets/css/custom.css";
import { GetQuoteList } from "../../services/adminService";
import { GetCity, GetUserType } from "../../services/dropdownService";
import { DatePicker, Pagination } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Loader from "../Common/Loader";
const { RangePicker } = DatePicker;
function RequestForQuotation() {
    const [cityData, setCityData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [quoteListData, setQuoteListData] = useState([]);
    const [userType, setUserType] = useState([]);
    const [quoteNo, setQuoteNo] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [cityId, setCityId] = useState(1);
    const [userTypeId, setUserTypeId] = useState(1);
    const [searchQuoteNo, setSearchQuoteNo] = useState(0);
    const [searchCustomerName, setSearchCustomerName] = useState("");
    const [searchCityId, setSearchCityId] = useState(cityId);
    const [searchUserTypeId, setSearchUserTypeId] = useState(userTypeId);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [fromSearchDate, setFromSearchDate] = useState("");
    const [toSearchDate, setToSearchDate] = useState("");
    const [sortField, setSortField] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [placeholder] = useState(["Start Date", "End Date"]);
    const [totalPages, setTotalPages] = useState();
    const [totalRecords, setTotalRecords] = useState();
    const [pageSize, setPageSize] = useState(50);
    const [pageNum, setPageNum] = useState(1);
    const navigate = useNavigate();
    const [statusDropdown, setStatusDropdown] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(1);
    const [searchStatus, setSearchStatus] = useState(selectedStatus);

    const handleCity = (event) => {
        const getCityId = event.target.value;
        setCityId(getCityId);
    };

    const handleUser = (event) => {
        const getUserId = event.target.value;
        setUserTypeId(getUserId);
    };
    const handleSort = (field) => {
        // If the same field is clicked again, toggle the sorting order

        if (field === sortField) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortField(field);
            setSortOrder("asc");
        }
    };

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString("en-US", {
            month: "long",
        });
    };

    const changeDateFormat = (date) => {
        let tempDate = date.split("-");
        tempDate[1] = getMonthName(tempDate[1]);
        tempDate[1] = tempDate[1].slice(0, 3);
        let newDate = `${tempDate[2]}-${tempDate[1]}-${tempDate[0]}`;
        return newDate;
    };

    const userTypeHandler = async () => {
        try {
            const response = await GetUserType();
            setUserType(response);
        } catch (error) {}
    };
    const quoteListHandler = async () => {
        setIsLoader(true);
        try {
            const response = await GetQuoteList(pageNum, pageSize, searchQuoteNo, searchCustomerName, searchCityId, searchUserTypeId, searchStatus, fromSearchDate, toSearchDate, sortField, sortOrder);
            setQuoteListData(response.quoteList);
            let page = response.paginationResponse.totalRecord;
            setTotalRecords(page);
            setTotalPages(Math.floor(page / pageSize) + 1);
        } catch (error) {
            console.warn("Something went wrong", error.message);
        }
        setIsLoader(false);
    };

    const checkToken = () => {
        if (!localStorage.getItem("token")) {
            return navigate("/SignIn");
        }
    };
    const handleClearData = () => {
        return window.location.reload(true);
    };

    const handlerFilterData = async () => {
        setPageNum(1);
        if (quoteNo == "") {
            setSearchQuoteNo(0);
        } else {
            setSearchQuoteNo(quoteNo);
        }
        setSearchCustomerName(customerName);
        setSearchCityId(cityId);
        setSearchUserTypeId(userTypeId);
        setFromSearchDate(fromDate);
        setToSearchDate(toDate);
        setSearchStatus(selectedStatus);
        // setSortField("");
        // setSortOrder("");
    };

    const cityHandler = async () => {
        try {
            const response = await GetCity();
            setCityData(response);
        } catch (error) {
            console.warn("Something went wrong", error);
        }
    };

    const handleStatusDropdown = () => {
        const status = [
            {
                id: 1,
                name: "Open",
            },
            {
                id: 5,
                name: "Cancelled",
            },
            {
                id: 6,
                name: "Converted to Order",
            },
        ];
        setStatusDropdown(status);
    };

    const handleDate = async (v) => {
        if (v != null) {
            let from = await formatDate(v[0].$d);
            let to = await formatDate(v[1].$d);
            setFromDate(from);
            setToDate(to);
        } else {
            setFromDate("");
            setToDate("");
        }
    };

    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }

    useEffect(() => {
        cityHandler();
        userTypeHandler();
        checkToken();
        handleStatusDropdown();
    }, []);

    useEffect(() => {
        quoteListHandler();
        // }
    }, [pageNum, searchQuoteNo, searchCustomerName, searchCityId, searchUserTypeId, fromSearchDate, toSearchDate, sortField, sortOrder, pageSize, searchStatus]);

    const handleNextPage = () => {
        setPageNum(pageNum + 1);
    };
    const handlePreviousPage = () => {
        setPageNum(pageNum - 1);
    };

    const pageHandler = (number, size) => {
        setPageNum(number || pageNum);
        setPageSize(size || pageSize);
    };

    return (
        <Fragment>
            <div className="page-body px-0 py-lg-2 py-1 mt-0 mt-lg-3 ms-3">
                <div className="container-fluid p-0">
                    <div className="row g-3">
                        {/* Request Management header  */}
                        <div className="col-12">
                            <div className="row-title px-2">
                                <div>
                                    <h6 className="fw-bold">Request For Quotation (RFQ)</h6>
                                </div>
                            </div>
                        </div>
                        {/* Dash Card 1 */}
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-1 col-md-3 col-sm-2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Q. No."
                                                value={quoteNo}
                                                onChange={(e) => setQuoteNo(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-2">
                                            <input
                                                type="search"
                                                className="form-control"
                                                placeholder="Customer Name"
                                                value={customerName}
                                                onChange={(e) => setCustomerName(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-2">
                                            <select
                                                className="form-control form-select"
                                                tabIndex={-98}
                                                onChange={(e) => {
                                                    handleCity(e);
                                                }}
                                                value={cityId}>
                                                <option value={0}>-- City --</option>
                                                {cityData.map((cityData) => (
                                                    <option
                                                        key={cityData.id}
                                                        value={cityData.id}>
                                                        {cityData.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {/* Datepicker/Flatpickr */}
                                        <div className="col-lg-2 col-md-3 col-sm-2">
                                            <select
                                                className="form-control form-select"
                                                tabIndex={-98}
                                                onChange={(e) => handleUser(e)}
                                                value={userTypeId}>
                                                <option value={0}>-- User Type --</option>
                                                {userType.map((data) => (
                                                    <option
                                                        key={data.id}
                                                        value={data.id}>
                                                        {data.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-2">
                                            <select
                                                className="form-control form-select"
                                                tabIndex={-98}
                                                onChange={(e) => setSelectedStatus(e.target.value)}
                                                value={selectedStatus}>
                                                <option value={0}>-- Status --</option>
                                                {statusDropdown.map((data) => (
                                                    <option
                                                        key={data.id}
                                                        value={data.id}>
                                                        {data.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-2">
                                            <RangePicker
                                                placeholder={placeholder}
                                                dateFormat={"YYYY - MM - DD"}
                                                style={{ height: "38px" }}
                                                onChange={(v) => {
                                                    handleDate(v);
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-2">
                                            <a
                                                className="btn btn-sm btn-light-success me-2"
                                                onClick={handlerFilterData}>
                                                Filter
                                            </a>
                                            <a
                                                className="btn btn-light-secondary btn-sm"
                                                onClick={handleClearData}>
                                                Clear
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Dash Card 2 - Request Table */}
                        <div className="col-12 mt-1">
                            {isLoader && <Loader />}
                            <div className="card">
                                <div className="card-body">
                                    <div
                                        id="DataTables_Table_2_wrapper"
                                        className="dataTables_wrapper dt-bootstrap5 no-footer">
                                        <div className="row">
                                            <div className="col-sm-12 px-0 table-responsive">
                                                <div className="col-sm-12 px-0 table-responsive">
                                                    <table
                                                        className="myDataTable table table-hover mb-0 nowrap dataTable no-footer dtr-inline"
                                                        id="DataTables_Table_2"
                                                        style={{ width: "100%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    width="15%"
                                                                    className="sorting"
                                                                    aria-label="Quotation No: activate to sort column ascending"
                                                                    onClick={() => handleSort("quotationno")}>
                                                                    Quotation No.
                                                                </th>
                                                                <th
                                                                    className="sorting"
                                                                    tabIndex={0}
                                                                    aria-controls="DataTables_Table_2"
                                                                    rowSpan={1}
                                                                    colSpan={1}
                                                                    style={{ width: "2rem" }}
                                                                    aria-label="Customer Details: activate to sort column ascending"
                                                                    onClick={() => handleSort("customername")}>
                                                                    Customer Name
                                                                </th>
                                                                <th
                                                                    className="sorting"
                                                                    tabIndex={0}
                                                                    aria-controls="DataTables_Table_2"
                                                                    rowSpan={1}
                                                                    colSpan={1}
                                                                    style={{ width: "2rem" }}
                                                                    aria-label="Quotation Details: activate to sort column ascending"
                                                                    onClick={() => handleSort("city")}>
                                                                    City
                                                                </th>
                                                                <th
                                                                    className="sorting"
                                                                    tabIndex={0}
                                                                    aria-controls="DataTables_Table_2"
                                                                    rowSpan={1}
                                                                    colSpan={1}
                                                                    style={{ width: "1rem" }}
                                                                    aria-label="Status: activate to sort column ascending"
                                                                    onClick={() => handleSort("usertype")}>
                                                                    User Type
                                                                </th>
                                                                <th width="15%">Order Price</th>
                                                                <th
                                                                    className="sorting"
                                                                    tabIndex={0}
                                                                    aria-controls="DataTables_Table_2"
                                                                    rowSpan={1}
                                                                    colSpan={1}
                                                                    style={{ width: "2rem" }}
                                                                    aria-label="Date/Time: activate to sort column ascending"
                                                                    onClick={() => handleSort("recieved")}>
                                                                    Received
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {quoteListData.length > 0 ? (
                                                                quoteListData.map((data, i) => (
                                                                    <tr
                                                                        key={i}
                                                                        className="odd">
                                                                        <td>
                                                                            <Link
                                                                                to={`/admin/request-details/${data.id}`}
                                                                                className="dtr-control sorting_1"
                                                                                tabIndex={0}>
                                                                                {data?.rfqNo}
                                                                            </Link>
                                                                        </td>

                                                                        <td>{data.customerName}</td>
                                                                        <td>{data.city}</td>
                                                                        <td>{data.userType}</td>
                                                                        <td>{data.totalPrice != null ? data.totalPrice : 0}</td>
                                                                        <td>{data.receivedDate != null ? changeDateFormat(data.receivedDate.substring(0, 11)) : <span></span>}</td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td>
                                                                        <span className="cell-content">No Record Found</span>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    <nav
                                                        aria-label="Page navigation"
                                                        className="page-nav">
                                                        <Pagination
                                                            defaultCurrent={pageNum}
                                                            total={totalRecords}
                                                            hideOnSinglePage
                                                            defaultPageSize={pageSize}
                                                            responsive
                                                            onChange={(page, pageSize) => pageHandler(page, pageSize)}
                                                            showQuickJumper
                                                        />
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default RequestForQuotation;
