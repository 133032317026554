import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import checkPageUrl from "../../../helpers";
import ApiConstants from "../../../Constants/ApiConstants";
import { getVendorDetailApi } from "../../../redux/features/vendorSlice";
import "./AdminLeftMenu.css";

function AdminLeftMenu({ hideSidebar, windowWidth, showSidebar }) {
    let currentUrl = window.location.hostname;
    let urlFlag = checkPageUrl(currentUrl);
    const dispatch = useDispatch();
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
 
    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };

    if (urlFlag) {
        currentUrl = ApiConstants.DOMAIN_URL5;
    }

    useEffect(() => {
        dispatch(getVendorDetailApi(currentUrl));
    }, [dispatch, currentUrl]);

    const content = useSelector((state) => state.vendor.vendorDetail);
    const isDaksh = useSelector((state) => state.auth.isDaksh);

    localStorage.getItem("open");
    const makeLinkActive = () => {
        if (window.location.pathname === "dashboard") {
            document.getElementsByClassName("dashboard").classList.add("active ");
        } else if (window.location.pathname === "product-management") {
            document.getElementsByClassName("product-management").classList.add("active");
        }
        if (window.location.pathname === "request-for-quotation") {
            document.getElementsByClassName("rfq").classList.add("active");
        }
    };

    useEffect(() => {
        makeLinkActive();
    });

    const sideBar = () => {
        document.getElementsByClassName("sidebar")[0].classList.remove("open");
        document.getElementsByClassName("hamburger-icon")[0].classList.remove("active");
    };

    return (
        <>
            <div className={`sidebar p-2 py-md-3 dark ${hideSidebar ? (windowWidth.current > 1200 ? "sidebar-mini" : "open") : ""}`} style={{ maxHeight: 'calc(200vh - 60px)', overflowY: 'auto' }}>
                <div className="container-fluid">
                    {/* sidebar: title*/}
                    <div className="title-text d-flex align-items-center mb-4 mt-1">
                        <img
                            src={content?.headerLogo}
                            alt="daksh_india"
                            width="100%"
                        />
                    </div>
                    {/* sidebar: menu list */}
                    <div className="main-menu flex-grow-1">
                        <ul className="menu-list">
                            {isDaksh && (
                                <li className="collapsed">
                                    <NavLink
                                        className="m-link dashboard"
                                        activeclassname="active"
                                        to="/admin/dashboard">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={18}
                                            fill="currentColor"
                                            viewBox="0 0 16 16">
                                            <path
                                                fillRule="evenodd"
                                                d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                                            />
                                            <path
                                                className="fill-warning"
                                                fillRule="evenodd"
                                                d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                                            />
                                        </svg>
                                        <span
                                            className="ms-2"
                                            onClick={sideBar}>
                                            Dashboard
                                        </span>
                                        {/* </a> */}
                                    </NavLink>
                                </li>
                            )}
                            <li className="collapsed">
                                {/* <a className="m-link " href="../Admin/prod_management.html"> */}
                                <NavLink
                                    className="m-link product-management"
                                    to={isDaksh ? "/admin/product-management" : "/seller-admin/product-management"}
                                    activeclassname="active">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={18}
                                        fill="currentColor"
                                        viewBox="0 0 18 18">
                                        <path d="M3.31875 0.45C3.42354 0.310279 3.55942 0.196876 3.71563 0.118769C3.87185 0.0406632 4.0441 0 4.21875 0L13.7812 0C13.9559 0 14.1282 0.0406632 14.2844 0.118769C14.4406 0.196876 14.5765 0.310279 14.6813 0.45L17.8875 5.1C17.9605 5.19737 18 5.31579 18 5.4375V16.875C18 17.1734 17.8815 17.4595 17.6705 17.6705C17.4595 17.8815 17.1734 18 16.875 18H1.125C0.826631 18 0.540483 17.8815 0.329505 17.6705C0.118526 17.4595 0 17.1734 0 16.875V5.4375C0 5.31579 0.0394751 5.19737 0.1125 5.1L3.31875 0.45ZM8.4375 1.125H4.21875L1.6875 4.875H8.4375V1.125ZM9.5625 1.125V4.875H16.3125L13.7812 1.125H9.5625ZM16.875 6H1.125V16.875H16.875V6Z" />
                                        <rect
                                            className="fill-warning"
                                            x={3}
                                            y={8}
                                            width={5}
                                            height={7}
                                        />
                                        <path
                                            className="fill-warning"
                                            d="M10 8H15V15H10V8Z"
                                        />
                                    </svg>
                                    <span
                                        className="ms-2"
                                        onClick={sideBar}>
                                        Products
                                    </span>
                                    {/* </a> */}
                                </NavLink>
                            </li>
                            {isDaksh && (
                                <li className="collapsed">
                                    {/* <a className="m-link" href="../Admin/rfq.html"> */}
                                    <NavLink
                                        className="m-link rfq"
                                        to="/admin/request-for-quotation"
                                        activeclassname="active">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={18}
                                            fill="currentColor"
                                            viewBox="0 0 16 16">
                                            <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z" />
                                            <path
                                                className="fill-warning"
                                                d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                                            />
                                        </svg>
                                        {/* <span class="ms-2">Request for Quotation</span> */}
                                        <span
                                            className="ms-2"
                                            onClick={sideBar}>
                                            RFQ
                                        </span>
                                        {/* </a> */}
                                    </NavLink>
                                </li>
                            )}

                            <li className="collapsed">
                                {/* <a className="m-link" href="../Admin/rfq.html"> */}
                                <NavLink
                                    className="m-link rfq"
                                    to={isDaksh ? `/admin/order-management` : `/seller-admin/order-management`}
                                    activeclassname="active">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={18}
                                        fill="currentColor"
                                        viewBox="0 0 16 16">
                                        <path d="M15.985 8.50001H8.20698L2.70698 14C3.82973 14.9905 5.20793 15.6461 6.68482 15.8922C8.1617 16.1383 9.678 15.9649 11.0613 15.3919C12.4445 14.8189 13.6392 13.8692 14.5095 12.6508C15.3797 11.4325 15.8916 9.99434 15.985 8.50001ZM1.99998 13.292C1.00944 12.1693 0.353862 10.7911 0.107801 9.31418C-0.138261 7.83729 0.0350724 6.32099 0.608086 4.93774C1.1811 3.55448 2.1308 2.35979 3.34916 1.48954C4.56752 0.619291 6.00565 0.108414 7.49998 0.0150146V7.79301L1.99998 13.293V13.292Z"></path>
                                        <path
                                            className="fill-secondary"
                                            d="M8.5 7.50001V0.0150146C10.4452 0.136897 12.279 0.964622 13.6572 2.34279C15.0354 3.72096 15.8631 5.5548 15.985 7.50001H8.5Z"></path>
                                    </svg>
                                    {/* <span class="ms-2">Request for Quotation</span> */}
                                    <span
                                        className="ms-2"
                                        onClick={sideBar}>
                                        Orders
                                    </span>
                                    {/* </a> */}
                                </NavLink>
                            </li>

                            {isDaksh && (
                                <li className="collapsed">
                                    {/* <a className="m-link" href="../Admin/rfq.html"> */}
                                    <NavLink
                                        className="m-link rfq"
                                        to="/admin/request-for-quotation-old"
                                        activeclassname="active">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={18}
                                            fill="currentColor"
                                            viewBox="0 0 16 16">
                                            <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z" />
                                            <path
                                                className="fill-warning"
                                                d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                                            />
                                        </svg>
                                        {/* <span class="ms-2">Request for Quotation</span> */}
                                        <span
                                            className="ms-2"
                                            onClick={sideBar}>
                                            RFQ (Old)
                                        </span>
                                        {/* </a> */}
                                    </NavLink>
                                </li>
                            )}
                            {isDaksh && (
                                <li className="collapsed">
                                    <NavLink
                                        className="m-link seller"
                                        to="/admin/seller-management"
                                        activeclassname="active">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={18}
                                            fill="currentColor"
                                            viewBox="0 0 16 16">
                                            <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z" />
                                            <path
                                                className="fill-warning"
                                                d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                                            />
                                        </svg>
                                        <span
                                            className="ms-2"
                                            onClick={sideBar}>
                                            Seller Management
                                        </span>
                                    </NavLink>
                                </li>
                            )}
                            {isDaksh && (
                                <li className="collapsed">
                                    <NavLink
                                        className="m-link seller"
                                        to="/admin/daily-order-management"
                                        target="_blank"
                                        activeclassname="active">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={18}
                                            fill="currentColor"
                                            viewBox="0 0 16 16">
                                            <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z" />
                                            <path
                                                className="fill-warning"
                                                d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                                            />
                                        </svg>
                                        <span
                                            className="ms-2"
                                            onClick={sideBar}>
                                            Daily Order MGT
                                        </span>
                                    </NavLink>
                                </li>
                            )}
                            <li className={`collapsed ${isSubMenuOpen ? 'show' : ''}`}>
                                <NavLink style={{ backgroundColor: "#111921", color: 'white' }}
                                    className="m-link configuration"
                                    activeClassName="active"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#configurationSubMenu"
                                    onClick={toggleSubMenu}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={18}
                                        fill="currentColor"
                                        viewBox="0 0 16 16">
                                        <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z" />
                                        <path
                                            className="fill-warning"
                                            d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                                        />                      </svg>
                                    <span className="ms-2">
                                        Configurations
                                    </span>
                                </NavLink>
                                <div className={`collapse ${isSubMenuOpen ? 'show' : ''}`} id="configurationSubMenu">
                                    <ul className="ms-3 mt-2 mb-3">
                                        <li>
                                            <NavLink to="/configuration/announcements" className=" m-link announement  text-decoration-none">
                                                Announcements
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/configuration/banner" className="m-link banner text-decoration-none">
                                                Banner
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="collapsed" style={{display:"none"}}>
                                <NavLink
                                    className="m-link rfq"
                                    to="/coupons"
                                    activeclassname="active">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={18}
                                        fill="currentColor"
                                        viewBox="0 0 16 16">
                                        <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z" />
                                        <path
                                            className="fill-warning"
                                            d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                                        />
                                    </svg>
                                    <span
                                        className="ms-2"
                                        onClick={sideBar}>
                                        Coupons
                                    </span>
                                    {/* </a> */}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminLeftMenu;
