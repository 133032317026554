import axios from "axios";
import ApiConstants from "../Constants/ApiConstants";
// import store from "../redux";

const api = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

api.interceptors.request.use((req) => {
    const token = localStorage.getItem('token');
    token && (req.headers.Authorization = `Bearer ${token}`);
    
    return req;
  });

export const convertRequestQuoteToOrder = async (rfqId, isRfq) => {
  try {
    const response = await api.get(
      `/admin/${ApiConstants.CONVERT_REQUEST_QUOTE_TO_ORDER}?rfqId=${rfqId}&isRfq=${isRfq}`
    );
    return response?.data?.result;
  } catch (error) {
    return "Something went wrong"
  }
};

export const cancelRequestForQuote = async (id) => {
  try {
    const response = await api.put(
      `/admin/${ApiConstants.CANCEL_REQUEST_FOR_QUOTE}/${id}`
    );
    return response?.data?.result;
  } catch (error) {
    return "Something went wrong"
  }
};
