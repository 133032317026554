import { useSelector } from "react-redux";
import "./Decor.css"
import checkPageUrl from "../../../helpers";
import { useNavigate } from "react-router";
import { useState } from "react";

export const Decor = () => {
    const content = useSelector(state => state.Home);
    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState(false);

    let urlFlag;
    urlFlag = checkPageUrl(window.location.hostname);
    const toCategoryPage = (id) => {
        setIsLoader(true);
        if (urlFlag === true && content?.HomeDetail?.sellerId) {
            navigate(`/${localStorage.getItem("cityName")}/${localStorage.getItem("userTypeName")}/category/${id}`)
        } else if (urlFlag === false && content?.sellerId) {
            navigate(`/category/${id}`);
        }

        setIsLoader(false);
    };
    return (
        <>
            {/* Decor starts */}
            <div className="container mb-3">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-2" >
                        <div className="d-flex flex-column  Decor-Item-1 justify-content-center p-4">
                            <div class="dcor-pots m-1">Décor Pots</div>
                            <div class="grow-your-greenery-container m-1" style={{ whiteSpace: 'nowrap' }}>
                                <p class="grow-your m-0">Grow your </p>
                                <p class="grow-your m-0">Greenery in Style</p>
                            </div>
                            <div className="d-flex decor-shop-now"  onClick={() => toCategoryPage(6)}>
                                <div className="Shop-Now m-1">
                                    Shop Now
                                </div>
                                <div className=" d-flex align-items-center">
                                    <div className="Shop-Now-Icon d-flex align-items-center p-1">
                                        <i class="fa-solid fa-chevron-right"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <div className="d-flex flex-column justify-content-center Decor-Item-2 p-4 ">
                            <div class="dcor-pots m-1">Décor Items</div>
                            <div class="grow-your-greenery-container m-1" style={{ whiteSpace: 'nowrap' }}>
                                <p class="grow-your m-0">Add Elegance & </p>
                                <p class="grow-your m-0">Style with Nature</p>
                            </div>
                            <div className="d-flex decor-shop-now"  onClick={() => toCategoryPage(5)}>
                                <div className="Shop-Now m-1">
                                    Shop Now
                                </div>
                                <div className=" d-flex align-items-center">
                                    <div className="Shop-Now-Icon d-flex align-items-center p-1">
                                        <i class="fa-solid fa-chevron-right"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <div className="d-flex flex-column justify-content-center  Decor-Item-3 p-4">
                            <div class="dcor-pots m-1">Flower Pots</div>
                            <div class="grow-your-greenery-container m-1" style={{ whiteSpace: 'nowrap' }}>
                                <p class="grow-your m-0">Perfect Pots for your </p>
                                <p class="grow-your m-0">Botanical Beauties</p>
                            </div>
                            <div className="d-flex decor-shop-now"  onClick={() => toCategoryPage(9)} >
                                <div className="Shop-Now m-1">
                                    Shop Now
                                </div>
                                <div className=" d-flex align-items-center">
                                    <div className="Shop-Now-Icon d-flex align-items-center p-1">
                                        <i class="fa-solid fa-chevron-right"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Decor ends */}
            {/* Perks starts */}
            <div className="container mb-3">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div class="categories-text-1 d-flex align-items-center">
                            <img class="tabler-icon-truck" alt="" src="/Assets/img/Perks/tablericontruck.svg" width={"36px"} />
                            <div className="p-2">
                                <div class="payment-delivery">Payment & Delivery</div>
                                <div class="in-case-of">Delivery within 9-12 days</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12"
                    >
                        <div class="categories-text-11 d-flex align-items-center">
                            <img class="tabler-icon-reload" alt="" src="/Assets/img/Perks/tablericonreload.svg" width={"36px"} />
                            <div className="p-2">
                                <div class="payment-delivery">Return & Refund</div>
                                <div class="in-case-of">In case of damaged products</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div class="categories-text-12 d-flex align-items-center">
                            <img class="tabler-icon-truck" alt="" src="/Assets/img/Perks/tablericontools.svg" width={"36px"} />
                            <div className="p-2">
                                <div class="payment-delivery">Quality Support</div>
                                <div class="in-case-of">Available for any queries</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div class="categories-text-13 d-flex align-items-center">
                            <img class="tabler-icon-truck" alt="" src="/Assets/img/Perks/tablericonmailopened.svg" width={"36px"} />

                            <div className="p-2">
                                <div class="payment-delivery">Write to us</div>
                                <div class="in-case-of">Available over emails at your service</div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            {/* Persk ends */}
        </>
    )
}