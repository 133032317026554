import { Link } from "react-router-dom";
import "./ProductDetail.css";

function ProductMedia({ data, image, video, flag, imageDisplay, videoId }) {
    return (
        <>
            <div className="product-imgs">
                <div className="img-display">
                    <div className="img-showcase">
                        {flag === "video" ? (
                            <iframe
                                width="200"
                                height="200"
                                src={video}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                                title="Video Player"></iframe>
                        ) : (
                            <div style={{ position: "relative" }}>
                                <img
                                    src={image}
                                    alt="Loading"
                                    className="img-thumbnail"
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="img-select">
                    <div className="img-row">
                        {data.map((d, i) =>
                            d?.mediaType === "image" ? (
                                <div className="img-item">
                                    <Link>
                                        <img
                                            src={d?.url}
                                            style={{ maxWidth: "100px", maxHeight: "100px", aspectRatio: "1/1" }}
                                            onClick={(e) => imageDisplay(d.url, d?.mediaType)}
                                            alt="Loading"
                                        />
                                    </Link>
                                </div>
                            ) : (
                                <div className="img-item video-image-item">
                                    <Link onClick={(e) => imageDisplay(d?.url, d?.mediaType)}>
                                        <i className="fa-brands fa-youtube"></i>
                                        <img
                                            src={`https://img.youtube.com/vi/${videoId}/default.jpg`}
                                            style={{ maxWidth: "100px", maxHeight: "100px", aspectRatio: "1/1" }}
                                            title="YouTube Video"
                                            alt="YouTube Video"
                                        />
                                    </Link>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductMedia;
