import { apiGet } from "./apiCall";
import ApiConstants from "../Constants/ApiConstants";

export const GetCategory = async () => {
  const response = await apiGet(ApiConstants.CATEGORY);
  return response;
};

export const GetCity = async () => {
  const response = await apiGet(ApiConstants.GET_CITY);
  return response;
};

export const getState = async () => {
  const response = await apiGet(ApiConstants.GET_STATE);
  return response;
};

export const getStatus = async () => {
  const response = await apiGet(ApiConstants.GET_STATUS);
  return response;
};

export const GetMaterial = async () => {
  const response = await apiGet(ApiConstants.GET_MATERIAL);
  return response;
};

export const GetPopularity = async () => {
  const response = await apiGet(ApiConstants.GET_POPULARITY);
  return response;
};

export const GetShape = async () => {
  const response = await apiGet(ApiConstants.GET_SHAPE);
  return response;
};

export const GetSize = async () => {
  const response = await apiGet(ApiConstants.SIZE);
  return response;
};

export const GetUserType = async () => {
  const response = await apiGet(ApiConstants.USER_TYPE);
  return response;
};

export const GetVendor = async () => {
  const response = await apiGet(ApiConstants.GET_VENDOR);
  return response;
};

export const GetState = async () => {
  const response = await apiGet(ApiConstants.GET_STATE);
  return response;
};
