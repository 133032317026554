import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GetDashboardQuickLink, GetDashboardSummary } from "../../services/adminService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Loader from "../Common/Loader";
import ApiConstants from "../../Constants/ApiConstants";

function Dashboard() {
    const [summary, setSummary] = useState({});
    const [quickLink, setQuickLink] = useState([]);
    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState(false);
    const getDashboardSummary = async () => {
        setIsLoader(true);
        try {
            const response = await GetDashboardSummary();
            setSummary(response);
        } catch (error) {
            console.warn("Something went wrong", error.message);
        }
        setIsLoader(false);
    };

    const getDashboardQuickLink = async () => {
        setIsLoader(true);
        try {
            const response = await GetDashboardQuickLink();
            setQuickLink(response);
        } catch (error) {
            console.warn(error.message);
        }
        setIsLoader(false);
    };
    const checkToken = () => {
        if (!localStorage.getItem("token")) {
            return navigate("/SignIn");
        }
    };

    useEffect(() => {
        getDashboardSummary();
        getDashboardQuickLink();
        checkToken();
    }, []);
    return (
        <Fragment>
            <ToastContainer />
            {isLoader && <Loader />}
            <div className="page-body px-0 py-lg-2 py-1 mt-0 mt-lg-3 ms-3">
                <div className="container-fluid p-0">
                    <div className="row g-3">
                        {/* Product Management header + Add button */}

                        <div className="col-12">
                            <div className="row-title px-2">
                                <div>
                                    <h6 className="fw-bold">Summary</h6>
                                </div>
                            </div>
                        </div>
                        {/* Dash Card 1 */}
                        <div className="col-12">
                            <div className="row  cols-xl-5 row-cols-lg-5 row-cols-md-5 row-cols-sm-2 row-cols-1 g-2 mb-2 dashboard-widgets">
                                <div className="col">
                                    <div className="card p-3">
                                        <Link to="/admin/product-management">
                                            <div className="card-body text-center p-0">
                                                <div className="fs-3 text-success">{summary.category}</div>
                                                <span className="text-yellow pb-1">Product Categories</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card p-3">
                                        <Link to="/admin/product-add-form">
                                            <div className="card-body text-center p-0">
                                                <div className="fs-3 text-success">{summary.product}</div>
                                                <span className="text-yellow pb-1">
                                                    Products <br />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card p-3">
                                        <Link to="/admin/request-for-quotation">
                                            <div className="card-body text-center p-0">
                                                <div className="fs-3 text-success">{summary.quote}</div>
                                                <span className="text-yellow pb-1">Requests for Quote</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card p-3">
                                        <Link to="/admin/product-management">
                                            <div className="card-body text-center p-0">
                                                <div className="fs-3 text-success">{summary.activeProduct}</div>
                                                <span className="text-yellow pb-1">Active Products</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card p-3">
                                        <Link to="/admin/product-management">
                                            <div className="card-body text-center p-0">
                                                <div className="fs-3 text-success">{summary.inActiveProduct}</div>
                                                <span className="text-yellow pb-1">InActive Products</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="card-title m-0">Quick Links</h6>
                                </div>
                                {quickLink.map((quickLink, index) => (
                                    <div
                                        key={index}
                                        className="card-body">
                                        <label className="form-label">{quickLink.cityName}</label>
                                        <div className="row g-3 row-cols-xxl-8 cols-xl-6 row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-1 g-2 mb-2 row-deck">
                                            {quickLink.userType.map((data, i) => (
                                                <div
                                                    key={i}
                                                    className="col">
                                                    <div
                                                        className="card main-border-dashboard text-center py-0 py-lg-0 p-0 overflow-hidden"
                                                        style={{ border: "none" }}>
                                                        <div className="card-body">
                                                            {data.userTypeName === "Wholesaler" ? <i className="fa fa-truck fa-2x text-muted" /> : data.userTypeName === "Nursery" ? <i className="fa fa-home fa-2x text-muted"></i> : <i className="fa fa-shopping-cart fa-2x text-muted"></i>}

                                                            <div className="text-muted mt-2">{data.userTypeName}</div>
                                                        </div>
                                                        <CopyToClipboard
                                                            text={`${ApiConstants.QUICK_LINK_BASE_URL}${data.link.replace(" ", "-")}`} //replace to change http => https when ssl;
                                                            onCopy={() => ({ copied: true })}>
                                                            <div
                                                                className="btn btn-outline-warning btn-copy"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="top"
                                                                aria-label="Copy link">
                                                                <i className="fa fa-copy" /> Copy
                                                            </div>
                                                        </CopyToClipboard>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Dashboard;
