import { toast } from "react-toastify";
import ApiConstants from "../Constants/ApiConstants";
import { apiGet } from "./apiCall";


export const AddItemsToCart = (productId, itemMinimumOrderQuantity, itemPrice) => {
    let userCartItems = [];
    let message="Item successfully added to your cart.";
    if (JSON.parse(localStorage.getItem("CustomerCartDetails") == null)) {
        userCartItems.push({ productId: productId, quantity: itemMinimumOrderQuantity, price: itemPrice });
    }
    else {
        userCartItems = JSON.parse(localStorage.getItem("CustomerCartDetails"));
        let flag = false;
        let i = 0;
        while (i < userCartItems.length) {
            if (userCartItems[i].productId == productId) {
                // checkDuplicate = i;
                userCartItems[i].quantity = itemMinimumOrderQuantity;
                flag = true;
                break;
            }
            i++;
        }

        if (!flag) {
            userCartItems.push({ productId: productId, quantity: itemMinimumOrderQuantity, price: itemPrice });
        }
        else {
            message="infoItem Already in Cart.";
        }
    }
    const cartItemCount = userCartItems.length;
    localStorage.setItem("cartItemCount", cartItemCount)
    localStorage.setItem("CustomerCartDetails", JSON.stringify(userCartItems))
    // Dispatch the getCartCount thunk to update the cart count in Redux state

    return message;
}

export const DeleteProductFromCart = (productId) => {
    let userCartItems = [];
    if (JSON.parse(localStorage.getItem("CustomerCartDetails") != null)) {
        userCartItems = JSON.parse(localStorage.getItem("CustomerCartDetails"));
        let flag = false;
        let i = 0;
        while (i < userCartItems.length) {
            if (userCartItems[i].productId == productId) {
                // checkDuplicate = i;
                userCartItems.splice(i, 1)
                flag = true;
                break;
            }
            i++;
        }

        if (flag) {
            const cartItemCount = userCartItems.length;
            localStorage.setItem("cartItemCount", cartItemCount)
            localStorage.setItem("CustomerCartDetails", JSON.stringify(userCartItems))
        }
    }


}




// export const GetListOfProductsInCart = async () => {
//     let userCartItems = [];
//     if (JSON.parse(localStorage.getItem("CustomerCartDetails") == null)) {
//         // userCartItems.push({ id: productId, quantity: itemMinimumOrderQuantity, price: itemPrice });
//     }
//     else {
//         let productIds = '';
//         userCartItems = JSON.parse(localStorage.getItem("CustomerCartDetails"));
//         let itemsLength = userCartItems.length;
//         let i = 0;
//         while (i< itemsLength){
//             productIds += ","
//         }
//     }
// }


// const GetListOfProductInCart = async () => {
//     // setIsLoader(true);
//     debugger;
//     if (
//         JSON.parse(localStorage.getItem("CustomerCartDetails")) !== null &&
//         JSON.parse(localStorage.getItem("CustomerCartDetails")).length !== 0
//     ) {
//         var localStorageCart = JSON.parse(localStorage.getItem("CustomerCartDetails"));
//         setItem(JSON.parse(localStorage.getItem("CustomerCartDetails")).length);

//         let productDetailForQuote = [];
//         var i = 1;
//         ids = localStorageCart[0]?.productId?.toString();
//         while (i < localStorageCart.length) {
//             ids = ids + "," + localStorageCart[i].productId;
//             i++;
//         }
//         if (urlFlag === true && content?.sellerId) {
//             const response = await GetListOfProductInCartService(
//                 cityId,
//                 userTypeId,
//                 ids
//             );
//             setProductInCartList(response.cartProductDetail);
//             tempArray = response.cartProductDetail;
//         } else if (urlFlag === false && content?.sellerId) {
//             const response = await getProductDetailsAtCheckout(
//                 content?.sellerId,
//                 ids
//             );
//             setProductInCartList(response.cartProductDetail);
//             tempArray = response.cartProductDetail;
//         }

//         const ListLength = Object.keys(localStorageCart).length;
//         localStorageCart?.map((list, i) => {
//             finalList.push({
//                 id: list.productId,
//                 code: "",
//                 quantity: list.quantity,
//                 description: "",
//                 imageURL1: "",
//                 price: 0.0,
//                 minimumOrderQuantity: 0,
//                 productName: "",
//                 ratings: "",
//                 material: "",
//             });
//         });

//         // Assigning product details in quote post body from api response
//         let k = 0;
//         let j = 0;

//         while (k < tempArray.length) {
//             j = 0;
//             while (j < tempArray.length) {
//                 if (localStorageCart[j]?.productId == tempArray[k]?.productId) {
//                     finalList[k].productId = tempArray[k].productId;
//                     finalList[k].quantity = localStorageCart[j].quantity;
//                     finalList[k].description = tempArray[k].description;
//                     finalList[k].imageURL1 = tempArray[k].imageURL1;
//                     finalList[k].price = tempArray[k].price;
//                     finalList[k].minimumOrderQuantity =
//                         tempArray[k].minimumOrderQuantity;
//                     finalList[k].code = tempArray[k].code;
//                     finalList[k].material = tempArray[k].material;
//                     finalList[k].productName = tempArray[k].productName;
//                     finalList[k].ratings = tempArray[k].ratings;

//                     break;
//                 }
//                 j++;
//             }

//             k++;
//         }
//         setUserCart1(finalList);

//         i = 0;
//         totalItems = 0;
//         while (i < finalList.length) {
//             var tempPrice = 0;
//             totalItems = totalItems + finalList[i].quantity;
//             tempPrice = finalList[i].price * finalList[i].quantity;
//             totalPrice = totalPrice + tempPrice;
//             i++;
//         }
//         setTotalCartPrice(totalPrice);
//         setTotalItemsCart(totalItems);
//         setDiscoutedPrice(totalPrice);
//     } else {
//         var localStorageCart = [];
//         //setting length
//         setTotalItemsCart(0);
//         setItem(localStorageCart.length);
//     }
//     setIsLoader(false);
// };

export const GetListOfProductInCartService = async (cityId, userTypeId, productsIds) => {
    const tempIds = productsIds.replaceAll(",", "%2C");
    const response = await apiGet(ApiConstants.GET_CART_PRODUCT_DETAIL + cityId + "/" + userTypeId + ApiConstants.GET_PRODUCT_DETAILS_FOR_CART + productsIds);
    return response;
};

export const GetListOfProductInCartServiceBySeller = async (sellerId, productsIds) => {
    const tempIds = productsIds.replaceAll(",", "%2C");
    const response = await apiGet(ApiConstants.GET_CART_PRODUCT_DETAIL + sellerId + ApiConstants.GET_PRODUCT_DETAILS_FOR_CART + productsIds);
    return response;
};

