import { apiGet, apiPost, apiStatusCodeGet } from "./apiCall";
import ApiConstants from "../Constants/ApiConstants";

export const GetHomePageData = async (cityId, userTypeId) => {
    const response = await apiGet(ApiConstants.CUSTOMER_HOME_PAGE + cityId + "/" + userTypeId + "/home-page");
    return response;
};

export const AddQuote = async (cunstomerQuoteData) => {
    const response = await apiPost(ApiConstants.CUSTOMER_QUOTE, cunstomerQuoteData);
    return response;
};

export const getProductDetailId = async (id, cityId, userTypeId) => {
    const response = await apiGet(ApiConstants.GET_PRODUCT_INFORMATION + cityId + "/" + userTypeId + "/product/" + id);
    return response;
};

export const GetProductCategoryById = async (id, pageNum, pageSize, popularityIds, materialsIds, shapeIds, sizeIds, priceIds, searchText, cityId, userTypeId) => {
    const response = await apiGet(ApiConstants.GET_CATEGORY_INFORMATION + cityId + "/" + userTypeId + "/product-by-category?id=" + id + "&pageNum=" + pageNum + "&pageSize=" + pageSize + "&popularityIds=" + popularityIds + "&materialIds=" + materialsIds + "&shapeIds=" + shapeIds + "&sizeIds=" + sizeIds + "&priceIds=" + priceIds + "&searchItem=" + searchText);
    return response;
};

export const GlobalSearch = async (pageSize, searchText, cityId, userTypeId) => {
    const response = await apiGet(ApiConstants.GLOBAL_SEARCH + cityId + "/" + userTypeId + "/global-search/" + pageSize + "?searchItem=" + searchText);
    return response;
};

export const GetCityUserType = async (city, userType) => {
    const response = await apiStatusCodeGet(ApiConstants.GET_CITY_USERTYPE + city + "/" + userType);
    return response;
};

export const GetPriceModalValue = async () => {
    const response = await apiGet(ApiConstants.GET_PRICE_LIST);
    return response;
};

// export const GetListOfProductInCartService = async (cityId, userTypeId, ids) => {
//     if (ids.includes(",")) {
//         const tempIds = ids.replaceAll(",", "%2C");
//         //console.log(ApiConstants.GET_CART_PRODUCT_DETAIL+tempIds+"/1/100")
//         const response = await apiGet(ApiConstants.GET_CART_PRODUCT_DETAIL + cityId + "/" + userTypeId + "/cart/" + tempIds);
//         return response;
//     } else {
//         const response = await apiGet(ApiConstants.GET_CART_PRODUCT_DETAIL + cityId + "/" + userTypeId + "/cart/" + ids);
//         return response;
//     }
// };

// export const GlobalSearchSuggestions = async (cityId, userTypeId, searchText) => {
//     const response = await apiGet(ApiConstants.GLOBAL_SEARCH_SUGGESTIONS + cityId + "/" + userTypeId + "?searchText=" + searchText);
//     return response;
// };
