import ApiConstants from "../Constants/ApiConstants"

export default function checkPageUrl(currentUrl) {
  try {
    if (
      currentUrl === ApiConstants.DOMAIN_URL1 ||
      currentUrl === ApiConstants.DOMAIN_URL2 ||
      currentUrl === ApiConstants.DOMAIN_URL3 ||
      currentUrl === ApiConstants.DOMAIN_URL4 ||
      currentUrl === ApiConstants.DOMAIN_URL5
    ) {
        return true;
        //That is Daksh URL
    }

    else {
    
        return false;
        //That is Seller URL
    }
  } catch (error) {
    console.log("Something went wrong", error.message);
  }
}
