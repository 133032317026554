import ApiConstants from "../Constants/ApiConstants";
import { apiGet } from "./apiCall";


export const getProductDetailsById = async (productId, cityId, userTypeId) => {
    const response = await apiGet("product/" + cityId + "/" + userTypeId + "/" + ApiConstants.GET_PRODUCT_DETAILS_BY_ID + productId);
    return response;
};

export const getProductDetailsBySeller = async (sellerId, productId) => {
    try {
        const response = await apiGet(`product/${sellerId}/${ApiConstants.GET_PRODUCT_DETAILS_BY_ID}${productId}`)
        return response;
    } catch (error) {
        console.log("Something went wrong");
    }
}

export const saveRecentlyViewedItem = (productDetails) => {
    let recentlyViewedItem = [];
    if (JSON.parse(localStorage.getItem("RecentlyViewedItem") == null)) {
        recentlyViewedItem.push({
            id: productDetails?.productDetailCustomer.id,
            name: productDetails?.productDetailCustomer.name,
            material: productDetails?.productDetailCustomer.material,
            ratings: productDetails?.productDetailCustomer.rating,
            minimumOrderQuantity: productDetails?.productDetailCustomer.minimumOrderQuantity,
            price: productDetails?.productDetailCustomer.basePrice,
            code: productDetails?.productDetailCustomer.code,
            actualPrice: productDetails?.productDetailCustomer.actualPrice,
            discount: productDetails?.productDetailCustomer.discount,
            imageURL: productDetails?.productMedia?.find(thumbnail => thumbnail.isThumbnail)?.url

        });
    }
    else {
        recentlyViewedItem = JSON.parse(localStorage.getItem("RecentlyViewedItem"));
        let flag = false;
        let i = 0;

        if (recentlyViewedItem) {
            while (i < recentlyViewedItem.length) {
                if (recentlyViewedItem[i].id == productDetails?.productDetailCustomer.id) {
                    flag = true;
                    break;
                }
                i++;
            }
        }

        if (recentlyViewedItem.length === 10 && flag === false) {
            recentlyViewedItem.splice(0, 1); // Remove the element at index i
        }

        if (!flag) {
            recentlyViewedItem.push({
                id: productDetails?.productDetailCustomer.id,
                name: productDetails?.productDetailCustomer.name,
                material: productDetails?.productDetailCustomer.material,
                ratings: productDetails?.productDetailCustomer.rating,
                minimumOrderQuantity: productDetails?.productDetailCustomer.minimumOrderQuantity,
                price: productDetails?.productDetailCustomer.basePrice,
                code: productDetails?.productDetailCustomer.code,
                actualPrice: productDetails?.productDetailCustomer.actualPrice,
                discount: productDetails?.productDetailCustomer.discount,
                imageURL: productDetails?.productMedia?.find(thumbnail => thumbnail.isThumbnail)?.url

            });
        }

    }

    localStorage.setItem("RecentlyViewedItem", JSON.stringify(recentlyViewedItem))

    // Dispatch the getCartCount thunk to update the cart count in Redux state

    return recentlyViewedItem;
}

export const getMostBoughtProductsApi = async (cityId, userTypeId) => {
    const response = await apiGet("product/" + cityId + "/" + userTypeId + "/" + ApiConstants.GET_MOST_BROUGHT_PRODUCTS);
    return response;
}

export const getMostBoughtProductsBySeller = async (sellerId) => {
    const response = await apiGet("product/" + sellerId + "/" + ApiConstants.GET_MOST_BROUGHT_PRODUCTS);
    return response;
}

