/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import ProductCategory from "./ProductCategory";
import "../Product/Product.css";
import { useParams, useNavigate } from "react-router";
import { CheckValidUser } from "../Common/CheckForValidUser";
import Loader from "../Common/Loader";
import checkPageUrl from "../../helpers";
import { useSelector } from "react-redux";
import HomeBanner from "../Home/Home-Banner/Home-Banner";
import { Decor } from "../Home/Decor/Decor";
import { FeaturedProducts } from "../Home/Featured-Products/Featured-Products";
import { Different } from "../Home/Different/Different";
import ApiConstants from "../../Constants/ApiConstants";
import { ToastContainer } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { StyleRoot } from "radium";


function Product() {
    const [isLoader, setIsLoader] = useState(false);
    const [item, setItem] = useState();
    const { cityName, userType } = useParams();
    const [validUser, setValidUser] = useState(false);
    const [headerCityName, setHeaderCityName] = useState("");
    const [headerUserType, setHeaderUserType] = useState("");
    const [cityId, setCityId] = useState();
    const [userTypeId, setUserTypeId] = useState();
    let urlFlag = checkPageUrl(window.location.hostname);
    const [flagForPage, setFlagForPage] = useState(urlFlag);
    const [featuredProducts, setFeaturedProducts] = useState([]);
    const sellerId = useSelector((state) => state.Home.sellerId);

    const navigate = useNavigate();
    useEffect(() => {
        if (flagForPage === true) {
            checkForValidUser();
        }
    }, []);

    // const content = useSelector((state) => state.Home.HomeDetail);

    const checkForValidUser = async () => {
        setIsLoader(true);
        const checkValidUser = await CheckValidUser(cityName, userType);
        setValidUser(checkValidUser);

        if (!checkValidUser) {
            navigate("/about-us");
        } else {
            setCityId(localStorage.getItem("cityId"));
            setUserTypeId(localStorage.getItem("userTypeId"));
            setHeaderCityName(localStorage.getItem("headerCityName"));
            setHeaderUserType(localStorage.getItem("headerUserType"));
        }
        setIsLoader(false);
    };


    // const navigateToProductDetails = (productId) => {
    //     if (urlFlag === true && content?.sellerId) {
    //         window.open(`/${cityName}/${userType}/product-details/${productId}`, "_blank", "noopener");
    //     } else if (urlFlag === false && content?.sellerId) {
    //         window.open(`/product-details/${productId}`, "_blank", "noopener");
    //     }
    // };

    // const handleClick = () => {
    //     setIsLoader(true);
    //     if (searchText.trim().length !== 0) {
    //         debugger;
    //         console.log(urlFlag+" "+content?.sellerId);
    //         if (urlFlag && content?.sellerId) {
    //             navigate(`/${cityName}/${userType}/category?searchText=${searchText}`, {
    //                 state: {
    //                     searchText: searchText,
    //                     isGlobalSearchNavigation: true,
    //                 },
    //             });
    //         } else if (!urlFlag && content?.sellerId) {
    //             navigate(`/category?searchText=${searchText}`, {
    //                 state: {
    //                     searchText: searchText,
    //                     isGlobalSearchNavigation: true,
    //                 },
    //             });
    //         }
    //     }
    //     setIsLoader(false);
    // };

    // const handleChange = (e) => {
    //     setSearchText(e.target.value);
    // };

    return (
        <>
        <StyleRoot>
            {flagForPage === false ? (
                <>

                    <HomeBanner />
                    <ProductCategory />
                    <Decor />
                    <FeaturedProducts headerText="Discounts & Offers - Upto 35% Off" source={ApiConstants.HOME_CONST} />
                    <Different />
                    {isLoader && <Loader />}
                    {/* <Category1
                        setItem={setItem}
                        toProductDetails={navigateToProductDetails}
                    />
                    <Category2
                        setItem={setItem}
                        toProductDetails={navigateToProductDetails}
                    />  */}
                </>
            ) : validUser ? (
                <>
                    {/* <Header
                        item={item}
                        searchText={searchText}
                        cityName={cityName}
                        userType={userType}
                        headerCityName={headerCityName}
                        headerUserType={headerUserType}
                        handleClick={handleClick}
                        handleChange={handleChange}
                    /> */}
                    <ToastContainer/>
                    <HomeBanner />
                    <ProductCategory />
                    <Decor />
                    <FeaturedProducts headerText="Discounts & Offers - Upto 35% Off" source={ApiConstants.HOME_CONST} />
                    <Different />
                    {isLoader && <Loader />}
                    {/* <Category1
                        setItem={setItem}
                        toProductDetails={navigateToProductDetails}
                    />
                    <Category2
                        setItem={setItem}
                        toProductDetails={navigateToProductDetails}
                    />  */}
                </>
            ) : (
                <></>
            )}
            </StyleRoot>
        </>
    );
}

export default Product;
