import React, { Fragment, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import GlobalSearchSuggestion from "./GlobalSearchSuggestion";
import { useState } from "react";
// import { GlobalSearchSuggestions } from "../../../services/customerService";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
// import { getVendorDetailApi } from "../../../redux/features/vendorSlice";
import checkPageUrl from "../../../helpers";
import ApiConstants from "../../../Constants/ApiConstants";
import "./Header.css"
import { getAnouncementsApi, getHomeDetailApi, getProductCategoriesApi } from "../../../redux/features/homeSlice";
import { ProductsSearchSuggestion } from "../../../services/Home/homeService";
import { ProductSearchSuggestionsForSeller } from "../../../services/filterService"
import { Dropdown } from "react-bootstrap";


function Header({ handleClick, handleChange, headerCityName, headerUserType, cityName, userType, searchText, item, show, setNewSearchText, validUser, categoryId, setCategoryId }) {
    const inputRef = useRef(null);
    const [data, setData] = useState([]);
    const [isSearchSuggestionModal, setIsSearchSuggestionModal] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [flagForPage, setFlagForPage] = useState();
    const [searchShow, setSearchShow] = useState(false);
    // const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState("");
    let currentUrl = window.location.hostname;

    let urlFlag = checkPageUrl(currentUrl);

    if (urlFlag) {
        currentUrl = ApiConstants.DOMAIN_URL5;
    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getHomeDetailApi(currentUrl));
        //  dispatch(getAnouncementsApi(con));
        setFlagForPage(urlFlag);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentUrl]);

    // const content = useSelector((state) => state.vendor.vendorDetail);
    const content = useSelector((state) => state.Home.HomeDetail);

    useEffect(() => {
        let sellerId = content?.sellerId;
        if (sellerId != undefined) {
            dispatch(getAnouncementsApi(content?.sellerId));
        }

    }, [dispatch, content?.sellerId]);

    const announcement = useSelector((state) => state.Home.Anouncement);

    useEffect(() => {
        dispatch(getProductCategoriesApi());
    }, [dispatch])

    const ProductCategories = useSelector((state) => state.Home.ProductCategories);

    function handleCategoryClick(e, id, name) {
        e.preventDefault();
        // debugger;
        setCategoryId(id);
        setCategoryName(name);

    }

    if (content?.title) {
        document.title = content?.title;
        let link = document.querySelector("link[rel~='icon']");
        link.href = content?.favicon;
    }

    const handleSearchSuggestionModal = (show) => {
        setIsSearchSuggestionModal(show);
    };



    const searchSuggestions = async () => {
        setIsLoader(true);
        let response;
        if (urlFlag === true && content?.sellerId) {
            // response = await GlobalSearchSuggestions(localStorage.getItem("cityId"), localStorage.getItem("userTypeId"), searchText);
            response = await ProductsSearchSuggestion(localStorage.getItem("cityId"), localStorage.getItem("userTypeId"), categoryId, searchText);

        } else if (urlFlag === false && content?.sellerId) {
            response = await ProductSearchSuggestionsForSeller(content?.sellerId, searchText, categoryId);
        }
        // console.log(response);
        setData(response);
        setIsLoader(false);
    };

    function handleSearchShow(e) {
        e.preventDefault();
        setSearchShow(!searchShow);
    }

    useEffect(() => {
        $(document).click(function (e) {
            if (!$(e.target).closest(".modal").length) {
                if (isSearchSuggestionModal === true) {
                    handleSearchSuggestionModal(false);
                }
            }
        });
    });

    useEffect(() => {
        if (searchText?.length >= 3) {
            searchSuggestions();
            handleSearchSuggestionModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    useEffect(() => {
        handleSearchSuggestionModal(show);
    }, [show]);

    return (
        <>
            {/* Announcement Start */}
            <div className='Discount-Header container-fluid mb-2'>
                <div className='Discount-Text text-center p-1 pb-0'>
                   <marquee>{announcement}</marquee> </div>
            </div>
            {/* Announcement End */}

            {/* Header Start */}
            <div className="header">
                <div className="container">
                    <div className='d-flex py-2 align-items-center justify-content-center'>
                        <div className='header-img text-center'>
                            <Link to={urlFlag && content?.sellerId ? `/${cityName}/${userType}` : `/`}>
                                <img
                                    src={content?.headerLogo}
                                    // src="/Assets/img/header/Daksh-logo.png"
                                    alt="logo" />
                            </Link>
                        </div>
                        <div className='vr my-1 mx-3'></div>
                        {
                            flagForPage && (
                                <div className='mx-2 gurugram-wholesaler'>
                                    <div className='gurugram'>{headerCityName}</div>
                                    <div className='wholesaler'>{headerUserType}</div>
                                </div>
                            )
                        }
                        <div className='header-input mx-2 d-flex justify-content-between align-items-center '>
                            {urlFlag && validUser !== undefined && !validUser ? (
                                <p>{validUser}</p>
                            ) : (
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control border-0 header-inputbox"
                                        aria-label="Text input with dropdown button"
                                        placeholder='Search for products..'
                                        ref={inputRef}
                                        aria-describedby="basic-addon1"
                                        value={searchText}
                                        onChange={handleChange}
                                        onBlur={handleChange}
                                    />
                                    <Dropdown>
                                    <Dropdown.Toggle className="header-dropdown">{categoryId === 0 ? ProductCategories.length > 0 && ProductCategories[0].name : categoryName}</Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-end">

                                        {
                                            ProductCategories.map((item) => {
                                                return (
                                                    <Dropdown.Item><a onClick={(e) => handleCategoryClick(e, item.id, item.name)} id={item.id} className="dropdown-item p-0" href="#">{item.name}</a></Dropdown.Item>
                                                )
                                            })
                                        }
                                    </Dropdown.Menu>
                                    </Dropdown>
                                    <button className='btn header-search-button' onClick={() => handleClick(searchText)} >
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </button>

                                </div>
                            )

                            }


                            {isSearchSuggestionModal === true && (
                                <GlobalSearchSuggestion
                                    content={content}
                                    urlFlag={urlFlag}
                                    handleClick={handleClick}
                                    isLoader={isLoader}
                                    searchText={searchText}
                                    setNewSearchText={setNewSearchText}
                                    data={data}
                                    cityName={cityName}
                                    userType={userType}
                                // categoryId={categoryId}
                                // // categoryName={categoryName}
                                // // setCategoryName={setCategoryName}
                                />
                            )}
                            <div onClick={handleSearchShow}>
                                <i className="fa fa-search search-icon"></i>
                            </div>
                        </div>

                        <div className='header-login'>
                            <div className='login-signup'>Login / Signup</div>
                            <div className='my-account'>My account</div>
                            <i className="fa-regular fa-user header-user-icon"></i>
                        </div>
                        <div className='vr my-1 mx-3'></div>

                        <div className=''>
                            <Link
                                to={flagForPage && content?.sellerId ? `/${cityName}/${userType}/my-cart` : `/my-cart`}>
                                <div className="header-cart d-flex">
                                    <div className="header-cart-icon">
                                        <img src='/Assets/img/header/tabler-icon-shopping-cart.svg' className='mx-1 header-cart-image' />
                                        <span className="header-cart-item px-1">{item > 0 ? item : 0}</span>
                                    </div>
                                    <div className="header-cart-text">cart</div>
                                </div>
                            </Link>

                        </div>
                    </div>
                    {
                        searchShow && (
                            <div className="mb-2 header-inputbox-2">
                                <input type="text" className="form-control header-inputbox2 rounded-0" aria-label="Text input with dropdown button" placeholder='Search for products..'

                                    ref={inputRef}
                                    aria-describedby="basic-addon1"
                                    value={searchText}
                                    onChange={handleChange} />
                                <button className='btn header-search-button2' onClick={() => handleClick(searchText)} >
                                    <i className="fa-solid fa-magnifying-glass"></i>
                                </button>
                                {isSearchSuggestionModal === true && (
                                    <GlobalSearchSuggestion
                                        content={content}
                                        urlFlag={urlFlag}
                                        handleClick={handleClick}
                                        isLoader={isLoader}
                                        searchText={searchText}
                                        setNewSearchText={setNewSearchText}
                                        data={data}
                                        cityName={cityName}
                                        userType={userType}
                                        categoryId={categoryId}
                                    />
                                )}
                            </div>
                        )
                    }

                </div>
            </div>
            {/* Header Start */}
        </>
    );
}

export default Header;
