import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { GetQuoteDetails } from "../../services/adminService";
import Star from "../User/MyCart/Rating";
import Loader from "../Common/Loader";
import {
  convertRequestQuoteToOrder,
  cancelRequestForQuote,
} from "../../services/rfqService";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import "../../assets/css/custom.css"

function RequestDetails() {
  const [customerDetail, setCustomerDetails] = useState({});
  const [quoteProductDetail, setQuoteProductDetail] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [cancelRfq, setCancelRfq] = useState(false);
  const [convertToOrder, setConvertToOrder] = useState(false);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    getQuoteDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    priceCalculate();
  });

  const getQuoteDetails = async () => {
    setIsLoader(true);
    try {
      const response = await GetQuoteDetails(id);
      setCustomerDetails(response?.customerDetail);
      setQuoteProductDetail(response?.requestQuoteProductDetail);
      if (
        response?.requestQuoteProductDetail[0]?.status ===
          "Converted to Order" ||
        response?.requestQuoteProductDetail[0]?.status === "Cancelled"
      ) {
        setIsStatusChanged(true);
      }
    } catch (error) {
      console.warn("Something went wrong", error.message);
    }
    setIsLoader(false);
  };

  // const convertRequestToOrder = async (e) => {
  //   setIsLoader(true);
  //   try {
  //     const response = await convertRequestQuoteToOrder(id, true);
  //     if (response?.isSuccess) {
  //       toast.success(response?.message);
  //       getQuoteDetails();
  //     }
  //   } catch (error) {
  //     console.warn("Something went wrong", error.message);
  //   }
  //   setIsLoader(false);
  // };

  const convertRequestToOrder = async (e) => {
    Swal.fire({
      text: "Are you sure you want to convert request to order?",
      icon: "warning",
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-active-light",
      },
    }).then(async function (result) {
      if (result?.value === true) {
        setIsLoader(true);
        try {
          const response = await convertRequestQuoteToOrder(id, true);
          if (response?.isSuccess) {
            toast.success(response?.message);
            getQuoteDetails();
            setConvertToOrder(true);
          }
        } catch (error) {
          console.warn("Something went wrong", error.message);
        }
        setIsLoader(false);
      }
    });
  };

  const cancelRequest = async (e) => {
    Swal.fire({
      text: "Are you sure you want to cancel the request?",
      icon: "warning",
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-active-light",
      },
    }).then(async function (result) {
      if (result?.value === true) {
        setIsLoader(true);
        try {
          const response = await cancelRequestForQuote(id);
          if (response?.isSuccess) {
            toast.success(response?.message);
            getQuoteDetails();
            setCancelRfq(true);
          }
        } catch (error) {
          console.warn("Something went wrong", error.message);
        }
        setIsLoader(false);
      }
    });
  };

  const priceCalculate = () => {
    let total = 0;
    for (let i = 0; i < quoteProductDetail?.length; i++) {
      total =
        total + quoteProductDetail[i]?.price * quoteProductDetail[i]?.quantity;
      setTotalPrice(total);
    }
  };

  const toTitleCase = (str) =>
    str
      ?.match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      ?.map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
      ?.join(" ");

  const getStatusColor = () => {
    switch (quoteProductDetail[0]?.status) {
      case "Cancelled":
        return "red";
      case "Converted to Order":
        return "green";
      default:
        return "blue";
    }
  };

  return (
    <Fragment>
      {isLoader && <Loader />}
      <div className="page-body px-0 py-lg-2 py-1 mt-0 mt-lg-3 ms-3">
        <ToastContainer />
        <div className="container-fluid p-0">
          <div className="row g-3">
            <div className="col-12">
              <div className="row-title px-2">
                <div>
                  <h6 className="fw-bold">
                    <Link to="/admin/request-for-quotation">
                      <i className="fa fa-arrow-left me-2" /> RFQ
                    </Link>
                    <span> </span>#{quoteProductDetail[0]?.rfqNo}
                  </h6>
                </div>
                <div className="d-flex morphing scale-left">
                  <span className="pt-1 mt-1">
                    Status:{" "}
                    <span style={{ color: getStatusColor() }}>
                      {quoteProductDetail[0]?.status}
                    </span>
                  </span>
                  <div className="pe-lg-0 ms-4 border-left ps-3">
                    <div className="input-group">
                      <button
                        className="btn btn-outline-warning text-dark"
                        type="button"
                        onClick={(e) => {
                          cancelRequest(e);
                        }}
                        disabled={convertToOrder || isStatusChanged}
                      >
                        <span className="d-none d-md-inline-block ms-2">
                          Cancel RFQ
                        </span>
                      </button>
                      <button
                        className="btn btn-warning text-dark"
                        type="button"
                        onClick={(e) => {
                          convertRequestToOrder(e);
                        }}
                        disabled={cancelRfq || isStatusChanged}
                      >
                        <span className="d-none d-md-inline-block ms-2">
                          Convert to Order
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Product Management header */}
            <div className="row cart-row pt-3">
              <div className="col-lg-8">
                <div className="card border-0">
                  <div className="card-header pb-0">
                    <p className="heading">Items</p>
                  </div>
                  <div className="card-body product-list scrollbar style-1 mt-0 pt-0">
                    <div className="row">
                      {quoteProductDetail?.map((data) => (
                        <div className="col-lg-6" key={data.id}>
                          <div className="card card-product  mb-3">
                            <div className="row p-3">
                              <div className="col-lg-4 col-md-3 col-sm-3 col-4 mt-0">
                                {data?.imageURL1 != null ? (
                                  <img  className="fixed-dimensions" src={data?.imageURL1} alt="" />
                                ) : (
                                  <img alt="" />
                                )}
                              </div>
                              <div className="col-lg-8 col-md-9 col-sm-9 col-8">
                                <div className="details">
                                  <p className="mb-2 text-muted h6">
                                    {data?.code}
                                  </p>
                                  <p
                                    className="mb-1"
                                    style={{
                                      fontSize: "1rem",
                                      fontweight: "bold",
                                    }}
                                  >
                                    {data?.productName?.length > 15
                                      ? toTitleCase(
                                          data?.productName?.substring(0, 15)
                                        ) + "....."
                                      : toTitleCase(data?.productName)}
                                  </p>
                                  <span className="mb-1">{data?.material}</span>
                                  <p
                                    // className="mb-1"
                                    style={{ marginTop: "20px" }}
                                  >
                                    <Star stars={data?.ratings} />
                                  </p>
                                  <h6 className="mb-2">
                                    <strong>
                                      <span>₹ {data?.price}</span>
                                    </strong>
                                  </h6>
                                </div>
                                <div className="quantity-button">
                                  <div className="input-group mb-3 ">
                                    <input
                                      type="number"
                                      min={0}
                                      value={data?.quantity}
                                      style={{ height: "33px", width: "49px" }}
                                    />
                                    <span
                                      className="input-group-text"
                                      id="basic-addon2"
                                    >
                                      <i className="fa-solid fa-cart-plus" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card form-card">
                  <div className="card-body">
                    <p className="heading">Customer Detail</p>
                    <form action="#">
                      <div className="mb-3">
                        <label className="form-label">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={customerDetail?.customerName}
                          disabled="disabled"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Mobile</label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          value={customerDetail?.mobile}
                          disabled="disabled"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id=""
                          value={customerDetail?.email}
                          disabled="disabled"
                        />
                      </div>
                      {/* <div className="mb-3">
                        <label className="form-label">User Type</label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          value={customerDetail?.userType}
                          disabled="disabled"
                        />
                      </div> */}
                      <div className="mb-3">
                        <label className="form-label">State</label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          value={customerDetail?.state}
                          disabled="disabled"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Address</label>
                        <textarea
                          name=""
                          id=""
                          className="form-control"
                          rows={2}
                          disabled="disabled"
                          value={customerDetail?.address}
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="card bg-success mt-3 total-price-card">
                  <div className="card-body">
                    <p className="mb-0">
                      <span className="items">
                        {quoteProductDetail?.length > 0
                          ? quoteProductDetail?.length
                          : 0}{" "}
                        Items
                      </span>{" "}
                      <i />
                      <span className="price ps-1">
                        {" "}
                        <strong>₹{totalPrice}</strong>
                      </span>{" "}
                    </p>
                    <p className="request mb-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default RequestDetails;
