import React from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import "./App.css";
import Login from "./components/AdminLogin/Login";
import Category from "./components/Category/Category";
import Layout from "./components/Common/Layout";
import UserLayout from "./components/Common/UserLayout";
import Dashboard from "./components/Dashboard/Dashboard";
import NotFound from "./components/Dashboard/NotFound";
import Product from "./components/Product/Product";
import ProductAddForm from "./components/ProductAddForm/ProductAddForm";
import ProductDetails from "./components/ProductDetail/ProductDetail";
import ProductManagement from "./components/ProductManagement/ProdManagement";
import RequestDetails from "./components/RequestDetails/RequestDetails";
import RequestForQuotation from "./components/RFQ/RequestForQuotation";
import MyCart from "./components/User/MyCart/MyCart";
import OrderManagement from "./components/OrderManagement/Order";
import OrderDetails from "./components/OrderManagement/OrderDetails";
import { useSelector } from "react-redux";
import PaymentConfirmation from "./components/User/MyCart/PaymentConfirmation";
import RequestForQuotationOld from "./components/RFQ/RequestForQuotationOld";
import RequestDetailsOld from "./components/RequestDetails/RequestDetailsOld";
import AboutUs from "./components/AboutUs/AboutUs";
import Terms from "./components/AboutUs/Terms&Cond";
import Policy from "./components/AboutUs/Privacy";
import SellerOnboarding from "./components/SellerOnboarding/SellerOnboarding";
import SellerManagement from "./SellerManagement/SellerManagement";
import { DailyOrderManagement } from "./components/OrderManagement/DailyOrderManagement";
import { PostAnnouncement } from "./services/adminService";
import Banner from "./components/Configuration/Banner";
import Announcements from "./components/Configuration/Announcements";
import Coupons from "./components/Coupon/Coupons";
import { GlobalProductFilter } from "./components/GlobalProductSearch/ProductFilters";
import { ToastContainer } from 'react-toastify'; 

function App() {
  const isAdmin = useSelector((state) => state.auth.isAuthenticated);
  const isDaksh = useSelector((state) => state.auth.isDaksh);
 
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="light"
        stacked={true}
      />

      <Routes>
        <Route path="/signin" element={<Login />} />
        <Route
          path="/payment-gateway/:dakshOrderId?/:requestBody?/:orderId?/:sellerUrl?/"
          element={<PaymentConfirmation />}
        />

        <Route element={<UserLayout />}>
          <Route path="/:cityName?/:userType?" element={<Product />} />

          <Route
            path="/:cityName?/:userType?/product-details/:id"
            element={<ProductDetails />}
          />

          <Route
            path="/:cityName?/:userType?/category/:categoryId"
            element={<GlobalProductFilter />}
          />
          <Route
            path="/:cityName?/:userType?/category/:categoryId"
            element={<GlobalProductFilter />}
          />
          <Route
            path="/:cityName?/:userType?/my-cart/:paymentResponse?"
            element={<MyCart />}
          />
          <Route path="/:cityName?/:userType?/about-us" element={<AboutUs />} />
          <Route path="/:cityName?/:userType?/terms" element={<Terms />} />
          <Route path="/:cityName?/:userType?/policy" element={<Policy />} />
        </Route>

        <Route path="/admin" element={<Layout />}>
          <Route
            path="dashboard"
            element={
              isAdmin && isDaksh ? <Dashboard /> : <Navigate to="/signin" />
            }
          />
          <Route
            path="product-management"
            element={
              isAdmin && isDaksh ? <ProductManagement /> : <Navigate to="/" />
            }
          />
          <Route
            path="request-for-quotation"
            element={
              isAdmin && isDaksh ? <RequestForQuotation /> : <Navigate to="/" />
            }
          />
          <Route
            path="request-details/:id"
            element={
              isAdmin && isDaksh ? <RequestDetails /> : <Navigate to="/" />
            }
          />
          <Route
            path="product-add-form"
            element={
              isAdmin && isDaksh ? <ProductAddForm /> : <Navigate to="/" />
            }
          />
          <Route
            path="product-add-form/:id"
            element={
              isAdmin && isDaksh ? <ProductAddForm /> : <Navigate to="/" />
            }
          />
          <Route
            path="order-management"
            element={
              isAdmin && isDaksh ? <OrderManagement /> : <Navigate to="/" />
            }
          />

          <Route
            path="order-details/:id"
            element={
              isAdmin && isDaksh ? <OrderDetails /> : <Navigate to="/" />
            }
          />
          <Route
            path="request-for-quotation-old"
            element={
              isAdmin && isDaksh ? (
                <RequestForQuotationOld />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="request-details-old/:id"
            element={
              isAdmin && isDaksh ? <RequestDetailsOld /> : <Navigate to="/" />
            }
          />
          <Route
            path="seller-onboarding/:urlSellerId?"
            element={
              isAdmin && isDaksh ? <SellerOnboarding /> : <Navigate to="/" />
            }
          />
          <Route
            path="seller-management"
            element={
              isAdmin && isDaksh ? <SellerManagement /> : <Navigate to="/" />
            }
          />

          <Route
            path="seller-product-management/:urlSellerId"
            element={
              isAdmin && isDaksh ? <ProductManagement /> : <Navigate to="/" />
            }
          />
        </Route>
        <Route path="/configuration" element={<Layout />}>
          <Route path="announcements" element={<Announcements />} />
          <Route path="banner" element={<Banner />} />
        </Route>
        <Route
          path="/admin/daily-order-management"
          element={
            isAdmin && isDaksh ? <DailyOrderManagement /> : <Navigate to="/" />
          }
        />
        <Route element={<Layout />}>
          <Route path="/coupons" element={<Coupons />} />
        </Route>

        <Route path="/seller-admin" element={<Layout />}>
          <Route
            path="product-management"
            element={
              isAdmin && !isDaksh ? (
                <ProductManagement />
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="product-add-form/:id"
            element={
              isAdmin && !isDaksh ? (
                <ProductAddForm />
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="order-management"
            element={
              isAdmin && !isDaksh ? (
                <OrderManagement />
              ) : (
                <Navigate to="/signin" />
              )
            }
          />

          <Route
            path="order-details/:id"
            element={
              isAdmin && !isDaksh ? <OrderDetails /> : <Navigate to="/signin" />
            }
          />
        </Route>

        <Route path="/not-found" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
