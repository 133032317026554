import { useEffect, useState } from "react";
import { GetHomeBannerImages } from "../../../services/Home/homeService";
import "./Home-Banner.css";
import { useSelector } from "react-redux";
import { Carousel } from "react-bootstrap";
import Radium from "radium";

const HomeBanner = () => {

    const sellerId = useSelector((state) => state.Home.sellerId);
    const [bannerImages, setBannerImages] = useState([]);

    useEffect(() => {
                const getbannerImages = async () => {
            try {
                const response = await GetHomeBannerImages(sellerId);
                setBannerImages(response)
                // console.log(response);
            } catch (error) {
                console.error("Error fetching order data:", error);
            }
        };

        if (sellerId !== 0) {
            getbannerImages();
        }
    }, [sellerId])
    const handleShopNowClick = (redirectPath) => {
        window.open(redirectPath, '_self'); // Open the URL in a new tab
    };

    // const carouselElement = document.getElementById('carouselExampleCaptions');

    // // Set the interval and enable cycling
    // carouselElement.setAttribute('data-interval', '3000');
    // carouselElement.setAttribute('data-ride', 'carousel');

    // // Initialize the carousel manually
    // const carousel = new bootstrap.Carousel(carouselElement, {
    //   interval: 3000,
    //   cycle: true
    // });


    return (
        <>
            <section className='banner pt-2'>
                <div className='container-fluid p-0
                '>

                   <div className="row">
                   <div class="col-md-12">
                        {/* <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="true">
                            <div class="carousel-indicators">
                                {
                                    bannerImages.length !== 0 && bannerImages.map((item, index) => (
                                        <button
                                            key={index} // Adding a key prop is a good practice when using map with React
                                            type="button"
                                            data-bs-target="#carouselExampleCaptions"
                                            data-bs-slide-to={index}
                                            className={index === 0 ? "active" : ""}
                                            aria-current={index === 0 ? "true" : "false"}
                                            aria-label={`Slide ${index +1}`}
                                        ></button>
                                    ))}
                            </div>
                            <div class="carousel-inner">
                               
                            </div>
                        </div> */}
                        <Carousel
                            slide={true}
                            >
                            
                            {bannerImages.length !== 0 && bannerImages.map((item, index) => (
                                    <Carousel.Item interval={30000}>
                                <div className="carousel-item-image"   style={{
                                                backgroundImage: `url(${item.url})`,
                                                '@media (max-width: 520px)': {
                                                    backgroundImage: `url(${item.mobileImageUrl})`,
                                                    backgroundSize:'100%',
                                                    backgroundRepeat:'no-repeat'
                                                }
                                            }}>
                                        {/* <ExampleCarouselImage text="First slide" /> */}
                                        <Carousel.Caption>
                                            <div className="carousel-caption text-dark">
                                            {item.bannerText.split(";").map((text, textIndex) => (
                                                    <div className="col-12 px-0" key={textIndex}>
                                                        {text}
                                                    </div>
                                                ))}
                                                <div className="col-12 col-md-12 col-lg-12 col-sm-12 px-0 home-banner-shop-now">
                                                    <button className="btn rounded-0 shop-now"   onClick={() => handleShopNowClick(item.redirectPath)}>Shop Now</button>
                                                </div>
                                            </div>
                                        </Carousel.Caption>
                                        
                                        </div>
                                    </Carousel.Item>
                                
                            ))}

                        </Carousel>
                    </div>
                   </div>

                </div>
            </section>
        </>
    )
}
export default Radium(HomeBanner);
