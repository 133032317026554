import axios from "axios";
import ApiConstants from "../Constants/ApiConstants";
import { apiGet, apiGetWithoutAuth } from "./apiCall";
const api = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

export const getVendor = async (currentUrl) => {
  try {
    const response = await api.get(`/${ApiConstants.GET_VENDOR_DETAIL}?url=${currentUrl}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getProductsBySeller = async (sellerId) => {
  try {
    const response = await api.get(`/${ApiConstants.GET_PRODUCTS_BY_SELLER}?sellerId=${sellerId}`)
    return response.result;
  } catch (error) {
    console.log("Something went wrong");
  }
}

// export const getProductDetailsBySeller = async (sellerId, productId) => {
//   try {
//     const response = await api.get(`/product/product-details-by-id?sellerId=${sellerId}&productId=${productId}`)
//     return response.data.result
//   } catch (error) {
//     console.log("Something went wrong");
//   }
// }



// export const GetProductCategoryByIdBySeller = async (categoryId, pageNum, pageSize, popularityIds, materialsIds, shapeIds, sizeIds, priceIds, searchText, sellerId) => {
//   const response = await apiGet(ApiConstants.GET_CATEGORY_DETAILS_BY_SELLER + "?sellerId=" + sellerId + "&categoryId=" + categoryId + "&pageNum=" + pageNum + "&pageSize=" + pageSize + "&popularityIds=" + popularityIds + "&materialIds=" + materialsIds + "&shapeIds=" + shapeIds + "&sizeIds=" + sizeIds + "&priceIds=" + priceIds + "&searchItem=" + searchText);
//   return response;
// };

// export const GlobalSearchBySeller = async (searchText, sellerId) => {
//   const response = await apiGet(ApiConstants.GLOBAL_SEARCH_BY_SELLER + "?sellerId=" + sellerId + "&searchItem=" + searchText);
//   return response;
// }

// export const GlobalSearchSuggestionsForSeller = async (sellerId, searchText) => {
//   const response = await apiGet(ApiConstants.GLOBAL_SEARCH_SUGGESTIONS_FOR_SELLER + "?sellerId=" + sellerId + "&searchText=" + searchText);
//   return response;
// };


// export const getProductDetailsAtCheckout = async (sellerId, ids) => {
//   try {
//     if (ids.includes(",")) {
//       const tempIds = ids.replaceAll(",", "%2C");
//       //console.log(ApiConstants.GET_CART_PRODUCT_DETAIL+tempIds+"/1/100")
//       const response = await api.get(
//         `/${ApiConstants.GET_PRODUCTS_DETAIL_FOR_CHECKOUT}?sellerId=${sellerId}&productIds=${tempIds}`
//       );
//       return response.data.result;
//     } else {
//       const response = await api.get(
//         `/${ApiConstants.GET_PRODUCTS_DETAIL_FOR_CHECKOUT}?sellerId=${sellerId}&productIds=${ids}`
//       );
//       return response.data.result;
//     }
//   } catch (error) {
//     console.log("Something went wrong");
//   }
// };

