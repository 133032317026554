import React, { useState } from "react";
import DragAndDropFileUpload from "../Common/DragAndDropFileUpload";
import { convertToBase64, validateFileSize } from "../../schema/ImageBase64";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { GetState } from "../../services/dropdownService";
import { GetSellerDetails, PostSeller, UpdateSellerDetails } from "../../services/sellerOnboardingService";
import { convertStructureOfEditSeller } from "../../helpers/convertBodyStructure";
import { sellerOnboardingValidation } from "../../schema/sellerOnboardingValidation";

export default function SellerOnboarding() {
    const { urlSellerId } = useParams();
    const pageAction = urlSellerId ? "edit" : "add";
    const [indianStates, setIndianStates] = useState([]);
    const [inputData, setInputData] = useState({
        id: 0,
        name: "",
        phone: "",
        email: "",
        username: "",
        password: "",
        address: "",
        state: 0,
        city: 0,
        pincode: "",
        logo: {
            documentName: "",
            base64: "",
        },
        footerLogo: {
            documentName: "",
            base64: "",
        },
        motto: "",
        webUrl: "",
        favicon: {
            documentName: "",
            base64: "",
        },
        title: "",
        copyright: "",
        isActive: true,
        isDeleted: false,
        minimumSellingAmount: 0,
        marginPercentage: 0,
        headerBackgroundColor: "",
        footerBackgroundColor: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputData({ ...inputData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let validationResponse = [];
        let validationFlag = 0;
        validationResponse = sellerOnboardingValidation(inputData);
        console.log(validationResponse);
        validationResponse?.map((data, i) => {
            if (data?.isError) {
                document.getElementById(data.Field)?.classList.add("border-danger");
                validationFlag = 1;
            } else {
                document.getElementById(data.Field)?.classList.remove("border-danger");
            }
        });
        if (validationFlag === 0) {
            if (pageAction === "add") {
                try {
                    const response = await PostSeller(inputData);
                    console.log(response);
                    if (response.status === 200) {
                        toast.error(response?.message);
                    } else if (response.status === 201) {
                        toast.success(response?.message);
                    } else if (response.status === 200) {
                        toast.error(response?.message);
                    } else if (response.status === 200) {
                        toast.error(response?.message);
                    }
                } catch (error) {
                    console.error(error);
                }
            } else if (pageAction === "edit") {
                updateSeller();
            }
        } else toast.error("All fields are required.");
    };

    const updateSeller = async () => {
        try {
            const data = convertStructureOfEditSeller(inputData);
            console.log(data);
            const response = await UpdateSellerDetails(urlSellerId, data);
            console.log(response);
            if (response.statusCode === 204) {
                // handleShow(e);
                toast.success("Updated");
            } else if (response.statusCode === 400) {
                response?.result?.map((data) => {
                    toast.success(data.errorMessage);
                    console.log("data.ErrorMessage", data.errorMessage);
                });
            } else {
                console.log("errors", response.message);
                toast.error("Something Went Wrong");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleDecimalInputChange = (value) => {
        // Regular expression to allow decimal numbers
        const decimalRegex = /^\d*\.?\d*$/;

        if (decimalRegex.test(value)) {
            setInputData({ ...inputData, marginPercentage: value });
        }
    };

    const handleImageUpload = async (e, fieldName) => {
        if (validateFileSize(e) == true) {
            const base64 = await convertToBase64(e, `${inputData.name}_${fieldName}`);
            let mediaObject;
            urlSellerId === undefined
                ? (mediaObject = {
                      documentName: base64.documentName,
                      base64: base64.base64,
                  })
                : (mediaObject = {
                      documentName: base64.documentName,
                      base64: base64.base64,
                      url: null,
                  });
            console.log(mediaObject);
            setInputData({ ...inputData, [fieldName]: base64 });
        } else console.error("File exceeds 1 MB");
    };

    const getSellerDetails = async () => {
        try {
            const response = await GetSellerDetails(urlSellerId);
            setInputData(response);
            console.log("this", response);
        } catch (error) {
            console.log(error);
        }
    };

    const getStates = async () => {
        try {
            const response = await GetState();
            setIndianStates(response);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getStates();
        pageAction === "edit" && getSellerDetails();
    }, []);

    const handleImageDelete = (data) => {
        const { name } = data.target;
        setInputData((prevInputData) => {
            return {
                ...prevInputData,
                [name]: "",
            };
        });
    };
    return (
        <div className="page-body px-0 py-lg-2 py-1 mt-0 mt-lg-3 ms-3">
            <ToastContainer autoClose={3000} />
            <div className="container-fluid">
                <div className="row g-0 mt-5">
                    <div className="col-lg-8 offset-lg-2 d-flex justify-content-center align-items-center">
                        <div className="card shadow-sm w-100 p-4 p-md-5 py-md-3 ">
                            <Link to="/admin/seller-management">
                                <i className="fa fa-arrow-left me-1" /> Back
                            </Link>
                            <form
                                className="row g-3 mt-5"
                                onSubmit={handleSubmit}>
                                <div className="col-12 text-center mb-3">
                                    <img
                                        src="../Assets/img/daksh-logo-dark.png"
                                        alt="Daksh"
                                        className="pb-2"
                                    />
                                    <h1>Seller Onboarding</h1>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-12">
                                        <label className="form-label">Seller name</label>
                                        <input
                                            id="Title"
                                            type="text"
                                            className="form-control mb-2 "
                                            name="title"
                                            value={inputData?.title}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <label className="form-label">Full name</label>
                                    <input
                                        id="Name"
                                        type="text"
                                        className="form-control mb-2 "
                                        name="name"
                                        value={inputData?.name}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-md-4 col-6">
                                    <label className="form-label">Mobile</label>
                                    <input
                                        type="number"
                                        id="Phone"
                                        className="form-control mb-2"
                                        placeholder={8974586987}
                                        name="phone"
                                        value={inputData?.phone}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-md-4 col-6">
                                    <label className="form-label">Email address</label>
                                    <input
                                        type="email"
                                        id="Email"
                                        className="form-control mb-2"
                                        placeholder="name@example.com"
                                        name="email"
                                        value={inputData?.email}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-6">
                                        <div>
                                            <div>
                                                <label className="form-label">Username</label>
                                                <input
                                                    id="Username"
                                                    type="text"
                                                    className="form-control mb-2"
                                                    value={inputData?.username}
                                                    onChange={(e) => setInputData({ ...inputData, username: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-6">
                                        <div></div>
                                        <div>
                                            <label className="form-label">Password</label>
                                            <input
                                                type="password"
                                                className="form-control mb-2"
                                                id=""
                                                value={inputData?.password}
                                                onChange={(e) => setInputData({ ...inputData, password: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="mb-2">
                                        <label className="form-label">Address</label>
                                        <textarea
                                            name="address"
                                            id="Address"
                                            className="form-control mb-2"
                                            rows={1}
                                            placeholder="Address"
                                            value={inputData?.address}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-6">
                                    <label className="form-label">State</label>
                                    <select
                                        id="State"
                                        onChange={(e) => setInputData({ ...inputData, state: e.target.value, city: e.target.value })}
                                        value={inputData?.state}
                                        className="form-select form-control">
                                        <option value="0">...</option>
                                        {indianStates.map((d) => (
                                            <option
                                                value={d.id}
                                                key={d.id}>
                                                {d.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-3 col-6">
                                    <label className="form-label">Pincode</label>
                                    <input
                                        type="number"
                                        id="Pincode"
                                        className="form-control mb-2"
                                        placeholder={100145}
                                        value={inputData?.pincode}
                                        onChange={(e) => setInputData({ ...inputData, pincode: e.target.value })}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-6">
                                        <div>
                                            <label className="form-label">Margin Percentage</label>
                                            <input
                                                type="text" // Change type to "text"
                                                className="form-control mb-2"
                                                id="Margin-Percentage"
                                                placeholder="Enter decimal value"
                                                value={inputData?.marginPercentage}
                                                onChange={(e) => handleDecimalInputChange(e.target.value)}
                                            />
                                            <div>
                                                <label className="form-label">Minimum Selling Amount</label>
                                                <input
                                                    type="number"
                                                    className="form-control mb-2"
                                                    id="MinimumSellingAmount"
                                                    value={inputData?.minimumSellingAmount}
                                                    onChange={(e) => setInputData({ ...inputData, minimumSellingAmount: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-6">
                                        <div>
                                            <label className="form-label">Header Backgound Color</label>
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                id=""
                                                value={inputData?.headerBackgroundColor}
                                                onChange={(e) => setInputData({ ...inputData, headerBackgroundColor: e.target.value })}
                                            />
                                        </div>
                                        <div>
                                            <label className="form-label">Footer Backgound Color</label>
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                id=""
                                                value={inputData?.footerBackgroundColor}
                                                onChange={(e) => setInputData({ ...inputData, footerBackgroundColor: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <label className="form-label">Domain</label>
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        id="Domain"
                                        value={inputData?.webUrl}
                                        onChange={(e) => setInputData({ ...inputData, webUrl: e.target.value })}
                                    />
                                </div>
                                <div className="row mt-4">
                                    <div className="col-lg-4">
                                        <h2>Logo (For header)</h2>
                                        <DragAndDropFileUpload
                                            url={inputData?.logo}
                                            onChange={(e) => handleImageUpload(e, "logo")}
                                            handleDelete={(e) => handleImageDelete(e)}
                                            name="logo"
                                            show={"hideThumbnail"}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <h2>Logo (For footer)</h2>
                                        <DragAndDropFileUpload
                                            url={inputData?.footerLogo}
                                            name="footerLogo"
                                            handleDelete={(e) => handleImageDelete(e)}
                                            onChange={(e) => handleImageUpload(e, "footerLogo")}
                                            show={"hideThumbnail"}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <h2>Favicon</h2>
                                        <DragAndDropFileUpload
                                            url={inputData?.favicon}
                                            name="favicon"
                                            handleDelete={(e) => handleImageDelete(e)}
                                            onChange={(e) => handleImageUpload(e, "favicon")}
                                            show={"hideThumbnail"}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 text-center mt-4 ">
                                    <button
                                        type="submit"
                                        className="btn btn-block btn-primary lift text-uppercase mx-4">
                                        Submit
                                    </button>
                                    <Link
                                        to={"/admin/seller-management"}
                                        className="btn btn-block btn-light-secondary lift text-uppercase"
                                        style={{ padding: "10px 20px" }}>
                                        Cancel
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
