import "./SuccessModal.css";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function SuccessModal({ url, show }) {
    return (
        <Modal show={show}>
            <div
                className="modal fade show"
                id="successmsg"
                tabIndex={-1}
                aria-modal="true"
                role="dialog"
                style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-scrollable  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body custom_scroll p-lg-5 text-center">
                            <button
                                type="button"
                                className="btn-close position-absolute top-0 end-0 mt-3 me-3"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                            <i className="fa-regular fa-circle-check order-success-btn" />
                            <h4 style={{ fontSize: "40px" }}>Success</h4>
                            <p className="text-muted small mb-4">Your order has been confirmed.</p>
                            <Link
                                to={url}
                                className="btn btn-success"
                                aria-label="Close">
                                Continue
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
