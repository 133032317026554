import React from "react";
import { useSelector } from "react-redux";
import { exportToExcel } from "../../services/orderService";
import { convertBase64ToExcel } from "../../schema/FileBase64";
const OrderReport = () => {
  const {
    orderNumber,
    customerName,
    stateId,
    sellerId,
    startDate,
    endDate,
    statusId,
  } = useSelector((state) => state.report);


  const getReports = async () => {
    try {
      const response = await exportToExcel(
        orderNumber,
        customerName,
        stateId,
        sellerId,
        startDate,
        endDate,
        statusId
      );

      const file = convertBase64ToExcel(response.result.fileContents, response.result.fileDownloadName);
      const url = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {}
  };

  return (
    <>
      <div className="col-12">
        <div className="row-title px-2">
          <div>
            <h6 className="fw-bold">Orders</h6>
          </div>
          <div className="d-flex morphing scale-left">
            <span className="pt-1 mt-1">Monthly Overview</span>
            <div className="pe-lg-0 ms-4 border-left ps-3">
              <div className="d-sm-block ms-auto">
                <div className="btn-group" role="group">
                  <label
                    className="btn btn-outline-warning btn-sm text-dark rounded"
                    for="btnradio1"
                    onClick={getReports}
                   
                  >
                    <i  className="fa fa-download"></i>{" "}
                    Reports
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderReport;
