import axios from "axios";
import ApiConstant from "../../Constants/ApiConstants";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const handleLogin = async (credential) => {
    try {
        const response = await axios.post(BASE_URL + ApiConstant.LOGIN, credential, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.data.statusCode === 200) {
            return { success: true, obj: response.data.result };
        } else {
            return { success: false };
        }
    } catch (error) {
        console.log(error.response.data.message);
    }
};
