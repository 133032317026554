function MinimunItemsModal({ minimum }) {
    return (
        <>
            <div
                className="modal fade"
                id="minimum-cart"
                tabIndex="-1"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body custom_scroll p-lg-5 text-center">
                            <button
                                type="button"
                                className="btn-close position-absolute top-0 end-0 mt-3 me-3"
                                data-bs-dismiss="modal"
                                aria-label="Close"></button>
                            {/* <i className="fa fa-times-circle"></i> */}
                            <i className="bi bi-exclamation-circle-fill"></i>
                            <h4></h4>
                            <p className="text-muted small mb-4">Minimum {minimum} items are required</p>
                            <a
                                href="#"
                                className="btn btn-success"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                Continue
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MinimunItemsModal;
