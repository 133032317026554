import axios from "axios";
import { apiGetWithoutAuth } from "./apiCall";
import ApiConstants from "../Constants/ApiConstants";
import { error } from "jquery";
// const api = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });


export const ProductsFilters = async (searchText) => {
  try {
    let response;
    if (searchText.length > 0) {
      response = await apiGetWithoutAuth(`${ApiConstants.GET_PRODUCT_SEARCH_COMMON_URL}${ApiConstants.GET_PRODUCT_FILTERS}?searchText=${searchText}`);
    }
    else {
      response = await apiGetWithoutAuth(`${ApiConstants.GET_PRODUCT_SEARCH_COMMON_URL}${ApiConstants.GET_PRODUCT_FILTERS}`);
    }
    return response.result;
  } catch (error) {
    throw error;
  }
};

export const GlobalProductCatalog = async (cityId, userTypeId, categories, rating, materials, shapes, sizeRange, priceRange, searchItem, pageNum, pageSize) => {
  try {
    const response = await apiGetWithoutAuth(`${ApiConstants.GET_PRODUCT_SEARCH_COMMON_URL}${cityId}/${userTypeId}/${ApiConstants.GET_PRODUCTS_CATALOG}?Categories=${categories}&Rating=${rating}&Material=${materials}&Shapes=${shapes}&SizeRange=${sizeRange}&PriceRange=${priceRange}&searchItem=${searchItem}&PageNum=${pageNum}&PageSize=${pageSize}`);
    return response.result;
  }
  catch (error) {
    throw error();
  }
}


export const GlobalProductCatalogBySeller = async (sellerId, categories, rating, materials, shapes, sizeRange, priceRange, searchItem, pageNum, pageSize) => {
  try {
    const response = await apiGetWithoutAuth(`${ApiConstants.GET_PRODUCT_SEARCH_COMMON_URL}${sellerId}/${ApiConstants.GET_PRODUCTS_CATALOG}?Categories=${categories}&Rating=${rating}&Material=${materials}&Shapes=${shapes}&SizeRange=${sizeRange}&PriceRange=${priceRange}&searchItem=${searchItem}&PageNum=${pageNum}&PageSize=${pageSize}`)
    return response.result;
  }
  catch (error) {
    throw error();
  }
}

export const ProductSearchSuggestionsForSeller = async (sellerId, searchText, categoryId) => {
  const response = await apiGetWithoutAuth(`${ApiConstants.GET_PRODUCT_SEARCH_COMMON_URL}${sellerId}/${ApiConstants.GET_PRODUCT_SEARCH_SUGGESTIONS}?categoryId=${categoryId}&searchText=${searchText}`);
  return response.result;
};