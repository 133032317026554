import { Switch } from 'antd';
import React from 'react'

const CouponSwitch = ({ status }) => {
    const isChecked = status === 'active';
  return (
    <div>
    <td>
   
      <Switch
        className={`mx-1 ${status && status.toLowerCase() === "active" ? "bg-success" : "bg-danger"}`}
        loading={false}
        checked={status && status.toLowerCase() === "active"}
        size="small"
        // Set checked and unchecked children to change colors
        checkedChildren={<span style={{ color: "green" }}></span>}
        unCheckedChildren={<span style={{ color: "red" }}></span>}
      />
    
  </td>
  
    </div>
  )
}

export default CouponSwitch
