import "./Different.css";

export const Different = () => {
  return (
    <>
      <section className="What-makes-us-diff p-4 img-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12 text-center mb-4">
              <div class="h5">
                <b class="what-makes-us">What Makes Us Different?</b>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-4 text-center">
              <div className="mb-3">
                <img src="/Assets/img/Different/organic_11824706 1.png" />
              </div>
              <div>
                <span class="">
                  <p class="we-share-your m-0">We share your </p>
                  <p class="we-share-your m-0">love for greenery</p>
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-4 text-center">
              <div className="mb-3">
                <img src="/Assets/img/Different/watering-can_9205955 (1) 1.png" />
              </div>
              <div>
                <span class="our-pots-empower-container1">
                  <p class="our-pots-empower m-0">Our pots empower </p>
                  <p class="our-pots-empower m-0">your greenery to thrive</p>
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6  mb-4 text-center">
              <div className="mb-3">
                <img src="/Assets/img/Different/delivered_1337232 1.png" />
              </div>
              <div>
                <span class="get-recommendations-from-container1">
                  <p class="get-recommendations m-0">Get recommendations </p>
                  <p class="get-recommendations m-0">
                    from our gardening experts
                  </p>
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-4 text-center">
              <div className="mb-3">
                <img src="/Assets/img/Different/fast-delivery_10746486 1.png" />
              </div>
              <div>
                <span class="swift-delivery-as-container1">
                  <p class="swift-delivery-as m-0">Swift delivery as </p>
                  <p class="swift-delivery-as m-0">per your convenience</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
