/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { GetProfile } from "../../../services/adminService";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

function AdminTopMenu({ hideSidebar, showSidebar, windowWidth }) {
    const [isButtonHover, setIsButtonHover] = useState(false);
    const navigate = useNavigate();
    const sellerId = useSelector((state) => state.auth.sellerId);

    const [profile, setProfile] = useState({});

    const logout = () => {
        localStorage.clear();
        navigate("/signin");
    };

    const profileHandler = async () => {
        try {
            const response = await GetProfile(sellerId);
            setProfile(response);
        } catch (error) {
            console.warn(error);
        }
    };

    useEffect(() => {
        profileHandler();
    }, []);

    return (
        <>
            <div className="container-fluid">
                <nav className="navbar">
                    {/* start: toggle btn */}
                    <div className="d-flex">
                        <button
                            type="button"
                            className="btn btn-link d-none d-xl-block sidebar-mini-btn p-0 text-primary"
                            onClick={showSidebar}
                            onMouseEnter={() => {
                                console.log("Click");
                                hideSidebar && setIsButtonHover(true);
                            }}
                            onMouseLeave={() => {
                                setIsButtonHover(false);
                            }}>
                            <span className={`hamburger-icon ${isButtonHover ? "active" : ""}`}>
                                <span className="line" />
                                <span className="line" />
                                <span className="line" />
                            </span>
                        </button>
                        <button
                            type="button"
                            className="btn btn-link d-block d-xl-none menu-toggle p-0 text-primary"
                            onClick={showSidebar}
                            onMouseEnter={() => {
                                hideSidebar && setIsButtonHover(true);
                            }}
                            onMouseLeave={() => {
                                setIsButtonHover(false);
                            }}
                            onTouchStart={() => {
                                hideSidebar && setIsButtonHover(true);
                            }}
                            onTouchEnd={() => {
                                setIsButtonHover(false);
                            }}>
                            <span className={`hamburger-icon ${isButtonHover ? "active" : ""}`}>
                                <span className="line" />
                                <span className="line" />
                                <span className="line" />
                            </span>
                        </button>
                    </div>

                    {/* start: links */}
                    <ul className="header-right justify-content-end d-flex align-items-center mb-0">
                        {/* start: notification link */}
                        {/* start: profile */}
                        <li>
                            {/*  user profile */}
                            <div className="dropdown morphing scale-left user-profile ms-lg-3 ms-2">
                                <a
                                    className="nav-link dropdown-toggle rounded after-none p-2 d-inline-flex justify-content-center align-items-center"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown">
                                    <img
                                        className="avatar rounded shadow"
                                        src="../Assets/img/avatar/avatar2.jpg"
                                        alt="user_avatar"
                                    />
                                    <div className="flex-fill ms-2 mb-1">
                                        <p>
                                            <span className="fw-bold;">{profile?.username} </span>
                                        </p>
                                        <small>Admin</small>
                                    </div>
                                </a>
                                <div className="dropdown-menu border-0 rounded-4 shadow p-0">
                                    <div className="card border-0">
                                        <div className="card-body border-bottom d-flex">
                                            <img
                                                className="avatar rounded"
                                                src="../Assets/img/avatar/avatar2.jpg"
                                                alt=""
                                            />
                                            <div className="flex-fill ms-3">
                                                <h6 className="card-title mb-0">{profile?.username} </h6>
                                                <span className="text-muted sm-txt">{profile?.email}</span>
                                            </div>
                                        </div>
                                        <Link
                                            to="/signin"
                                            onClick={logout}
                                            className="btn btn-dark text-light text-uppercase rounded-0">
                                            Sign out
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    );
}

export default AdminTopMenu;
