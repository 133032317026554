import { combineReducers } from "redux";
import vendorSlice from "./features/vendorSlice";
import authSlice from "./features/authSlice";
import reportSlice from "./features/reportSlice";
import paymentSlice from "./features/paymentSlice";
import HomeSlice from "./features/homeSlice"
import cartSlice from "./features/cartSlice";

const rootReducer = combineReducers({
  vendor: vendorSlice,
  auth: authSlice,
  report: reportSlice,
  payment_request: paymentSlice,
  Home:HomeSlice,
  Cart:cartSlice
});

export default rootReducer;
