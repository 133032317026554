import React, { useState } from "react";
import { InlineShareButtons } from 'sharethis-reactjs';
 
const ShareButtons = (props) => {
    const [shareButtonsConfig] = useState({
 
      color: "white",
      enabled: true,
      font_size: 6,
      language: "en",
      networks: [           // which networks to include (see SHARING NETWORKS)
      "whatsapp",
      "facebook",
      "twitter",
      "telegram",
      "email",
      ],
      padding: 0,
      // radius: 8,
      show_total: false,
      size: 30,
      url: props.url,
      description: "custom text",
      title: "custom title",
      message: "custom email text",
      subject: "custom email subject",
      username: "custom twitter handle",
    });
 
    return (
      <div>
        <InlineShareButtons config={shareButtonsConfig} />        
      </div>
    );
  };
 
  export default ShareButtons;