import Modal from "react-bootstrap/Modal";
import { useState } from "react";

function TrackingInfoModal({ show, handleClose, handleContinue }) {
    const [trackingInfo, setTrackingInfo] = useState({
        trackingNumber: "",
        courierWebsite: "",
        trackingImage: {
            url: "",
            base64: "",
            documentName: "",
        },
    });
    const handleInputChange = (field, event) => {
        setTrackingInfo({
            ...trackingInfo,
            [field]: event.target.value,
        });
    };

    const handleImageInputChange = (field, event) => {
        // Assuming the image input accepts only one file
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setTrackingInfo({
                    ...trackingInfo,
                    trackingImage: {
                        ...trackingInfo.trackingImage,
                        [field]: reader.result,
                    },
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = () => {
        handleClose();
        handleContinue(trackingInfo);
    };

    return (
        <>
            <Modal
                centered={true}
                animation={true}
                show={show}
            >
                <div
                    className="modal fade show"
                    tabIndex="-1"
                    aria-modal="true"
                    role="dialog"
                    style={{ display: "block" }}>
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body p-3 py-5">
                                <center>
                                    <h1>Enter Package Details</h1>
                                </center>
                                <button
                                    type="button"
                                    className="btn-close position-absolute top-0 end-0 mt-3 me-3"
                                    onClick={handleClose}
                                    data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                                <div className="form-group">
                                    {/* <label htmlFor="trackingNumber">Tracking Number</label> */}
                                    <br />
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="trackingNumber"
                                        placeholder="Enter Tracking Number"
                                        value={trackingInfo.trackingNumber}
                                        // onChange={(e) => handleInputChange("trackingNumber")}
                                        onChange={(e) => handleInputChange("trackingNumber", e)}
                                    />
                                </div>
                                <div className="form-group">
                                    {/* <label htmlFor="courierWebsite">Courier Website</label> */}
                                    <br />
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="courierWebsite"
                                        placeholder="Enter Courier Website"
                                        value={trackingInfo.courierWebsite}
                                        onChange={(e) => handleInputChange("courierWebsite", e)}
                                    />
                                </div>
                                <div className="form-group">
                                    {/* <label htmlFor="courierWebsite">Courier Website</label> */}
                                    <br />
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="shippingCharges"
                                        placeholder="Enter Shipping Amount"
                                        value={trackingInfo.shippingCharges}
                                        onChange={(e) => handleInputChange("shippingCharges", e)}
                                    />
                                </div> 
                                <div className="form-group">
                                    <label htmlFor="trackingImage">Tracking Image</label>
                                    <br />
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="trackingImage"
                                        accept="image/*"
                                        onChange={(e) => handleImageInputChange("base64", e)}
                                    />
                                </div>
                                <center>
                                    <div className="modal-buttons mt-3">
                                        <button
                                            className="btn btn-success me-2"
                                            type="button"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={handleSubmit}
                                        >
                                            Yes, Confirm
                                        </button>
                                        <button
                                            className="btn btn-outline-success"
                                            type="button"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={handleClose}>
                                            Cancel
                                        </button>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default TrackingInfoModal;
