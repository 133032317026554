import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL;

export const apiGet = async (endPoints) => {
    const response = await axios.get(API_URL + endPoints, {
        headers: {
            Authorization: localStorage.getItem("token"),
        },
    });
    return response.data.result;
};

export const apiGetWithoutAuth = async (endPoints) => {
    const response = await axios.get(API_URL + endPoints, { 
    });
    return response.data;
};


export const apiGetWithParams = async (endpoints, params) => {
    const response = await axios.get(API_URL + endpoints, params);
    return response.data.result;
};

export const apiPost = async (endpoints, body, config) => {
    
    const response = await axios.post(API_URL + endpoints, body, config);
    return response.data;
};

export const apiPut = async (endpoints, body) => {
    const response = await axios.put(API_URL + endpoints, body, {
        headers: {
            Authorization: localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    return response.data;
};

export const apiStatusCodeGet = async (endPoints) => {
    const response = await axios.get(API_URL + endPoints, {
        headers: {
            Authorization: localStorage.getItem("token"),
        },
    });
    return response;
};

export const apiDelete = async (endPoints, config) => {
    const response = await axios.delete(API_URL + endPoints, {
        headers: {
            Authorization: localStorage.getItem("token"),
        },
    });
    return response;
};

export const apiPatch = async (endPoints, config, data) => {
    const response = await axios.patch(API_URL + endPoints, data, config);
    return response;
}


export const apiPostWithAuthorization = async (endpoints, body) => {
    const response = await axios.post(API_URL + endpoints, body, {
        headers: {
            Authorization: localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    return response.data;
};
