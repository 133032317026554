export function convertBodyStructure(body) {
  let j = 0;
  body.productImages = [];

  for (let i = 0; i < 10; i++) {
    const imageURLKey = `imageURL${i + 1}`;

    // Check if imageURLKey exists in body and is not an empty object
    if (body[imageURLKey] && Object.keys(body[imageURLKey]).length !== 0) {
      body.productImages[j] = {
        id: 0,
        documentName: body[imageURLKey]?.documentName,
        base64: body[imageURLKey]?.base64,
        mediaType: "image",
        url: "",
      };
      if (body.thumbnail === i + 1) {
        body.productImages[j] = {
          ...body.productImages[j],
          isThumbnail: true,
        };
      }
      j++;
    }

    delete body[imageURLKey];
  }
  if (body.videoURL !== "")
    body.productImages[j] = {
      id: 0,
      documentName: "",
      base64: "",
      mediaType: "video",
      url: body.videoURL,
    };

  return { body: body, isValid: true };
}

export function convertStructureOfGetProducts(productDetails) {
  let imageId = new Map();

  for (let i = 0; i < productDetails?.productMedia?.length; i++) {
    const imageURLKey = `imageURL${i + 1}`;
    if (productDetails?.productMedia[i]?.mediaType !== "video") {
      productDetails.basicDetail[imageURLKey] =
        productDetails?.productMedia[i]?.url;
      imageId.set(imageURLKey, productDetails?.productMedia[i]?.id);
    }

    if (productDetails?.productMedia[i]?.isThumbnail) {
      productDetails.basicDetail.thumbnail =
        productDetails?.productMedia[i]?.url;
    }
    if (productDetails?.productMedia[i]?.mediaType === "video") {
      productDetails.basicDetail.videoURL =
        productDetails?.productMedia[i]?.url;
      imageId.set("videoUrl", productDetails?.productMedia[i]?.id);
    }
  }
  delete productDetails?.productMedia;
  let mapAsJsonString = JSON.stringify(Array.from(imageId.entries()));
  localStorage.setItem("imageId", mapAsJsonString);
  return { productDetails };
}

export function convertStructureOfUpdateProducts(id, body) {
  let retrievedMapAsJsonString = localStorage.getItem("imageId");
  let imageId = new Map(JSON.parse(retrievedMapAsJsonString));

  let j = 0;
  body.productImages = [];
  for (let i = 0; i < 10; i++) {
    const imageURLKey = `imageURL${i + 1}`;
    if (
      typeof body[imageURLKey] === "object" &&
      Object.keys(body[imageURLKey]).length !== 0
    ) {
      body.productImages[j] = {
        id: imageId.get(imageURLKey) || 0,
        documentName: "",
        base64: body[imageURLKey]?.base64,
        mediaType: "image",
        url: "",
      };
      j++;
    }

    delete body[imageURLKey];
  }
  if (imageId.has("videoUrl")) {
    body.productImages[j] = {
      id: imageId.get("videoUrl") || 0,
      documentName: "",
      base64: "",
      mediaType: "video",
      url: body.videoURL,
    };
  }
  // for (let i = 0; i < body?.productImages?.length; i++) {
  //   if (body?.thumbnail === i + 1) {
  //     body.productImages[i] = {
  //       ...body.productImages[i],
  //       isThumbnail: true,
  //     };
  //   }
  //   else {
  //     body.productImages[i] = {
  //       ...body.productImages[i],
  //       isThumbnail: false,
  //     };
  //   }
  // }
  console.log("body-->", body);
  return body;
}

export function convertStructureOfEditProducts(basicDetails, productMedia, priceInformation) {
  let body = {
    ...basicDetails,
    productImages: [...productMedia],
    priceInformation: [...priceInformation]
  }
  let imageURLKey;
  let i;
  delete body.id;
  delete body.code;
  body.totalBuying = parseFloat(body.totalBuying)
  let productMediaLength = productMedia.length;
  for (i = 0; i < productMediaLength; i++) {
    if (productMedia[i].isThumbnail === true) {
      body.thumbnail = productMedia[i].id
    }
  }
  for (i = 0; i < 10; i++) {
    imageURLKey = `imageURL${i + 1}`;
    imageURLKey in body && delete body[imageURLKey];
  }
  let priceInformationLength = priceInformation.length
  for (i = 0; i < priceInformationLength; i++) {
    "priceInfoId" in priceInformation[i] && delete priceInformation[i].priceInfoId;
    "productId" in priceInformation[i] && delete priceInformation[i].productId;
    "cityName" in priceInformation[i] && delete priceInformation[i].cityName;
  }
  console.log(body)
  return body;
}

export function convertStructureOfEditSeller(data) {
  const unUsedFields = ["isDeleted", "marginPercentage", "minimumSellingAmount", "password", "username"];
  const imageFields = ["logo", "footerLogo", "favicon"];
  let newData;
  newData = Object.keys(data).filter(key => !unUsedFields.includes(key)).reduce((obj, key) => {
    obj[key] = data[key];
    return obj;
  }, {});
  imageFields.forEach(field => {
    if (typeof data[field] === "string") {
      newData[field] = {
        url: data[field],
      };
    }
  });
  newData.pincode = parseInt(newData?.pincode);
  return newData;
}

export const convertStructureOfConfigurationBanner = (originalArray) => {
  return originalArray
    .filter(item => item.base64 !== undefined || item.url !== undefined)
    .filter(item => item.base64 !== "" || item.url !== "")
    .filter(item => item.base64 !== undefined || item.url !== "")
    .map((item) => {
      const { id, base64, url, mobileBase64, mobileImageUrl, redirectPath, bannerText } = item;

      return {
        id: id,
        base64: base64 && base64.base64,
        url: url,
        mobileBase64: mobileBase64.base64 || mobileImageUrl,
        redirectUrl: redirectPath,
        bannerText: bannerText
      };
    });
};

export const bannerValidation = (originalArray) => {
  debugger
  const ValidationErrors = [];
  if (originalArray.length === 0) {
    ValidationErrors.push({ ErrorField: `Banner Image`, ErrorMessage: `Banner Image should not be empty` });
    console.log("No Image")
  } {
    originalArray.forEach((element, index) => {
      if (element.base64 === "" & element.url === "") {
        ValidationErrors.push({ ErrorField: `Banner Image ${index}`, ErrorMessage: `Banner Image ${index} should not be empty` });
      }
      else if (element.base64 !== "" & element.url === "") {
        if (element.bannerText === "") {
          ValidationErrors.push({ ErrorField: `Banner Text ${index}`, ErrorMessage: `Banner Text ${index} should not be empty` });
        }
        if (element.redirectUrl === "") {
          ValidationErrors.push({ ErrorField: `Redirect Url ${index}`, ErrorMessage: `Redirect Url ${index} should not be empty` });
        }
      }
    })
  }
  return ValidationErrors;
}
