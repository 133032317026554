import { createSlice } from "@reduxjs/toolkit";
// global state
const initialState = {
    orderNumber : "",
    customerName : "",
    stateId : 0,
    sellerId : localStorage.getItem("sellerId"),
    startDate : "",
    endDate : "",
    statusId : 0
};

const reportSlice = createSlice({
  name: "report",
  initialState,

  // modifying global state
  reducers: {
    setReportFilters: (state, action) => {
        state.sellerId = action?.payload?.sellerId;
        state.orderNumber = action?.payload?.orderNumber;
        state.customerName = action?.payload?.customerName;
        state.stateId = action?.payload?.stateId;
        state.startDate = action?.payload?.startDate;
        state.endDate = action?.payload?.endDate;
        state.statusId = action?.payload?.statusId;

    },
  },
});

export const { setReportFilters } = reportSlice.actions;
export default reportSlice.reducer;
