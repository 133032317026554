
export default class ApiConstants {
  static HOME_CONST = "HOME"
  static PRODUCT_VIEW_CONST = "PRODUCT_VIEW"
  static RECENTLY_VIEWED_PRODUCTS = "RECENTLY_VIEWED_PRODUCTS"
  static BASE_URL = "http://dakshindia.dakshindiaproducts.com/api/";
  static GET_CITY = "dropdown/city";
  static GET_SHAPE = "dropdown/shape";
  static GET_MATERIAL = "dropdown/material";
  static GET_POPULARITY = "dropdown/popularity";
  static GET_VENDOR = "dropdown/vendor";
  static GET_ADMIN_PROFILE = "admin/profile";
  static GET_PRODUCT_LIST = "admin/product/";
  static GET_DASHBOARD_SUMMARY = "admin/dashboard-summary";
  static GET_DASHBOARD_QUICK_LINK = "admin/dashboard-quick-link/";
  static GET_QUOTE_LIST = "admin/request-for-quote/";
  static GET_QUOTE_LIST_OLD = "admin/quote/";
  static LOGIN = "login";
  static USER_TYPE = "dropdown/user-type";
  static CATEGORY = "dropdown/category";
  static SIZE = "dropdown/size-filter";
  static QUOTE_DETAILS = "admin/request-for-quote/";
  static QUOTE_DETAILS_OLD = "admin/quote/";
  static ADD_PRODUCT = "admin/product";
  static CUSTOMER_HOME_PAGE = "customer/";
  static CUSTOMER_QUOTE = "customer/quote";
  static GET_PRODUCT_DETAILS = "admin/product/";
  static GET_PRODUCT_INFORMATION = "customer/";
  static GET_CATEGORY_INFORMATION = "customer/";
  static UPDATE_PRODUCT_DETAILS = "admin/product/";
  static CHECK_PRODUCT_CODE_EXISTS = "admin/CheckProductCodeExists?Code=";
  static GET_PRODUCT_CODE = "admin/product-code";
  static GLOBAL_SEARCH = "customer/";
  static GET_CITY_USERTYPE = "customer/city-user-type/";
  static GET_PRICE_LIST = "dropdown/price-filter";
  static GET_CART_PRODUCT_DETAIL = "customer/";
  static GLOBAL_SEARCH_SUGGESTIONS = "customer/search-suggestion/";
  static GET_VENDOR_DETAIL = "home/page-detail-by-url";
  static GET_PRODUCTS_BY_SELLER = "product/home-page";
  static DOMAIN_URL1 = "localhost";
  static DOMAIN_URL2 = "app.dakshindiaproducts.com";
  static DOMAIN_URL3 = "dakshindiaproducts.com";
  static DOMAIN_URL4 = "wwww.dakshindiaproducts.com";
  static DOMAIN_URL5 = process.env.REACT_APP_DOMAIN;
  static GET_PRODUCT_DETAILS_BY_SELLER = "product/product-details-by-id"
  static GLOBAL_SEARCH_SUGGESTIONS_FOR_SELLER = "product/product-search-suggestion";
  static GET_CATEGORY_DETAILS_BY_SELLER = "product/product-by-category";
  static GLOBAL_SEARCH_BY_SELLER = "product/product-categories-global-search";
  static GET_PRODUCTS_DETAIL_FOR_CHECKOUT = "product/product-details-for-cart";
  static CONVERT_REQUEST_QUOTE_TO_ORDER = "convert-request-quote-to-order"
  static CANCEL_REQUEST_FOR_QUOTE = "Cancel-request-for-quote"
  static OTP_VALIDATE_MOBILE_FOR_CHECKOUT = "customer/verify-and-get-customer-detail";
  static GET_STATE = "dropdown/state";
  static GET_STATUS = "dropdown/status";
  static CUSTOMER_REQUEST_FOR_QUOTE_OR_ORDER = "customer/customer-request-for-quote-or-order";
  static VERIFY_OTP = "customer/verify-otp"
  static GENERATE_PAYMENT_ORDER = "payment/generate-order";
  static GET_PAYMENT_STATUS = "payment/get-return-page-details/"
  static GET_PRODUCT_DETAILS_BY_SELLER_ID = "seller/product";
  static GET_PRODUCT_LIST_FOR_SELLER = "seller/product-list-by-seller/";
  static DELETE_CUSTOMER_ADDRESS = "customer/delete-address"
  static GET_SELLER_LIST = "admin/seller";
  static DELETE_SELLER = "admin/seller"
  static POST_SELLER = "admin/seller"
  static QUICK_LINK_BASE_URL = process.env.REACT_APP_QUICK_LINK_BASE_URL;
  static UPADTE_PRODUCT_STATUS_FOR_SELLER = "product/update-single-product-status-for-seller"
  static UPADTE_PRODUCT_MOQ_FOR_SELLER = "product/update-single-product-MOQ-for-seller"
  static SAVE_TRACKING_INFO = "order/orders/";
  static GET_SELLER_DETAILS = "admin/seller/";
  static UPDATE_SELLER_DETAILS = "admin/seller/";
  static GET_DISCOUNT = "customer/get-discounts";
  static DAILY_ORDER_MANAGEMENT = "admin/get-product-management";
  static GET_PRICE_AFTER_DISCOUNT = "customer/get-price-after-applying-discount"
  static GET_STAFF_MEMBERS = "dropdown/staff-members"
  static POST_ORDER_RESPONSIBILITY = "order/change-order-responsibility"
  static GET_ORDER_RESPONSIBILE_DATA = "order/current-order-responsible"
  static POST_ANNOUNCEMENT = "configuration/announcement"
  static GET_ANNOUNCEMENTS = "configuration/"
  static GET_BANNER_IMAGES = "configuration/"
  static POST_BANNER_IMAGES = "configuration/"
  static UPDATE_BANNER_IMAGES = "configuration/"
  static DELETE_BANNER_IMAGES = "configuration/"
  static GET_HEADER_ANNOUNCEMENTS = "home/get-announcement";
  static GET_PAGE_DETAIL_BY_URL = "home/page-detail-by-url";
  static GET_CATEGORIES = "home/get-categories";
  static GET_HOME_PAGE_BANNER = "/get-banner-images";
  static HOME = "home/";
  static GET_FEATURED_PRODUCTS = "get-featured-products?sellerId=";

  static GET_PRODUCT_SEARCH_COMMON_URL = "productfilter/"
  static GET_PRODUCT_SEARCH_SUGGESTIONS = "product-search-suggestions"
  static GET_PRODUCT_SEARCH_SUGGESTIONS_FOR_SELLER = "product-search-suggestions"
  static GET_PRODUCT_FILTERS = "get-product-search-filters"
  static GET_PRODUCTS_CATALOG = "global-product-catalog"

  static GET_PRODUCT_DETAILS_BY_ID = "product-details-by-id/";
  static GET_MOST_BROUGHT_PRODUCTS = "get-most-brought-products";

  static GET_PRODUCT_DETAILS_FOR_CART="/product-details-for-cart?productIds="
}