import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getOrdersLIst, getSellerList } from "../../services/orderService";
import { getStatus, getState } from "../../services/dropdownService";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../OrderManagement/OrderManagement.css";
import Loader from "../Common/Loader";
import { DatePicker, Pagination } from "antd";
import { useDispatch } from "react-redux";
import { setReportFilters } from "../../redux/features/reportSlice";
import Select from "react-select";
import { useSelector } from "react-redux";

const OrderTable = ({ sellerId }) => {
    const [startDate, setFromDate] = useState("");
    const [endDate, setToDate] = useState("");
    const [orderNumber, setOrderNumber] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [sortColumn, setSortColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [totalPages, setTotalPages] = useState();
    const [totalRecords, setTotalRecords] = useState();
    const [pageSize, setPageSize] = useState(50);
    const [pageNum, setPageNum] = useState(1);
    const [statusId, setStatusId] = useState(0);
    const [isLoader, setIsLoader] = useState(false);
    const [placeholder] = useState(["Start Date", "End Date"]);
    const [orderListData, setOrderListData] = useState();
    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();
    const [state, setState] = useState([]);
    const [selectedState, setSelectedState] = useState(0);
    const [statusData, setStatusData] = useState();
    const [dropDownSeller, setDropDownSeller] = useState([]);
    const [vendorId, setVendorId] = useState(0);
    const isDaksh = useSelector((state) => state.auth.isDaksh);

    const handleSort = (field) => {
        setIsLoader(true);
       
        if (field === sortColumn) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(field);
            setSortOrder("asc");
        }
        setIsLoader(false);
    };

    useEffect(() => {
        stateHandler();
        statusHandler();
        sellerHandler();
    }, []);

    const orderListHandler = async (isClear) => {
        setIsLoader(true);
        try {
            let response;
            if (isClear) {
                response = await getOrdersLIst(pageNum, pageSize, sellerId, "", "", 0, 0, "", "", "", "");
            } else {
                response = await getOrdersLIst(pageNum, pageSize, vendorId === 0 ? sellerId : vendorId, orderNumber, customerName, selectedState.value || 0, statusId, startDate, endDate, sortColumn, sortOrder);
            }
            setOrderListData(response?.orderListFilter);
            let page = response?.pageResponse?.totalRecord;
            setTotalRecords(page);
            setTotalPages(Math.floor(page / pageSize) + 1);
        } catch (error) {
            console.warn("Something went wrong", error.message);
        }
        setIsLoader(false);
    };

    const handleClear = () => {
        setPageNum(1);
        setOrderNumber("");
        setCustomerName("");
        setStatusId(0);
        setVendorId(0);
        setSelectedState(0);
        setSortOrder("");
        setSortColumn("");

        dispatch(
            setReportFilters({
                orderNumber: "",
                customerName: "",
                stateId: 0,
                sellerId: sellerId,
                startDate: "",
                endDate: "",
                statusId: 0,
            })
        );

        orderListHandler(true);
    };

    const handleFilterData = () => {
        dispatch(
            setReportFilters({
                orderNumber: orderNumber || "",
                customerName: customerName || "",
                stateId: selectedState.value || 0,
                sellerId: vendorId === 0 ? 0 : vendorId,
                startDate: startDate || "",
                endDate: endDate || "",
                statusId: statusId || 0,
            })
        );

        orderListHandler(false);
    };

    const stateHandler = async () => {
        try {
            const response = await getState();
            setState(response);
        } catch (error) {
            console.warn("Something went wrong", error.message);
        }
    };

    const statusHandler = async () => {
        try {
            const response = await getStatus();
            setStatusData(response);
        } catch (error) {
            console.warn("Something went wrong", error.message);
        }
    };

    const sellerHandler = async () => {
        try {
            const response = await getSellerList(0);
            setDropDownSeller(response);
        } catch (error) {
            console.warn("Something went wrong", error.message);
        }
    };

    const handleDate = async (v) => {
        if (v != null) {
            let from = await formatDate(v[0].$d);
            let to = await formatDate(v[1].$d);
            setFromDate(from);
            setToDate(to);
        } else {
            setFromDate("");
            setToDate("");
        }
    };

    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }

    const handleNextPage = () => {
        setPageNum(pageNum + 1);
    };
    const handlePreviousPage = () => {
        setPageNum(pageNum - 1);
    };

    const pageHandler = (number, size) => {
        setPageNum(number || pageNum);
        setPageSize(size || pageSize);
    };

    useEffect(() => {
        orderListHandler(false);
    }, [pageNum, sortColumn, sortOrder, pageSize]);

    const renderState = () => {
        return state?.map((data) => ({
            label: data?.name,
            value: data?.id,
        }));
    };

    const getStatusColor = (status) => {
        switch (status) {
            case "Delivered":
                return "green";
            case "Cancel":
                return "red";
            default:
                return "orange";
        }
    };

    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-2 col-md-4 col-sm-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Order No."
                                            value={orderNumber}
                                            onChange={(e) => setOrderNumber(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-3">
                                        <input
                                            type="search"
                                            className="form-control"
                                            placeholder="Customer Name"
                                            value={customerName}
                                            onChange={(e) => setCustomerName(e.target.value)}
                                        />
                                    </div>
                                   
                                    <div className="col-lg-2 col-md-4 col-sm-3">
                                        <Select
                                            placeholder="State"
                                            className="form-contro"
                                            options={renderState()}
                                            value={selectedState}
                                            isSearchable
                                            isClearable
                                            onChange={(e) => setSelectedState(e)}
                                        />
                                    </div>
                                    {isDaksh && (
                                        <div className="col-lg-2 col-md-4 col-sm-3">
                                            <select
                                                name=""
                                                id=""
                                                placeholder="Seller"
                                                value={vendorId}
                                                className="form-select form-control"
                                                onChange={(e) => setVendorId(e.target.value)}>
                                                {dropDownSeller?.map((data) => (
                                                    <option
                                                        key={data?.key}
                                                        value={data?.key}>
                                                        {data?.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}

                                    <div className="col-lg-3 col-md-3 col-sm-2">
                                        <RangePicker
                                            placeholder={placeholder}
                                            dateFormat={"YYYY - MM - DD"}
                                            style={{ height: "38px" }}
                                            onChange={(v) => {
                                                handleDate(v);
                                            }}
                                        />
                                    </div>
                                 
                                    <div className="col-lg-2 col-md-4 col-sm-3">
                                        <select
                                            name=""
                                            id=""
                                            value={statusId}
                                            className="form-select form-control"
                                            onChange={(e) => setStatusId(e.target.value)}>
                                            <option value={0}>-- Status --</option>
                                            {statusData?.map((data) => (
                                                <option
                                                    key={data?.key}
                                                    value={data?.key}>
                                                    {data?.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-3">
                                        <div>
                                            <span
                                                onClick={handleFilterData}
                                                className="btn btn-sm btn-light-success me-2">
                                                Filter
                                            </span>
                                            <span
                                                className="btn btn-light-secondary btn-sm "
                                                onClick={handleClear}>
                                                Clear
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 mt-1">
                {isLoader && <Loader />}
                <div className="card">
                    <div className="card-body">
                        <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                            <div className="row">
                                <div className="col-sm-12 px-0 table-responsive">
                                    <div className="col-sm-12 px-0 table-responsive">
                                        <table
                                            className="myDataTable table table-hover mb-0 nowrap dataTable no-footer dtr-inline"
                                            id="DataTables_Table_2">
                                            <thead>
                                                <tr>
                                                    <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="DataTables_Table_2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        style={{ width: "1rem" }}
                                                        aria-label="Code: activate to sort column ascending"
                                                        onClick={() => handleSort("ordernumber")}>
                                                        Order No.
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="DataTables_Table_2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        aria-label="Product Name: ac"
                                                        onClick={() => handleSort("customername")}>
                                                        Customer Name
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="DataTables_Table_2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        style={{ width: "2rem" }}
                                                        aria-label="Product Name: activate to sort column ascending"
                                                        onClick={() => handleSort("state")}>
                                                        State
                                                    </th>
                                                    {isDaksh && (
                                                        <th
                                                            className="sorting"
                                                            tabIndex={0}
                                                            aria-controls="DataTables_Table_2"
                                                            rowSpan={1}
                                                            colSpan={1}
                                                            style={{ width: "2rem" }}
                                                            aria-label="Product Name: activate to sort column ascending"
                                                            onClick={() => handleSort("sellername")}>
                                                            Seller
                                                        </th>
                                                    )}

                                                    <th
                                                        tabIndex={0}
                                                        aria-controls="DataTables_Table_2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        style={{ width: "2rem" }}>
                                                        Url
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="DataTables_Table_2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        style={{ width: "2rem" }}
                                                        aria-label="Type: activate to sort column ascending"
                                                        
                                                    >
                                                        Cost Price
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="DataTables_Table_2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        style={{ width: "2rem" }}
                                                        aria-label="Type: activate to sort column ascending"
                                                        // onClick={() => handleSort("sellermargin")}
                                                    >
                                                        Discount Amount
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="DataTables_Table_2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        style={{ width: "2rem" }}
                                                        aria-label="Type: activate to sort column ascending"
                                                        // onClick={() => handleSort("sellermargin")}
                                                    >
                                                        Shipping Charges
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="DataTables_Table_2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        style={{ width: "2rem" }}
                                                        aria-label="Type: activate to sort column ascending"
                                                        // onClick={() => handleSort("sellermargin")}
                                                    >
                                                        {isDaksh ? "Seller Margin" : "Your Margin"}
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="DataTables_Table_2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        style={{ width: "2rem" }}
                                                        aria-label="Type: activate to sort column ascending"
                                                        // onClick={() => handleSort("orderPrice")}
                                                    >
                                                        T. Order Price
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="DataTables_Table_2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        style={{ width: "2rem" }}
                                                        aria-label="Type: activate to sort column ascending"
                                                        onClick={() => handleSort("orderDate")}>
                                                        Order Date
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="DataTables_Table_2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        style={{ width: "2rem" }}
                                                        aria-label="Type: activate to sort column ascending"
                                                        onClick={() => handleSort("status")}>
                                                        Status
                                                    </th>
                                                    <th
                                                        className="sorting"
                                                        tabIndex={0}
                                                        aria-controls="DataTables_Table_2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        style={{ width: "2rem" }}
                                                        aria-label="Type: activate to sort column ascending"
                                                        onClick={() => handleSort("paymentMode")}>
                                                        Payment Mode
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orderListData?.length > 0 ? (
                                                    orderListData?.map((data) => (
                                                        <tr
                                                            key={data.orderId}
                                                            className="odd">
                                                            <td>
                                                                <Link to={isDaksh ? `/admin/order-details/${data.orderId}` : `/seller-admin/order-details/${data.orderId}`}>{data.orderNumber}</Link>
                                                            </td>
                                                            <td>{data?.customerName}</td>
                                                            <td>{data?.state}</td>
                                                            {isDaksh && <td>{data?.sellerName}</td>}
                                                            <td>{data?.sellerURL}</td>
                                                            <td>{data?.totalPrice}</td>
                                                            <td>{data?.discount == 0 ? '': data?.discount}</td>
                                                            <td>{data?.shippingAmount == null ? '' : data?.shippingAmount}</td>
                                                            <td className="text-center">{data?.margin !== 0 ? data?.margin : ''}</td>
                                                            <td>{data?.totalOrderPrice}</td>
                                                            <td>{data?.orderDate}</td>
                                                            <td style={{ color: getStatusColor(data?.status) }}>{data?.status}</td>
                                                            
                                                            <td>{data?.paymentMode}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <p className="text-center">No Record Found</p>
                                                )}
                                            </tbody>
                                        </table>
                                        <nav
                                            aria-label="Page navigation"
                                            className="page-nav">
                                            <Pagination
                                                defaultCurrent={pageNum}
                                                total={totalRecords}
                                                hideOnSinglePage
                                                defaultPageSize={pageSize}
                                                responsive
                                                onChange={(page, pageSize) => pageHandler(page, pageSize)}
                                                showQuickJumper
                                            />
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderTable;
