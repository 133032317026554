import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router";
import AdminLeftMenu from "./AdminLeftMenu/AdminLeftMenu";
import AdminTopMenu from "./AdminTopMenu/AdminTopMenu";

const Layout = () => {
    const [sidebar, setSidebar] = useState(false);
    const [hideSidebar, setHideSidebar] = useState(false);
    const windowWidth = useRef(window.innerWidth);
    const windowHeight = useRef(window.innerHeight);
    const element = document.getElementById("root");
    element.classList.add("layout-2");
    element.setAttribute("data-luno", "theme-blue");
    const sidebarEffect = () => {
        //setSidebar(true)
        document.getElementById("sidebar").style.width = "100%";
    };

    const showSidebar = () => {
        setHideSidebar(!hideSidebar);
    };

    useEffect(() => {
        windowWidth.current = window.innerWidth;
        windowHeight.current = window.innerHeight;
    }, [window.innerWidth, window.innerHeight]);
    return (
        <>
            <div style={{ display: "flex" ,width:"100%"}}>
                <AdminLeftMenu
                    id="sidebar"
                    hideSidebar={hideSidebar}
                    windowWidth={windowWidth}
                    showSidebar={showSidebar}
                />
                <div className="wrapper">
                    <header className="page-header px-xl-2 px-sm-2 px-0 py-0  ms-3">
                        <AdminTopMenu
                            hideSidebar={hideSidebar}
                            onClcik={sidebarEffect}
                            showSidebar={showSidebar}
                            windowWidth={windowWidth}
                        />
                    </header>
                    <Outlet />
                </div>
            </div>
            <script src="assets/js/plugins.js"></script>
            <script src="assets/js/theme.js"></script>
        </>
    );
};

export default Layout;
