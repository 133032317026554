import React, { useEffect, useState } from "react";
import {
  DeleteAddress,
  GetValidateOtp,
  GetVerifyOtp,
} from "../../../services/checkoutService";
import { ThreeDots } from "react-loader-spinner";
import "./MyCart.css";
import { Link } from "react-router-dom";
import { checkoutFormValidation } from "../../../schema/CheckOutValidation";
import { GetState } from "../../../services/dropdownService";
import ReCAPTCHA from "react-google-recaptcha";
import {
  GetOrderDiscounts,
  GetPriceAfterDiscount,
} from "../../../services/paymentService";

function OtpValidation({
  toast,
  handlePlaceOrder,
  content,
  totalItemsCart,
  urlFlag,
  totalCartPrice,
  setDiscoutedPrice,
}) {
  // const [isNewUser, setIsNewUser] = useState(true);
  const [validateMobileNumberBox, setValidateMobileNumberBox] = useState(true);
  const [validateOtpBox, setValidateOtpBox] = useState(false);
  const [detailsFormBox3, setDetailsFormBox3] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [existingUserDetails, setExistingUserDetails] = useState({
    id: 0,
    name: "",
    email: "",
    mobileNumber: mobileNumber,
  });
  const [showNewAddress, setShowNewAddress] = useState(0);
  const [customerAddressId, setCustomerAddressId] = useState(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [addressObject, setAddressObject] = useState({
    id: 0,
    addressLine1: "",
    state: 0,
    pincode: "",
  });
  const [existingUserAddress, setExistingUserAddress] = useState([
    addressObject,
  ]);
  const [indianStates, setIndianStates] = useState([{}]);
  const [isActive, setIsActive] = useState(false);
  const [otpReferenceNo, setOtpReferenceNo] = useState("");
  const [customerOtp, setCustomerOtp] = useState({});
  const [customerOtpString, setCustomerOtpString] = useState("");
  const recaptchaRef = React.createRef();
  const paynow = "pay_now";
  const cod = "COD";
  const [selectedOption, setSelectedOption] = useState(paynow);
  const [discountDetails, setDiscountDetails] = useState([]);
  const [Price, setPrice] = useState();
  const newButtonTexts = Array(discountDetails.length).fill('Apply');
  const [buttonTexts, setButtonTexts] = useState(newButtonTexts);

  const maxAmountForCod = 5000;
  useEffect(() => {
    const initialButtonTexts = Array(discountDetails.length).fill('Apply');
    setButtonTexts(initialButtonTexts);
  }, [discountDetails.length,totalCartPrice]);

  const [tempdiscountId, setTempDiscountId] = useState(null);
  useEffect(() => {
    setPrice(totalCartPrice);
  }, [totalCartPrice]);

  const onValueChange = (e) => {   
    if (e.target.value === cod) {           
      setPrice(totalCartPrice);    
      setButtonTexts(Array(discountDetails.length).fill('Apply'));
      setTempDiscountId(null);
    }
       setSelectedOption(e.target.value);
  };

  const showValidateMobileNumberBox = () => {
    setValidateMobileNumberBox(!validateMobileNumberBox);
  };

  const showValidateOtpBox = () => {
    setValidateOtpBox(!validateOtpBox);
  };
  const showDetailsFormBox3 = () => {
    setDetailsFormBox3(!detailsFormBox3);
  };

  const handleRecaptchaChange = (value) => {
    if (value !== null) setIsActive(true);
    else setIsActive(false);
  };

  const validateOtp = async () => {
    setIsLoader(true);
    try {
      if (mobileNumber.length === 10) {
        const response = await GetValidateOtp(mobileNumber,content?.sellerId);
        if (!response?.isError) {
          if (response?.statusCode === 200) {
            toast.success(response?.message);
            showValidateMobileNumberBox();
            showDetailsFormBox3();
            setExistingUserDetails(response?.result?.customer);
            if (response?.result?.customerAddress.length > 0) {
              setExistingUserAddress(response?.result?.customerAddress);
              setCustomerAddressId(response?.result?.customerAddress[0]?.id);
            } else {
              setShowNewAddress(1);
            }
          } else if (response?.statusCode === 204) {
            setOtpReferenceNo(response?.result);
            setExistingUserDetails({
              ...existingUserDetails,
              mobileNumber: mobileNumber,
            });
            showValidateMobileNumberBox();
            showValidateOtpBox();
          }
        }
      } else {
        toast.error("Please enter a valid mobile number");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
    setIsLoader(false);
  };

  const handleOtp = async () => {
    setIsLoader(true);
    try {
      const response = await GetVerifyOtp(otpReferenceNo, customerOtpString);
      if (!response?.isError) {
        if (response?.statusCode === 204 || response?.result === 1) {
          showValidateOtpBox();
          showDetailsFormBox3();
          setShowNewAddress(1);
        } else {
          toast.error("Please enter the correct OTP.");
        }
      } else {
        toast.error("Please enter the correct OTP.");
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoader(false);
  };

  const GetStates = async () => {
    try {
      const response = await GetState();
      setIndianStates(response);
    } catch (error) {
      console.log(error);
    }
  };

  const addAddressField = () => {
    if (showNewAddress === 0) {
      existingUserAddress.push(addressObject);
      setShowNewAddress(1);
    }
  };

  const placeOrderHandler = (isRFQ) => {
    let validationResponse = [];
    let data = {
      sellerId: content?.sellerId,
      customer: existingUserDetails,
      customerAddressId: parseInt(customerAddressId),
      customerAddress: [...existingUserAddress],
      status: 1,
      createdBy: "0",
      isRFQ: Boolean(isRFQ),
      paymentMode: selectedOption === paynow ? 1 : 3,
      discountId:
        selectedOption === cod
          ? null
          : tempdiscountId !== null
          ? tempdiscountId
          : null,
    };

    let validationFlag = 0;
    validationResponse = checkoutFormValidation(
      data.customer,
      data.customerAddress,
      data.customerAddressId
    );
    if (data?.customerAddress?.length > 0) {
      validationResponse?.map((data, i) => {
        if (data?.isError) {
          document.getElementById(data.Field)?.classList.add("border-danger");
          validationFlag = 1;
        } else {
          document
            .getElementById(data.Field)
            ?.classList.remove("border-danger");
        }
      });
      validationFlag === 0
        ? handlePlaceOrder(data)
        : toast.error("Please fill all required boxes.");
    } else {
      toast.error("Please add atleast one address.");
    }
  };

  const handleOtpInput = (e, digitString) => {
    if (
      e.target.value.length >= e.target.getAttribute("maxlength") &&
      digitString !== "otpSix"
    ) {
      e.target.nextElementSibling.focus();
    }
    setCustomerOtp({ ...customerOtp, [digitString]: e.target.value });
  };

  const setAddress = (id, e, field) => {
    let i = 0;
    let n = existingUserAddress.length;
    let tempArray = [...existingUserAddress];
    switch (field) {
      case "address":
        for (i = 0; i < n; i++) {
          if (tempArray[i]?.id === id) {
            tempArray[i].addressLine1 = e.target.value;
          }
        }
        setExistingUserAddress(tempArray);
        break;
      case "state":
        for (i = 0; i < n; i++) {
          if (tempArray[i]?.id === id) {
            tempArray[i].state = e.target.value;
          }
        }
        setExistingUserAddress(tempArray);
        break;
      case "pincode":
        for (i = 0; i < n; i++) {
          if (tempArray[i]?.id === id) {
            tempArray[i].pincode = e.target.value;
          }
        }
        setExistingUserAddress(tempArray);
        break;
      default:
        return;
    }
  };

  const handleDeleteAddress = async (id) => {
    try {
      if (id > 0) {
        const response = await DeleteAddress(id, mobileNumber);
        if (response?.statusCode === 200) {
          console.log("Address Deleted");
        }
      }

      let tempArray = [...existingUserAddress];
      let i = 0;
      let n = tempArray?.length;
      for (i = 0; i < n; i++) {
        tempArray[i]?.id === id && tempArray?.splice(i, 1);
      }
      setExistingUserAddress(tempArray);
      setShowNewAddress(0);
    } catch (error) {
      console.log(error);
    }
  };

  const getPriceAfterDiscount = async (id, totalCartPrice) => {
    try {
      const response = await GetPriceAfterDiscount(id, totalCartPrice);
      return response.result;
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleDiscountPriceClick = async (id, index) => {
    const newPrice = await getPriceAfterDiscount(id, totalCartPrice);
    const newButtonText = Array(discountDetails.length).fill('Apply');
    newButtonText[index] = 'Applied';
    setButtonTexts(newButtonText);
    setTempDiscountId(id);
    setPrice(newPrice);
    setDiscoutedPrice((prev) => newPrice);
  };
  const getDiscount = async () => {
    try {
      const response = await GetOrderDiscounts();
      setDiscountDetails(response?.result);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  function handleCodClick(e) {
  
    setSelectedOption(e.target.value);
    setPrice(totalCartPrice);    
    setButtonTexts(Array(discountDetails.length).fill('Apply'));
  }

  useEffect(() => {
    getDiscount();
  }, []);

  useEffect(() => {
    setCustomerOtpString(
      `${customerOtp?.otpOne}${customerOtp?.otpTwo}${customerOtp?.otpThree}${customerOtp?.otpFour}${customerOtp?.otpFive}${customerOtp?.otpSix}`
    );
  }, [customerOtp]);

  useState(() => {
    GetStates();
  });

  useEffect(() => {
    // console.log(216, existingUserAddress);
  }, [existingUserAddress]);

  useEffect(() => {
    changePaymentOption();
  }, [Price]);

  function changePaymentOption() {
    // console.log(326)
    if(Price > 5000){
         setSelectedOption(paynow);
  }}

  return (
    <>
      <div className="col-lg-4">
        <div className="card form-card">
          {validateMobileNumberBox && (
            <div className="card-body validation-form">
              <p className="heading">Tell Us About Yourself</p>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Mobile
                  </label>
                  <input
                    type="number"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && validateOtp()}
                    className="form-control"
                    id=""
                    maxLength={10}
                    placeholder=""
                  />
                </div>
                <div className="mb-3">
                  {isLoader ? (
                    <ThreeDots
                      center
                      height="42"
                      width="42"
                      radius="9"
                      color="#ffbf00"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <button
                      onClick={validateOtp}
                      href="javacript:void(0)"
                      type="button"
                      className="btn btn-warning cart-button"
                    >
                      <span>Submit </span>
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}
          {validateOtpBox && (
            <div className="card-body otp-form">
              <p className="heading">
                Please enter the one time password <br /> to verify your account
              </p>
              <p className="fs-6">
                <span>A code has been sent to</span>{" "}
                <small>*******{mobileNumber.substring(7)}</small>{" "}
              </p>
              <div
                id="otp"
                className="inputs d-flex flex-row justify-content-center mt-2"
              >
                <input
                  className="m-2 text-center form-control rounded"
                  type="text"
                  maxLength={1}
                  value={customerOtp?.otpOne}
                  onInput={(e) => {
                    handleOtpInput(e, "otpOne");
                  }}
                />
                <input
                  className="m-2 text-center form-control rounded"
                  type="text"
                  value={customerOtp?.otpTwo}
                  maxLength={1}
                  onInput={(e) => {
                    handleOtpInput(e, "otpTwo");
                  }}
                />
                <input
                  className="m-2 text-center form-control rounded"
                  type="text"
                  value={customerOtp?.otpThree}
                  // id="first"
                  maxLength={1}
                  onInput={(e) => {
                    handleOtpInput(e, "otpThree");
                  }}
                />
                <input
                  className="m-2 text-center form-control rounded"
                  type="text"
                  value={customerOtp?.otpFour}
                  // id="first"
                  maxLength={1}
                  onInput={(e) => {
                    handleOtpInput(e, "otpFour");
                  }}
                />
                <input
                  className="m-2 text-center form-control rounded"
                  type="text"
                  value={customerOtp?.otpFive}
                  // id="first"
                  maxLength={1}
                  onInput={(e) => {
                    handleOtpInput(e, "otpFive");
                  }}
                />
                <input
                  className="m-2 text-center form-control rounded"
                  type="text"
                  value={customerOtp?.otpSix}
                  // id="first"
                  maxLength={1}
                  onInput={(e) => {
                    handleOtpInput(e, "otpSix");
                  }}
                />
              </div>
              <div className="mt-4">
                {isLoader ? (
                  <ThreeDots
                    center
                    height="42"
                    width="42"
                    radius="9"
                    color="#ffbf00"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  <button
                    onClick={handleOtp}
                    className="btn btn-warning px-4 validate "
                    id="validate-otp"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          )}
          {detailsFormBox3 && (
            <div className="card-body details-form_third">
              <p className="heading">Tell Us About Yourself</p>
              <form className="all_details_third">
                <div className="row">
                  <div className="col-12 mb-3">
                    <label htmlFor="" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Name"
                      value={existingUserDetails?.name}
                      onChange={(e) =>
                        setExistingUserDetails({
                          ...existingUserDetails,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-12 mb-3 verify-mobile">
                    <label htmlFor="" className="form-label">
                      Mobile
                    </label>
                    <i className="fa fa-check text-success fs-6" />
                    <input
                      id="Mobile"
                      type="number"
                      className="form-control"
                      value={existingUserDetails?.mobileNumber}
                      readOnly
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <label htmlFor="" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      id="Email"
                      className="form-control"
                      value={existingUserDetails?.email}
                      onChange={(e) =>
                        setExistingUserDetails({
                          ...existingUserDetails,
                          email: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-12">
                    <label className="form-label">
                      Address
                      <Link id="add_address_third" onClick={addAddressField}>
                        <i
                          className="fa fa-plus border rounded-circle p-1"
                          title="Add address"
                        />
                      </Link>
                    </label>
                    {existingUserAddress.length > 0 &&
                      existingUserAddress?.map((d, i) => (
                        <div className="form-check" key={d.id}>
                          <input
                            className="form-check-input"
                            type="radio"
                            value={d.id}
                            name="address"
                            id="CustomerAddressId"
                            checked={parseInt(customerAddressId) === d.id}
                            onChange={(e) => {
                              setCustomerAddressId(e.target.value);
                            }}
                          />
                          <div className="card address-card">
                            <div className="card-body">
                              <Link onClick={() => handleDeleteAddress(d?.id)}>
                                <i
                                  className="fa fa-close rounded-circle border text-danger"
                                  title="Delete Address"
                                />
                              </Link>
                              <div
                                className="row"
                                // style={{ pointerEvents: "none", opacity: "0.5" }}
                              >
                                <div className="col-12 mb-3">
                                  <label htmlFor="" className="form-label">
                                    Address
                                  </label>
                                  <textarea
                                    name=""
                                    id={`AddressLine1[${i}]`}
                                    cols={30}
                                    rows={1}
                                    className="form-control"
                                    value={d?.addressLine1}
                                    onChange={(e) =>
                                      setAddress(d?.id, e, "address")
                                    }
                                  />
                                </div>
                                <div className="mb-3 col-lg-6">
                                  <label htmlFor="" className="form-label">
                                    State
                                  </label>
                                  <select
                                    name=""
                                    onChange={(e) =>
                                      setAddress(d?.id, e, "state")
                                    }
                                    id={`State[${i}]`}
                                    value={d.state}
                                    className="form-select form-control"
                                  >
                                    <option value="">...</option>
                                    {indianStates.map((d) => (
                                      <option value={d.id} key={d.id}>
                                        {d.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-6 mb-3">
                                  <label htmlFor="" className="form-label">
                                    Pin code
                                  </label>
                                  <input
                                    type="number"
                                    id={`Pincode[${i}]`}
                                    className="form-control"
                                    value={d?.pincode}
                                    onChange={(e) =>
                                      setAddress(d?.id, e, "pincode")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    <p className="fs-6" />
                  </div>
                </div>
                <div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECATCHA_SITE_KEY} // Replace with your reCAPTCHA site key
                    // sitekey="6Lel4Z4UAAAAAOa8LO1Q9mqKRUiMYl_00o5mXJrR" // Replace with your reCAPTCHA site key
                    theme="light"
                    onChange={handleRecaptchaChange}
                  />
                </div>
              </form>
            </div>
          )}
        </div>
         {/* Update UrlFlag to false below condition if you wnat ot check paynow & cod option  otherwise RFQ will be shown */}
        {urlFlag && content?.sellerId && (
          <div
            className={`card ${
              !isActive ? "btn-default bg-success" : ""
            } mt-3 total-price-card`}
            onClick={() => placeOrderHandler(1)}
            style={
              !isActive
                ? { pointerEvents: "none", opacity: "0.5" }
                : { cursor: "pointer" }
            }
          >
            <div className="card-body">
              <p className="mb-0">
                <span className="items">{totalItemsCart} Quantity</span>
                <span className="request">
                  <span>
                    Request Quote{" "} 
                    <i className="fa-solid fa-chevron-right fs-0" />
                  </span>
                </span>
              </p>
              <p type="button" className="mb-0">
                <span className="items">₹ {Price?.toLocaleString()}</span>
              </p>
            </div>
          </div>
        )}
         {/* Update UrlFlag to true below condition if you wnat ot check paynow & cod option 
         otherwise paynow will be only checked from seller */}
        {!urlFlag && content?.sellerId && (
          <div>
            <div className="mb-3">
              {isActive ? (
                <>
                  <br></br>
                  <div className="container">
                    <div className="row">
                      <div className="radio col-sm-6">
                        <label>
                          <input
                            className="m-2"
                            type="radio"
                            name="payment-option"
                            value={paynow}
                            checked={selectedOption === paynow}
                            onChange={onValueChange}
                          />
                          Pay Now
                        </label>
                      </div>
                      {totalCartPrice <= maxAmountForCod && (
                        <div className="radio col-sm-6">
                          <label>
                            <input
                              className="m-2"
                              type="radio"
                              value={cod}
                              name="payment-option"
                              checked={selectedOption === cod}
                              onChange={onValueChange}                            
                            />
                            COD
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            {totalCartPrice >= 400 && selectedOption === paynow && isActive ? (
              <div className="card mb-3 p-3">
                <div className="d-flex justify-content-between">
                  <ul class="list-group">
                    {discountDetails &&
                      discountDetails.map((value, index) => {
                        return (
                          <li class="list-group-item">
                            <span className="">
                              {value.discountDescription}
                              <button
                                id={"btn_discount_" + index}
                                type="button"
                                className="btn  custom-button"
                                onClick={() =>
                                  handleDiscountPriceClick(value.id, index)
                                }
                              >
                               {buttonTexts[index]}
                              </button>
                            </span>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            ) : null}
            <div
              className={`card ${
                !isActive ? "btn-default" : ""
              } mt-3 total-price-card place-order`}
              style={
                !isActive
                  ? { pointerEvents: "none", opacity: "0.5" }
                  : { cursor: "pointer" }
              }
              onClick={() => placeOrderHandler(0)}
            >
              <div className="card-body">
                <p type="button" className="mb-0">
                  <span className="items">{totalItemsCart} Quantity</span>
                  <span className="request">
                    <span>
                      {selectedOption === paynow ? "Pay Now" : "COD"}
                      <i className="fa-solid fa-chevron-right fs-0" />
                    </span>
                  </span>
                </p>
                <p type="button" className="mb-0">
                  <span className="items">₹ {Price?.toLocaleString()}</span>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default OtpValidation;
