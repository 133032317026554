import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetAnnouncement, GetCategories, PageDetailsByUrl } from '../../services/Home/homeService'

const initialState = {
    HomeDetail: {},
    Anouncement: "",
    ProductCategories: [],
    sellerId: 0,
    isLoading: false,
    state: null
};

export const getHomeDetailApi = createAsyncThunk(
    "home/getHomeDetail",
    async (currentUrl) => {
        const res = await PageDetailsByUrl(currentUrl);
        const data = await res.data;
        return data.result;
    }
);

export const getAnouncementsApi = createAsyncThunk(
    "home/getAnouncements",
    async (sellerId) => {
        const res = await GetAnnouncement(sellerId);
        const data = await res.data;
        return data.result;
    }
);

export const getProductCategoriesApi = createAsyncThunk(
    "home/getProductCategorie",
    async () => {
        const res = await GetCategories();

        return res;
    }
);


const HomeSlice = createSlice({
    name: "Home",
    initialState,
    reducers: {
        setInitHomeDetail: (state) => {
            state.HomeDetail = {};
            state.ProductCategories = [];

        },
    },
    extraReducers: (builder) => {
        builder.addCase(getHomeDetailApi.pending, (state, action) => {
            state.isLoading = true;
        });

        builder.addCase(getHomeDetailApi.fulfilled, (state, action) => {
            state.isLoading = false;
            state.HomeDetail = action.payload;
            state.sellerId = action.payload.sellerId;
            // sellerId= state.HomeDetail.sellerId;
        });
        builder.addCase(getHomeDetailApi.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message
        });
        builder.addCase(getAnouncementsApi.pending, (state, action) => {
            state.isLoading = true;
        });

        builder.addCase(getAnouncementsApi.fulfilled, (state, action) => {
            state.isLoading = false;
            state.Anouncement = action.payload;

        });
        builder.addCase(getAnouncementsApi.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message
        });
        builder.addCase(getProductCategoriesApi.pending, (state, action) => {
            state.isLoading = true;
        });

        builder.addCase(getProductCategoriesApi.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ProductCategories = action.payload;

        });
        builder.addCase(getProductCategoriesApi.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message
        })
    },
});

const { reducer } = HomeSlice;
export const { setInitHomeDetail } = HomeSlice.actions;
export default reducer;