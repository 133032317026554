import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { thunk } from "redux-thunk";
// import  thunk  from "redux-thunk";

const store = configureStore({
  reducer: rootReducer,
  middleware:[thunk],
});

export default store;
