export   const convertBase64ToExcel = (base64String, filename) => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "application/octet-stream" });
    return new File([blob], filename, { type: "application/octet-stream" });
  };

export const convertBase64ToPdf = (base64String, fileName) => {
    // Decode the Base64 string
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
  
    // Convert byteArray to Blob with MIME type 'application/pdf'
    const blob = new Blob([byteArray], { type: 'application/pdf' });
  
    // Create a File from the Blob
    const pdfFile = new File([blob], fileName, { type: 'application/pdf' });
  
    return pdfFile;
  };
  