import ApiConstants from "../Constants/ApiConstants";
import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from "./apiCall";



export const getSellerOnboardingList = async (pageNum, pageSize) => {
  const response = await apiGet(ApiConstants.GET_SELLER_LIST + "?pageNum=" + pageNum + "&pageSize=" + pageSize);
  return response;
}


// export const deleteSeller = async (id) => {
//   try {
//     const response = await apiDelete(ApiConstants.DELETE_SELLER + "/" + id);
//     console.log(response);
//     return response;
//   } catch (error) {
//     throw error; 
//   }
// };

export const deleteSeller = async (id) => {
  const response = await apiDelete(ApiConstants.DELETE_SELLER + `/${id}`);
  return response.data;
};


export const fetchSellersWithFilter = async (pageNum, pageSize, filterCriteria) => {
  const params = new URLSearchParams();
  params.append('pageNum', pageNum);
  params.append('pageSize', pageSize);

  if (filterCriteria.name) {
    params.append('name', filterCriteria.name);
  }
  if (filterCriteria.phone) {
    params.append('phone', filterCriteria.phone);
  }
  if (filterCriteria.firmName) {
    params.append('firmName', filterCriteria.firmName);
  }
  if (filterCriteria.dateOfOnboarding) {
    params.append('dateOfOnboarding', filterCriteria.dateOfOnboarding);
  }
  if (filterCriteria.status) {
    params.append('status', filterCriteria.status);
  }

  const queryString = params.toString();
  const response = await apiGet(ApiConstants.GET_SELLER_LIST + `?${queryString}`);
  return response;
};

export const UpdateProductStatusForSeller = async (sellerId, productId, isActive) => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };
  const response = await apiPatch(ApiConstants.UPADTE_PRODUCT_STATUS_FOR_SELLER + "?sellerId=" + sellerId + "&productId=" + productId + "&isActive=" + isActive, config);
  return response.data;
}

export const UpdateProductMoqForSeller = async (sellerId, productId, moq) => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };
  const response = await apiPatch(ApiConstants.UPADTE_PRODUCT_MOQ_FOR_SELLER + "?sellerId=" + sellerId + "&productId=" + productId + "&MOQ=" + moq, config);
  return response.data;
}

export const GetSellerDetails = async (id) => {
  const response = await apiGet(ApiConstants.GET_SELLER_DETAILS + id)
  return response;
}

export const PostSeller = async (sellerData) => {
  try {
    const response = await apiPost(ApiConstants.POST_SELLER, sellerData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (error) {
    console.error("Error posting seller:", error);
    throw error;
  }
};

export const UpdateSellerDetails = async (id, body) => {
  const response = await apiPut(ApiConstants.UPDATE_SELLER_DETAILS + id, body);
  return response;
};