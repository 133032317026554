import axios from "axios";
import ApiConstant from "../../Constants/ApiConstants";
import { apiGet, apiGetWithoutAuth } from "../apiCall";
import { useSelector } from "react-redux";
const api = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });


export const PageDetailsByUrl = async (currentUrl) => {
  try {
    const response = await api.get(`/${ApiConstant.GET_PAGE_DETAIL_BY_URL}?url=${currentUrl}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const GetAnnouncement = async (sellerId) => {
  try {
    const response = await api.get(`/${ApiConstant.GET_HEADER_ANNOUNCEMENTS}?sellerId=${sellerId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const GetCategories = async () => {
  try {
    const response = await apiGet(ApiConstant.GET_CATEGORIES);
    return response;
  } catch (error) {
    throw error;
  }

};

export const GetHomeBannerImages = async (sellerId) => {
  try {
    const response = await apiGet(`${ApiConstant.HOME}${sellerId}${ApiConstant.GET_HOME_PAGE_BANNER}`);
    return response;
  } catch (error) {
    throw error;
  }
};

  export const GetFeaturedProducts = async (sellerId, cityId, userTypeId) => {
    try {
      const response = await apiGetWithoutAuth(`${ApiConstant.HOME}${cityId}/${userTypeId}/${ApiConstant.GET_FEATURED_PRODUCTS}${sellerId}`);
      return response.result;
    } catch (error) {
      throw error;
    }

  };

  export const ProductsSearchSuggestion = async (cityId, userTypeId,categoryId,searchText) => {
    try {
      const response = await apiGetWithoutAuth(`${ApiConstant.GET_PRODUCT_SEARCH_COMMON_URL}${cityId}/${userTypeId}/${ApiConstant.GET_PRODUCT_SEARCH_SUGGESTIONS}?categoryId=${categoryId}&searchText=${searchText}`);
      return response.result;
    } catch (error) {
      throw error;
    }

  };


  