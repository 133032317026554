export const toTitleCase = (str) =>
    str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
        .join(" ");


export const stringToArray = (str) => {
    const arr = str.split(",");
    let numberArray = [];
    for (var i = 0; i < arr.length; i++) {
        numberArray.push(parseInt(arr[i]));
    }
    return numberArray;
};

export const arrayHandler = (newArray, event, id) => {
    if (event === true) {
        newArray.push(id);
    } else if (event === false) {
        const index = newArray.findIndex((d) => d === id);
        newArray.splice(index, 1);
    }
    return newArray;
};