import React from "react";
import Footer from "../Common/Footer/Footer";
import Header from "../Common/Header/Header";
import { Link } from "react-router-dom";
export default function NotFound() {
  return (
    <>
      <header>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-2 col-md-3 col-sm-5 col-6 logo-col"
              style={{ border: "black" }}
            >
              <div className="logo">
                <Link>
                  <img
                    src="../Assets/img/daksh-logo.png"
                    alt="daksh_india"
                    className="img-responsive"
                    width="100%"
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-10 col-md-9">
              <div className="row">
                <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                  <div className="select-field">
                    <div className="city-name">
                      <p className="mb-0">
                        {/* <strong>{headerCityName}</strong> */}
                      </p>
                    </div>
                    <div className="user-name">
                      {/* <p className="mb-0">{headerUserType}</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-6 col-sm-8 col-8">
                  <div className="input-group">
                    <input
                      type="text"
                      //value={searchText}
                      className="form-control"
                      placeholder="Search for products..."
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      // onChange={(e) => setSearchText(e.target.value)
                      // }
                    />
                    <button className="search-button">
                      <span className="input-group-text" id="basic-addon1">
                        <i className="fas fa-search" />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="col-lg-3">
                  <Link type="button" className="btn btn-success cart-button">
                    <i className="fas fa-shopping-cart" />{" "}
                    <span className="ps-1">0 Items </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <center>
        <h1 style={{ margin: "14.5rem" }}>
          {" "}
          City Name and User Type does not exist
        </h1>
      </center>
      <div className="section footer">
        <div className="container">
          <div className="row g-3 mb-4">
            <div className="col-lg-2 col-md-4">
              <h3 className="mb-3">
                <img
                  src="../Assets/img/daksh-logo-dark.png"
                  alt="Daksh India"
                  width="80%"
                />
              </h3>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="row">
                <div className="col-lg-7">
                  <p>
                    Our dedication and commitment to providing the best products
                    to our customers have made us a people's favourite.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 footer-border">
              <p className="mb-1">
                <i className="fas fa-map-marker-alt text-success me-2" /> Daksh
                India, Garhi Harsaru, Gurugram, Haryana, 122505
              </p>
              <p className="mb-1">
                <i className="fas fa-envelope-open-text text-success me-2" />{" "}
                dakshindiaproducts@gmail.com
              </p>
              <p className="mb-1">
                <i className="fas fa-phone text-success me-2" /> +91 9625592663
              </p>
            </div>
            <div className="col-lg-8 col-12">
              <div className="row g-3"></div>
            </div>
          </div>{" "}
          {/* .row end */}
          <div className="row g-3 border-top pt-3">
            <div className="col-lg-6 col-md-12 text-center text-lg-start">
              <span>
                © 2023 <Link>Daksh India</Link>. All Rights Reserved.
              </span>
            </div>
            <div className="col-lg-6 col-md-12 text-center text-lg-end">
              <ul className="list-unstyled d-flex justify-content-center justify-content-lg-end mb-0">
                <li>
                  <Link to="/signin" className="p-2 ms-2 btn-success ps-3 pe-3">
                    Admin Login
                  </Link>
                </li>
              </ul>
            </div>
          </div>{" "}
          {/* .row end */}
        </div>
      </div>
    </>
  );
}
