/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, Fragment, useRef } from "react";
import { convertToBase64, validateFileSize } from "../../schema/ImageBase64";
import { ToastContainer, toast } from "react-toastify";
import { productAddValidation, updateProductAddValidation } from "../../schema/ProductAddFormValidation";
import { saveProduct } from "../../services/adminService";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { GetMaterial, GetPopularity, GetShape, GetCategory, GetVendor, GetCity } from "../../services/dropdownService";
import { useNavigate, useParams } from "react-router";
import { GetProductCode, GetProductDetails, UpdateProductDetails, ValidateProductCode, GetProductDetailsBySeller } from "../../services/adminService";
import DragAndDropFileUpload from "../Common/DragAndDropFileUpload";
import StaticExample from "../Modal/StaticModal";
import "./ProductAddForm.css";
import Loader from "../Common/Loader";
import { useSelector } from "react-redux";
import { convertBodyStructure, convertStructureOfEditProducts, convertStructureOfGetProducts } from "../../helpers/convertBodyStructure";
import { updateProductForSeller } from "../../services/orderService";

export default function ProductAddForm() {
    //#region state and variables declared
    const [details, setDetails] = useState({
        name: "",
        description: "",
        basePrice: 0,
        size: "",
        minimumOrderQuantity: null,
        popularityId: 0,
        shapeId: 0,
        materialId: 0,
        vendorId: 0,
        weight: null,
        categoryId: 0,
        status: 1,
        imageURL1: "",
        imageURL2: "",
        imageURL3: "",
        imageURL4: "",
        imageURL5: "",
        imageURL6: "",
        imageURL7: "",
        imageURL8: "",
        imageURL9: "",
        imageURL10: "",
        thumbnail: null,
        videoURL: "",
        gst: 0,
        transportation: 0,
        discount: 0,
        totalBuying: 0,
        code: "",
    });
    const [sellerPriceInfo, setSellerPriceInfo] = useState({
        productId: 0,
        baseRate: 0,
        sellerMargin: 0,
        sellerPrice: 0,
    });
    const [priceInformation, setPriceInformation] = useState([]);
    const [category, setCategory] = useState([]);
    const [shape, setShape] = useState([]);
    const [materialData, setMaterialData] = useState([]);
    const [vendorData, setVendorData] = useState([]);
    const [expandBasePrice, setExpandBasePrice] = useState(false);
    const [expandUploadImage, setExpandUploadImage] = useState(false);
    const [expand, setExpand] = useState(false);
    const [expandableProductInfo, setExpandableBasicProductInfo] = useState(false);
    const [popularityData, setPopularityData] = useState([]);
    const [saveAction, setSaveAction] = useState(0);
    const [show, setShow] = useState(false);
    const [codeAvailable, setCodeAvailable] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const isDaksh = useSelector((state) => state.auth.isDaksh);
    const sellerId = useSelector((state) => state.auth.sellerId);
    // const [isDaksh, setIsDaksh] = useState(false);
    const [productMedia, setProductMedia] = useState([]);
    const onRender = useRef(false);
    //#endregion
    const handleShow = (e) => {
        if (e == undefined) {
            setSaveAction(0);
        } else {
            setSaveAction(e);
        }
        setShow(true);
    };

    const navigate = useNavigate();

    const { id } = useParams();
    const pageAction = () => {
        if (id !== undefined) {
            getProductDetails();
            setSuccessMessage("Updated");
        } else {
            getCityInformation();
            getProductCode();
            setSuccessMessage("Added");
        }
    };

    const expandable = () => {
        setExpand(!expand);
    };

    const navigateToProductList = () => {
        return navigate("/admin/product-management");
    };

    const naviagteToProductAddForm = () => {
        navigate("/admin/product-add-form");
        return window.location.reload(true);
    };

    const saveProductDetail = async (e) => {
        setIsLoading(true);
        const body = await validating();
        if (body != undefined) {
            try {
                if (!(await ValidateProductCode(body.code))) {
                    let productDetails = await convertBodyStructure(body);
                    if (productDetails.isValid === true) {
                        const response = await saveProduct(productDetails.body);
                        if (response.statusCode === 201) {
                            handleShow(e);
                        } else if (response.statusCode === 400) {
                            toast.error(response.message);
                            response?.result?.map((data) => {
                                toast.error(data.errorMessage);
                                console.log("data.ErrorMessage", data.errorMessage);
                            });
                        } else {
                            toast.error("Something Went Wrong 121");
                        }
                    }
                } else {
                    toast.error("Please enter Unique Product Code");
                }
            } catch (error) {
                console.log(error);
                toast.error("Something Went Wrong 1");
            }
        }
        setIsLoading(false);
    };

    const getProductDetails = async () => {
        try {
            let response;
            if (isDaksh) {
                response = await GetProductDetails(id);
                setProductMedia(response?.productMedia);
                // console.log("This",response?.productMedia);
                let { productDetails } = convertStructureOfGetProducts(response);
                setPriceInformation(productDetails?.priceInformation);
                setDetails(productDetails?.basicDetail);
                // setProductCode(response.basicDetail.id);
                thumbnailChecker(productDetails?.basicDetail.imageURL1, productDetails?.basicDetail.imageURL2, productDetails?.basicDetail.imageURL3, productDetails?.basicDetail.imageURL4, productDetails?.basicDetail.imageURL5, productDetails?.basicDetail.imageURL6, productDetails?.basicDetail.imageURL7, productDetails?.basicDetail.imageURL8, productDetails?.basicDetail.imageURL9, productDetails?.basicDetail.imageURL10, productDetails?.basicDetail.thumbnail, productDetails?.basicDetail);
            } else {
                response = await GetProductDetailsBySeller(sellerId, id);
                setProductMedia(response?.productMedia);
                let { productDetails } = convertStructureOfGetProducts(response);
                setDetails(productDetails?.basicDetail);
                let sellerPrice = await calculatePriceOnLoad(productDetails?.priceInformation);
                setSellerPriceInfo(sellerPrice);
                thumbnailChecker(productDetails?.basicDetail.imageURL1, productDetails?.basicDetail.imageURL2, productDetails?.basicDetail.imageURL3, productDetails?.basicDetail.imageURL4, productDetails?.basicDetail.imageURL5, productDetails?.basicDetail.imageURL6, productDetails?.basicDetail.imageURL7, productDetails?.basicDetail.imageURL8, productDetails?.basicDetail.imageURL9, productDetails?.basicDetail.imageURL10, productDetails?.basicDetail.thumbnail, productDetails?.basicDetail);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateProductService = async (e, body) => {
        setIsLoading(true);
        try {
            if (isDaksh) {
                // const resultSet = await convertStructureOfUpdateProducts(id, body);
                const response = await UpdateProductDetails(id, body);
                // const response = "";
                if (response.statusCode === 204) {
                    handleShow(e);
                } else if (response.statusCode === 400) {
                    response?.result?.map((data) => {
                        toast.error(data.errorMessage);
                        console.log("data.ErrorMessage", data.errorMessage);
                    });
                } else {
                    console.log("errors", response.errors);
                    toast.error("Something Went Wrong");
                }
            } else {
                const response = await updateProductForSeller(sellerPriceInfo, sellerId);
                console.log(response);
                if (response?.status === 200) {
                    toast.success("Successfully updated");
                }
            }
        } catch (error) {
            console.log(error);
            toast.error("Something Went Wrong");
        }
        setIsLoading(false);
    };

    const updateProductDetail = async (e) => {
        // let body = await validating();
        let body = convertStructureOfEditProducts(details, productMedia, priceInformation);
        updateProductService(e, body);
        //     // const errors = updateProductThumbnailValidation(
        //     // tempBody.imageURL1,
        //     // tempBody.imageURL2,
        //     // tempBody.imageURL3,
        //     // tempBody.imageURL4,
        //     // tempBody.imageURL5,
        //     // tempBody.imageURL6,
        //     // tempBody.imageURL7,
        //     // tempBody.imageURL8,
        //     // tempBody.imageURL9,
        //     // tempBody.imageURL10,
        //     // tempBody.thumbnail,
        //     // tempBody.URL1,
        //     // tempBody.URL2,
        //     // tempBody.URL3,
        //     // tempBody.URL4,
        //     // tempBody.URL5,
        //     // tempBody.URL6,
        //     // tempBody.URL7,
        //     // tempBody.URL8,
        //     // tempBody.URL9,
        //     // tempBody.URL10
        //     // );
        //     if (false) console.log("thv");
        //     else {
        //         console.log();
        //         delete tempBody.code;
        //         body = tempBody;
        //         try {
        //             const resultSet = await convertStructureOfUpdateProducts(id, body);
        //             const response = await UpdateProductDetails(id, resultSet);
        //             // const response = "";
        //             if (response.statusCode === 204) {
        //                 handleShow(e);
        //             } else if (response.statusCode === 400) {
        //                 response.result.map((data) => {
        //                     toast.error(data.errorMessage);
        //                     console.log("data.ErrorMessage", data.errorMessage);
        //                 });
        //             } else {
        //                 console.log("errors", response.errors);
        //                 toast.error("Something Went Wrong");
        //             }
        //         } catch (error) {
        //             console.log(error);
        //             toast.error("Something Went Wrong");
        //         }
        //     }
        // }
        // const ans  = convertStructureOfUpdateProducts(id, body,imageMap);
    };

    const validateProductCode = async (code) => {
        try {
            const response = await ValidateProductCode(code);
            if (response === true) {
                setCodeAvailable(true);
            } else {
                setCodeAvailable(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSave = (e) => {
        if (id != undefined) {
            updateProductDetail(e);
        } else {
            saveProductDetail(e);
        }
    };

    const getCityInformation = async () => {
        priceInformation.length = 0;
        let tempArray = [];
        const response = await GetCity();
        if (response != null) {
            response?.map((data) => {
                tempArray.push({
                    cityId: data.id,
                    cityName: data.name,
                    isWholesaleActive: false,
                    wholesaleMargin: null,
                    wholesalePrice: null,
                    isNurseryActive: false,
                    nurseryMargin: null,
                    nurseryPrice: null,
                    isFactoryOutletActive: false,
                    factoryOutletMargin: null,
                    factoryOutletPrice: null,
                });
            });
            setPriceInformation(tempArray);
        }
    };

    const getProductCode = async () => {
        const response = await GetProductCode();
        //Biren
        setDetails({ ...details, code: response.codeId });
    };

    const isNumberNan = (element) => {
        // eslint-disable-next-line no-self-assign
        element === null || isNaN(element) ? (element = 0) : (element = element);
        return element;
    };
    const validating = async () => {
        let validationResponse = [];
        if (id != undefined) {
            validationResponse = await updateProductAddValidation(details.name, details.categoryId, details.popularityId, details.minimumOrderQuantity, details.basePrice, details.gst, details.materialId, details.shapeId, details.size, details.vendorId, details.imageURL1, details.imageURL2, details.imageURL3, details.imageURL4, details.imageURL5, details.imageURL6, details.imageURL7, details.imageURL8, details.imageURL9, details.imageURL10, details.thumbnail, details.code, details.URL1, details.URL2, details.URL3, details.URL4, details.URL5, details.URL6, details.URL7, details.URL8, details.URL9, details.URL10, id);
        } else {
            validationResponse = await productAddValidation(details.name, details.categoryId, details.popularityId, details.minimumOrderQuantity, details.basePrice, details.gst, details.materialId, details.shapeId, details.size, details.vendorId, details.imageURL1, details.imageURL2, details.imageURL3, details.imageURL4, details.imageURL5, details.imageURL6, details.imageURL7, details.imageURL8, details.imageURL9, details.imageURL10, details.thumbnail, details.code, details.URL1, details.URL2, details.URL3, details.URL4, details.URL5, details.URL6, details.URL7, details.URL8, details.URL9, details.URL10, id);
        }
        // //
        if (validationResponse.length != 0) {
            // toast.error("All (*) fields are mandatory");
            validationResponse?.map((data, i) => {
                // if (data.ErrorField == "Thumbnail") {
                toast.error(data.ErrorMessage);
                // }
            });
        } else {
            let body = { ...details };
            body.categoryId = parseInt(body.categoryId);
            body.basePrice = parseFloat(body.basePrice);
            body.discount = parseFloat(isNumberNan(body.discount));
            body.gst = parseFloat(body.gst);
            body.materialId = parseInt(body.materialId);
            body.vendorId = parseInt(body.vendorId);
            body.popularityId = parseInt(body.popularityId);
            body.shapeId = parseInt(body.shapeId);
            body.weight = parseFloat(isNumberNan(body.weight));
            body.status = parseInt(body.status);
            if (body.imageURL2 == "" || body.imageURL2 == null) {
                body.imageURL2 = {};
            }
            if (body.imageURL3 == "" || body.imageURL3 == null) {
                body.imageURL3 = {};
            }
            if (body.imageURL4 == "" || body.imageURL4 == null) {
                body.imageURL4 = {};
            }
            if (body.imageURL5 == "" || body.imageURL5 == null) {
                body.imageURL5 = {};
            }
            if (body.imageURL6 == "" || body.imageURL6 == null) {
                body.imageURL6 = {};
            }
            if (body.imageURL7 == "" || body.imageURL7 == null) {
                body.imageURL7 = {};
            }
            if (body.imageURL8 == "" || body.imageURL8 == null) {
                body.imageURL8 = {};
            }
            if (body.imageURL9 == "" || body.imageURL9 == null) {
                body.imageURL9 = {};
            }
            if (body.imageURL10 == "" || body.imageURL10 == null) {
                body.imageURL10 = {};
            }
            if (body.thumbnail == "" || body.thumbnail == null) {
                body.thumbnail = 0;
            }
            body.transportation = parseFloat(isNumberNan(body.transportation));
            body.totalBuying = parseFloat(isNumberNan(body.totalBuying));
            let bodyPriceInformation = [...priceInformation];
            bodyPriceInformation?.map((data) => {
                data.wholesaleMargin = parseFloat(isNumberNan(data.wholesaleMargin));
                data.wholesalePrice = parseFloat(isNumberNan(data.wholesalePrice));
                data.nurseryMargin = parseFloat(isNumberNan(data.nurseryMargin));
                data.nurseryPrice = parseFloat(isNumberNan(data.nurseryPrice));
                data.factoryOutletMargin = parseFloat(isNumberNan(data.factoryOutletMargin));
                data.factoryOutletPrice = parseFloat(isNumberNan(data.factoryOutletPrice));
            });
            body = {
                ...body,
                priceInformation: bodyPriceInformation,
            };
            return body;
        }
    };

    //#region Image Upload Functions
    const handleFirstFileUpload = async (e) => {
        if (validateFileSize(e) == true) {
            const base64 = await convertToBase64(e, details.name);
            var mediaObjectTemp = {
                id: 0,
                documentName: base64.documentName,
                base64: base64.base64,
                isThumbnail: false,
                mediaType: "image",
                url: "",
            };
            productMedia.push(mediaObjectTemp);
            setDetails({ ...details, imageURL1: base64 });
        } else toast.error("File exceeds 1 MB");
    };

    const handleSecondFileUpload = async (e) => {
        if (validateFileSize(e) == true) {
            const base64 = await convertToBase64(e, details.name);
            let mediaObject = {
                id: 0,
                documentName: base64.documentName,
                base64: base64.base64,
                isThumbnail: false,
                mediaType: "image",
                url: "",
            };
            productMedia.push(mediaObject);
            setDetails({ ...details, imageURL2: base64 });
        } else toast.error("File exceeds 1 MB");
    };
    const handleThirdFileUpload = async (e) => {
        if (validateFileSize(e) == true) {
            const base64 = await convertToBase64(e, details.name);
            let mediaObject = {
                id: 0,
                documentName: base64.documentName,
                base64: base64.base64,
                isThumbnail: false,
                mediaType: "image",
                url: "",
            };
            productMedia.push(mediaObject);
            setDetails({ ...details, imageURL3: base64 });
        } else toast.error("File exceeds 1 MB");
    };
    const handleFourthFileUpload = async (e) => {
        if (validateFileSize(e) == true) {
            const base64 = await convertToBase64(e, details.name);
            let mediaObject = {
                id: 0,
                documentName: base64.documentName,
                base64: base64.base64,
                isThumbnail: false,
                mediaType: "image",
                url: "",
            };
            productMedia.push(mediaObject);
            setDetails({ ...details, imageURL4: base64 });
        } else toast.error("File exceeds 1 MB");
    };
    const handleFifthFileUpload = async (e) => {
        if (validateFileSize(e) == true) {
            const base64 = await convertToBase64(e, details.name);
            let mediaObject = {
                id: 0,
                documentName: base64.documentName,
                base64: base64.base64,
                isThumbnail: false,
                mediaType: "image",
                url: "",
            };
            productMedia.push(mediaObject);
            setDetails({ ...details, imageURL5: base64 });
        } else toast.error("File exceeds 1 MB");
    };
    const handleSixthFileUpload = async (e) => {
        if (validateFileSize(e) == true) {
            const base64 = await convertToBase64(e, details.name);
            let mediaObject = {
                id: 0,
                documentName: base64.documentName,
                base64: base64.base64,
                isThumbnail: false,
                mediaType: "image",
                url: "",
            };
            productMedia.push(mediaObject);
            setDetails({ ...details, imageURL6: base64 });
        } else toast.error("File exceeds 1 MB");
    };
    const handleSeventhFileUpload = async (e) => {
        if (validateFileSize(e) == true) {
            const base64 = await convertToBase64(e, details.name);
            let mediaObject = {
                id: 0,
                documentName: base64.documentName,
                base64: base64.base64,
                isThumbnail: false,
                mediaType: "image",
                url: "",
            };
            productMedia.push(mediaObject);
            setDetails({ ...details, imageURL7: base64 });
        } else toast.error("File exceeds 1 MB");
    };
    const handleEighthFileUpload = async (e) => {
        if (validateFileSize(e) == true) {
            const base64 = await convertToBase64(e, details.name);
            let mediaObject = {
                id: 0,
                documentName: base64.documentName,
                base64: base64.base64,
                isThumbnail: false,
                mediaType: "image",
                url: "",
            };
            productMedia.push(mediaObject);
            setDetails({ ...details, imageURL8: base64 });
        } else toast.error("File exceeds 1 MB");
    };
    const handleNinthFileUpload = async (e) => {
        if (validateFileSize(e) == true) {
            const base64 = await convertToBase64(e, details.name);
            let mediaObject = {
                id: 0,
                documentName: base64.documentName,
                base64: base64.base64,
                isThumbnail: false,
                mediaType: "image",
                url: "",
            };
            productMedia.push(mediaObject);
            setDetails({ ...details, imageURL9: base64 });
        } else toast.error("File exceeds 1 MB");
    };
    const handleTengthFileUpload = async (e) => {
        if (validateFileSize(e) == true) {
            const base64 = await convertToBase64(e, details.name);
            let mediaObject = {
                id: 0,
                documentName: base64.documentName,
                base64: base64.base64,
                isThumbnail: false,
                mediaType: "image",
                url: "",
            };
            productMedia.push(mediaObject);
            setDetails({ ...details, imageURL10: base64 });
        } else toast.error("File exceeds 1 MB");
    };
    //#endregion

    //#region  Thumbnail { get; set;}
    const handleSetThumbnail = (e) => {
        if (e == 1) {
            let n = productMedia.length;
            for (let i = 0; i < n; i++) {
                if (productMedia[i]?.url === details.imageURL1 || productMedia[i]?.base64 === details.imageURL1?.base64) {
                    productMedia[i].isThumbnail = true;
                } else {
                    productMedia[i].isThumbnail = false;
                }
            }
            // setThumbnail1Check(true);
            setDetails({ ...details, thumbnail: 1 });
        } else if (e == 2) {
            let n = productMedia.length;
            for (let i = 0; i < n; i++) {
                if (productMedia[i]?.url === details.imageURL2 || productMedia[i]?.base64 === details.imageURL2?.base64) {
                    productMedia[i].isThumbnail = true;
                } else {
                    productMedia[i].isThumbnail = false;
                }
            }
            // setThumbnail2Check(true);
            setDetails({ ...details, thumbnail: 2 });
        } else if (e == 3) {
            let n = productMedia.length;
            for (let i = 0; i < n; i++) {
                if (productMedia[i]?.url === details.imageURL3 || productMedia[i]?.base64 === details.imageURL3?.base64) {
                    productMedia[i].isThumbnail = true;
                } else {
                    productMedia[i].isThumbnail = false;
                }
            }
            setDetails({ ...details, thumbnail: 3 });
        } else if (e == 4) {
            let n = productMedia.length;
            for (let i = 0; i < n; i++) {
                if (productMedia[i]?.url === details.imageURL4 || productMedia[i]?.base64 === details.imageURL4?.base64) {
                    productMedia[i].isThumbnail = true;
                } else {
                    productMedia[i].isThumbnail = false;
                }
            }
            setDetails({ ...details, thumbnail: 4 });
        } else if (e == 5) {
            let n = productMedia.length;
            for (let i = 0; i < n; i++) {
                if (productMedia[i]?.url === details.imageURL5 || productMedia[i]?.base64 === details.imageURL5?.base64) {
                    productMedia[i].isThumbnail = true;
                } else {
                    productMedia[i].isThumbnail = false;
                }
            }
            setDetails({ ...details, thumbnail: 5 });
        } else if (e == 6) {
            let n = productMedia.length;
            for (let i = 0; i < n; i++) {
                if (productMedia[i]?.url === details.imageURL6 || productMedia[i]?.base64 === details.imageURL6?.base64) {
                    productMedia[i].isThumbnail = true;
                } else {
                    productMedia[i].isThumbnail = false;
                }
            }
            setDetails({ ...details, thumbnail: 6 });
        } else if (e == 7) {
            let n = productMedia.length;
            for (let i = 0; i < n; i++) {
                if (productMedia[i]?.url === details.imageURL7 || productMedia[i]?.base64 === details.imageURL7?.base64) {
                    productMedia[i].isThumbnail = true;
                } else {
                    productMedia[i].isThumbnail = false;
                }
            }
            setDetails({ ...details, thumbnail: 7 });
        } else if (e == 8) {
            let n = productMedia.length;
            for (let i = 0; i < n; i++) {
                if (productMedia[i]?.url === details.imageURL8 || productMedia[i]?.base64 === details.imageURL8?.base64) {
                    productMedia[i].isThumbnail = true;
                } else {
                    productMedia[i].isThumbnail = false;
                }
            }
            setDetails({ ...details, thumbnail: 8 });
        } else if (e == 9) {
            let n = productMedia.length;
            for (let i = 0; i < n; i++) {
                if (productMedia[i]?.url === details.imageURL9 || productMedia[i]?.base64 === details.imageURL9?.base64) {
                    productMedia[i].isThumbnail = true;
                } else {
                    productMedia[i].isThumbnail = false;
                }
            }
            setDetails({ ...details, thumbnail: 9 });
        } else if (e == 10) {
            let n = productMedia.length;
            for (let i = 0; i < n; i++) {
                if (productMedia[i]?.url === details.imageURL10 || productMedia[i]?.base64 === details.imageURL10?.base64) {
                    productMedia[i].isThumbnail = true;
                } else {
                    productMedia[i].isThumbnail = false;
                }
            }
            setDetails({ ...details, thumbnail: 10 });
        }
    };

    const thumbnailChecker = (image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, thumbnail, details) => {
        if (thumbnail?.length !== 0) {
            if (image1 === thumbnail) {
                setDetails({ ...details, thumbnail: 1 });
            }
            if (image2 === thumbnail) {
                setDetails({ ...details, thumbnail: 2 });
            }
            if (image3 === thumbnail) {
                setDetails({ ...details, thumbnail: 3 });
            }
            if (image4 === thumbnail) {
                setDetails({ ...details, thumbnail: 4 });
            }
            if (image5 === thumbnail) {
                setDetails({ ...details, thumbnail: 5 });
            }
            if (image6 === thumbnail) {
                setDetails({ ...details, thumbnail: 6 });
            }
            if (image7 === thumbnail) {
                setDetails({ ...details, thumbnail: 7 });
            }
            if (image8 === thumbnail) {
                setDetails({ ...details, thumbnail: 8 });
            }
            if (image9 === thumbnail) {
                setDetails({ ...details, thumbnail: 9 });
            }
            if (image10 === thumbnail) {
                setDetails({ ...details, thumbnail: 10 });
            }
        } else {
            return 0;
        }
    };
    //#endregion

    //#region Image Delete Functions
    const handleImage1Delete = () => {
        let n = productMedia.length;
        for (let i = 0; i < n; i++) {
            if (productMedia[i]?.url === details.imageURL1) {
                productMedia.splice(i, 1);
            } else if (productMedia[i]?.base64 === details.imageURL1?.base64) {
                productMedia.splice(i, 1);
            }
        }
        setDetails({ ...details, imageURL1: "" });
    };
    const handleImage2Delete = () => {
        let n = productMedia.length;
        for (let i = 0; i < n; i++) {
            if (productMedia[i]?.url === details.imageURL2) {
                productMedia.splice(i, 1);
            } else if (productMedia[i]?.base64 === details.imageURL2?.base64) {
                productMedia.splice(i, 1);
            }
        }
        setDetails({ ...details, imageURL2: "" });
    };
    const handleImage3Delete = () => {
        let n = productMedia.length;
        for (let i = 0; i < n; i++) {
            if (productMedia[i]?.url === details.imageURL3) {
                productMedia.splice(i, 1);
            } else if (productMedia[i]?.base64 === details.imageURL3?.base64) {
                productMedia.splice(i, 1);
            }
        }
        setDetails({ ...details, imageURL3: "" });
    };
    const handleImage4Delete = () => {
        let n = productMedia.length;
        for (let i = 0; i < n; i++) {
            if (productMedia[i]?.url === details.imageURL4) {
                productMedia.splice(i, 1);
            } else if (productMedia[i]?.base64 === details.imageURL4?.base64) {
                productMedia.splice(i, 1);
            }
        }
        setDetails({ ...details, imageURL4: "" });
    };
    const handleImage5Delete = () => {
        let n = productMedia.length;
        for (let i = 0; i < n; i++) {
            if (productMedia[i]?.url === details.imageURL5) {
                productMedia.splice(i, 1);
            } else if (productMedia[i]?.base64 === details.imageURL5?.base64) {
                productMedia.splice(i, 1);
            }
        }
        setDetails({ ...details, imageURL5: "" });
    };
    const handleImage6Delete = () => {
        let n = productMedia.length;
        for (let i = 0; i < n; i++) {
            if (productMedia[i]?.url === details.imageURL6) {
                productMedia.splice(i, 1);
            } else if (productMedia[i]?.base64 === details.imageURL6?.base64) {
                productMedia.splice(i, 1);
            }
        }
        setDetails({ ...details, imageURL6: "" });
    };
    const handleImage7Delete = () => {
        let n = productMedia.length;
        for (let i = 0; i < n; i++) {
            if (productMedia[i]?.url === details.imageURL7) {
                productMedia.splice(i, 1);
            } else if (productMedia[i]?.base64 === details.imageURL7?.base64) {
                productMedia.splice(i, 1);
            }
        }
        setDetails({ ...details, imageURL7: "" });
    };
    const handleImage8Delete = () => {
        let n = productMedia.length;
        for (let i = 0; i < n; i++) {
            if (productMedia[i]?.url === details.imageURL8) {
                productMedia.splice(i, 1);
            } else if (productMedia[i]?.base64 === details.imageURL8?.base64) {
                productMedia.splice(i, 1);
            }
        }
        setDetails({ ...details, imageURL8: "" });
    };
    const handleImage9Delete = () => {
        let n = productMedia.length;
        for (let i = 0; i < n; i++) {
            if (productMedia[i]?.url === details.imageURL9) {
                productMedia.splice(i, 1);
            } else if (productMedia[i]?.base64 === details.imageURL9?.base64) {
                productMedia.splice(i, 1);
            }
        }
        setDetails({ ...details, imageURL9: "" });
    };
    const handleImage10Delete = () => {
        let n = productMedia.length;
        for (let i = 0; i < n; i++) {
            if (productMedia[i]?.url === details.imageURL10) {
                productMedia.splice(i, 1);
            } else if (productMedia[i]?.base64 === details.imageURL10?.base64) {
                productMedia.splice(i, 1);
            }
        }
        setDetails({ ...details, imageURL10: "" });
    };
    //#endregion

    //#region Dropdown Api Calls
    const shapeHandler = async () => {
        try {
            const response = await GetShape();
            setShape(response);
        } catch (error) {
            console.log("Something went wrong", error.message);
        }
    };
    const vendorHandler = async () => {
        try {
            const response = await GetVendor();
            setVendorData(response);
        } catch (error) {
            console.log("Something went wrong", error.message);
        }
    };

    const materialHandler = async () => {
        try {
            const response = await GetMaterial();
            setMaterialData(response);
        } catch (error) {
            console.log("Something went wrong", error.message);
        }
    };

    const popularityHandler = async () => {
        try {
            const response = await GetPopularity();
            setPopularityData(response);
        } catch (error) {
            console.log("Something went wrong", error.message);
        }
    };

    const categorylist = async () => {
        try {
            const response = await GetCategory();
            setCategory(response);
        } catch (error) {
            console.log(error.message);
        }
    };
    //#endregion

    const expandableBasePrice = () => {
        setExpandBasePrice(!expandBasePrice);
    };

    const expandableUploadProductImage = () => {
        setExpandUploadImage(!expandUploadImage);
    };

    const expandableBasicProductInfo = () => {
        setExpandableBasicProductInfo(!expandableProductInfo);
    };

    const CalculatePrice = (margin) => {
        const basePrice = details.totalBuying;
        const Price = basePrice * (1 + margin / 100);
        return parseFloat(Price.toFixed(2));
    };

    //#region price information handler functions
    const wholeSaleCheckBoxHandler = (x, event) => {
        const index = priceInformation.findIndex((data) => data.cityId === x);
        const newArray = [...priceInformation];
        if (event === false) {
            newArray[index] = {
                ...newArray[index],
                isWholesaleActive: event,
                wholesaleMargin: 0,
                wholesalePrice: 0,
            };
        } else if (event === true) {
            newArray[index] = {
                ...newArray[index],
                isWholesaleActive: event,
                wholesaleMargin: 0,
                wholesalePrice: CalculatePrice(newArray[index].wholesaleMargin),
            };
        }
        setPriceInformation(newArray);
    };

    const wholeSaleMarginHandler = (x, event) => {
        const index = priceInformation.findIndex((data) => data.cityId === x);
        const newArray = [...priceInformation];
        newArray[index] = {
            ...newArray[index],
            wholesaleMargin: event,
            wholesalePrice: CalculatePrice(event),
        };

        setPriceInformation(newArray);
    };

    const nurserySaleCheckBoxHandler = (x, event) => {
        const index = priceInformation.findIndex((data) => data.cityId === x);
        const newArray = [...priceInformation];
        if (event === false) {
            newArray[index] = {
                ...newArray[index],
                isNurseryActive: event,
                nurseryMargin: 0,
                nurseryPrice: 0,
            };
        } else if (event === true) {
            newArray[index] = {
                ...newArray[index],
                isNurseryActive: event,
                nurseryMargin: 0,
                nurseryPrice: CalculatePrice(newArray[index].nurseryMargin),
            };
        }
        setPriceInformation(newArray);
    };

    const nurserySaleMarginHandler = (x, event) => {
        const index = priceInformation.findIndex((data) => data.cityId === x);
        const newArray = [...priceInformation];
        newArray[index] = {
            ...newArray[index],
            nurseryMargin: event,
            nurseryPrice: CalculatePrice(event),
        };
        setPriceInformation(newArray);
    };
    const factorySaleCheckBoxHandler = (x, event) => {
        const index = priceInformation.findIndex((data) => data.cityId === x);
        const newArray = [...priceInformation];
        if (event === false) {
            newArray[index] = {
                ...newArray[index],
                isFactoryOutletActive: event,
                factoryOutletMargin: 0,
                factoryOutletPrice: 0,
            };
        } else if (event === true) {
            newArray[index] = {
                ...newArray[index],
                isFactoryOutletActive: event,
                factoryOutletMargin: 0,
                factoryOutletPrice: CalculatePrice(newArray[index].factoryOutletMargin),
            };
        }
        setPriceInformation(newArray);
    };

    const factorySaleMarginHandler = (x, event) => {
        const index = priceInformation.findIndex((data) => data.cityId === x);
        const newArray = [...priceInformation];
        newArray[index] = {
            ...newArray[index],
            factoryOutletMargin: event,
            factoryOutletPrice: CalculatePrice(event),
        };
        setPriceInformation(newArray);
    };
    //#endregion

    //#region priceInformation price updating after basePrice value changes
    const setUserTypePrice = () => {
        let newArray = [...priceInformation];
        for (let i = 0; i < priceInformation?.length; i++) {
            if (priceInformation[i]?.isWholesaleActive === true) {
                if (i + 1 === priceInformation[i]?.cityId) {
                    newArray[i] = {
                        ...newArray[i],
                        wholesalePrice: CalculatePrice(priceInformation[i].wholesaleMargin),
                    };
                }
            }
            if (priceInformation[i]?.isNurseryActive === true) {
                if (i + 1 === priceInformation[i]?.cityId) {
                    newArray[i] = {
                        ...newArray[i],
                        nurseryPrice: CalculatePrice(priceInformation[i].nurseryMargin),
                    };
                }
            }
            if (priceInformation[i]?.isFactoryOutletActive === true) {
                if (i + 1 === priceInformation[i]?.cityId) {
                    newArray[i] = {
                        ...newArray[i],
                        factoryOutletPrice: CalculatePrice(priceInformation[i].factoryOutletMargin),
                    };
                }
            }
        }
        setPriceInformation(newArray);
    };

    useEffect(() => {
        if (!onRender.current) onRender.current = true;
        else setUserTypePrice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details?.totalBuying]);
    //#endregion

    useEffect(() => {
        let total = parseFloat(parseFloat(details.basePrice) * ((100 + parseFloat(details.gst) + parseFloat(details.transportation) - parseFloat(details.discount)) / 100)).toFixed(2);
        setDetails({ ...details, totalBuying: total });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details.basePrice, details.gst, details.transportation, details.discount]);

    useEffect(() => {
        pageAction();
        shapeHandler();
        materialHandler();
        popularityHandler();
        categorylist();
        vendorHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSellerMarginChange = (e) => {
        setSellerPriceInfo({
            ...sellerPriceInfo,
            sellerMargin: e.target.value,
            sellerPrice: ((sellerPriceInfo.baseRate * e.target.value) / 100 + sellerPriceInfo.baseRate).toFixed(2),
        });
    };

    const handleSellerPriceChange = (e) => {
        setSellerPriceInfo({
            ...sellerPriceInfo,
            sellerPrice: e.target.value,
            sellerMargin: (((e.target.value - sellerPriceInfo.baseRate) / sellerPriceInfo.baseRate) * 100).toFixed(2),
        });
    };

    const calculatePriceOnLoad = async (price) => {
        let sellerPrice = Number((price?.baseRate * price?.sellerMargin) / 100 + price?.baseRate).toFixed(2);
        // price?.priceInformation?.sellerPrice = sellerPrice;
        price = {
            ...price,
            sellerPrice: sellerPrice,
        };
        return price;
    };

    return (
        <Fragment>
            {saveAction === 0 ? (
                <StaticExample
                    show={show}
                    message={successMessage}
                    navigateToProductList={navigateToProductList}
                />
            ) : (
                <StaticExample
                    show={show}
                    message={successMessage}
                    navigateToProductList={naviagteToProductAddForm}
                />
            )}
            <ToastContainer />
            {isLoading && <Loader />}
            <div className="page-body px-0 py-lg-2 py-1 mt-0 mt-lg-3 ms-3">
                <div className="container-fluid p-0">
                    <div className="row g-3">
                        {/* Product Management header + Add button */}
                        <div className="col-12">
                            <div className="row-title px-2">
                                <div>
                                    <Link to={isDaksh ? "/admin/product-management" : "/seller-admin/product-management"}>
                                        <h6 className="fw-bold">
                                            <i className="fa fa-arrow-left me-2" />
                                            Add Product
                                        </h6>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* Dash Card 1 */}
                        {expandableProductInfo === false ? (
                            <div className="col-12">
                                <div
                                    className="card card1"
                                    style={!isDaksh ? { pointerEvents: "none", opacity: "0.7", cursor: "not-allowed" } : {}}>
                                    <div className="card-header">
                                        <h6 className="card-title m-0">Basic Product Information</h6>
                                        <a className="minimize">
                                            <i
                                                className="text-primary fa fa-minus"
                                                onClick={expandableBasicProductInfo}
                                            />
                                        </a>
                                    </div>
                                    <div className="card-body card-body1">
                                        <div className="row g-3">
                                            <div className="col-sm-3">
                                                <label className="form-label">
                                                    Code <sup className="text-danger">*</sup>
                                                </label>
                                                {id !== undefined ? (
                                                    <input
                                                        type="text"
                                                        value={details.code}
                                                        className="form-control form-control"
                                                        readOnly
                                                        placeholder="#25A"
                                                    />
                                                ) : (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            value={details.code}
                                                            maxLength={8}
                                                            minLength={1}
                                                            className="form-control form-control"
                                                            onChange={(e) => {
                                                                setDetails({
                                                                    ...details,
                                                                    code: e.target.value,
                                                                });
                                                            }}
                                                            onBlur={(e) => validateProductCode(e.target.value)}
                                                            placeholder="#25A"
                                                        />
                                                        {codeAvailable === false ? <span className="text-success code-available">Code Available</span> : <span className="text-danger code-available">Code Not Available</span>}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="form-label">
                                                    Name <sup className="text-danger">*</sup>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control"
                                                    placeholder="Name"
                                                    value={details.name}
                                                    onChange={(e) => {
                                                        setDetails({
                                                            ...details,
                                                            name: e.target.value,
                                                        });
                                                    }}
                                                    required
                                                    disabled={!isDaksh}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="form-label">
                                                    Category <sup className="text-danger">*</sup>
                                                </label>
                                                <select
                                                    disabled={!isDaksh}
                                                    name=""
                                                    id=""
                                                    className="form-select form-control"
                                                    value={details.categoryId}
                                                    onChange={(e) =>
                                                        setDetails({
                                                            ...details,
                                                            categoryId: e.target.value,
                                                        })
                                                    }
                                                    required>
                                                    <option value="0">---Category---</option>
                                                    {category?.map((category) => (
                                                        <option
                                                            value={category.id}
                                                            key={category.id}>
                                                            {category.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="form-label">Status</label>
                                                <select
                                                    disabled={!isDaksh}
                                                    value={details.status}
                                                    name=""
                                                    id=""
                                                    className="form-select form-control"
                                                    onChange={(e) =>
                                                        setDetails({
                                                            ...details,
                                                            status: e.target.value,
                                                        })
                                                    }>
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="form-label">
                                                    Size (Inch) <sup className="text-danger">*</sup>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control form-control"
                                                    placeholder="Size"
                                                    value={details.size}
                                                    onChange={(e) => {
                                                        setDetails({
                                                            ...details,
                                                            size: e.target.value,
                                                        });
                                                    }}
                                                    disabled={!isDaksh}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="form-label">
                                                    Weight <small>(kg)</small>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control form-control"
                                                    placeholder="Weight"
                                                    value={details.weight}
                                                    onChange={(e) => {
                                                        setDetails({
                                                            ...details,
                                                            weight: e.target.value,
                                                        });
                                                    }}
                                                    required
                                                    disabled={!isDaksh}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="form-label">
                                                    Shape <sup className="text-danger">*</sup>
                                                </label>
                                                <select
                                                    disabled={!isDaksh}
                                                    name=""
                                                    id=""
                                                    className="form-select form-control"
                                                    value={details.shapeId}
                                                    onChange={(e) =>
                                                        setDetails({
                                                            ...details,
                                                            shapeId: e.target.value,
                                                        })
                                                    }
                                                    required>
                                                    <option value="0">---Shape---</option>
                                                    {shape?.map((shape) => (
                                                        <option
                                                            value={shape.id}
                                                            key={shape.id}>
                                                            {shape.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="form-label">
                                                    Material <sup className="text-danger">*</sup>
                                                </label>
                                                <select
                                                    disabled={!isDaksh}
                                                    name=""
                                                    id=""
                                                    className="form-select form-control"
                                                    value={details.materialId}
                                                    onChange={(e) =>
                                                        setDetails({
                                                            ...details,
                                                            materialId: e.target.value,
                                                        })
                                                    }
                                                    required>
                                                    {" "}
                                                    <option value="0">---Material---</option>
                                                    {materialData?.map((materialData) => (
                                                        <option
                                                            value={materialData.id}
                                                            key={materialData.id}>
                                                            {materialData.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="form-label">
                                                    MOQ <sup className="text-danger">*</sup>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control"
                                                    placeholder="Min. Order Quantity"
                                                    value={details.minimumOrderQuantity}
                                                    onChange={(e) =>
                                                        setDetails({
                                                            ...details,
                                                            minimumOrderQuantity: e.target.value,
                                                        })
                                                    }
                                                    required
                                                    disabled={!isDaksh}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="form-label">
                                                    Vendor <sup className="text-danger">*</sup>
                                                </label>
                                                <select
                                                    disabled={!isDaksh}
                                                    name=""
                                                    id=""
                                                    className="form-select form-control"
                                                    value={details.vendorId}
                                                    onChange={(e) => {
                                                        setDetails({
                                                            ...details,
                                                            vendorId: e.target.value,
                                                        });
                                                    }}
                                                    required>
                                                    <option value="0">---Vendor---</option>
                                                    {vendorData?.map((data) => (
                                                        <option
                                                            value={data.id}
                                                            key={data.id}>
                                                            {data.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="form-label">
                                                    Rating <sup className="text-danger">*</sup>
                                                </label>
                                                <select
                                                    disabled={!isDaksh}
                                                    name=""
                                                    id=""
                                                    value={details.popularityId}
                                                    className="form-select form-control"
                                                    onChange={(e) =>
                                                        setDetails({
                                                            ...details,
                                                            popularityId: e.target.value,
                                                        })
                                                    }
                                                    required>
                                                    <option value="">---</option>
                                                    {popularityData?.map((popularityData) => (
                                                        <option
                                                            value={popularityData.id}
                                                            key={popularityData.id}>
                                                            {popularityData.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-6 col-lg-12">
                                                <label className="form-label">Description</label>
                                                <textarea
                                                    rows={1}
                                                    className="form-control"
                                                    style={{ height: "200px" }}
                                                    placeholder="Enter Description"
                                                    value={details.description}
                                                    onChange={(e) =>
                                                        setDetails({
                                                            ...details,
                                                            description: e.target.value,
                                                        })
                                                    }
                                                    disabled={!isDaksh}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-12">
                                <div className="card card4">
                                    <div className="card-header">
                                        <h6 className="card-title m-0">Basic Product Information</h6>
                                        <a className="minimize">
                                            <i
                                                className="fa fa-plus text-primary"
                                                onClick={expandableBasicProductInfo}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}
                        {expandUploadImage === false ? (
                            <div className="col-12">
                                <div
                                    className="card card2"
                                    style={!isDaksh ? { pointerEvents: "none", opacity: "0.7", cursor: "not-allowed" } : {}}>
                                    <div className="card-header">
                                        <h6 className="card-title m-0">Upload Product Image / Video</h6>
                                        <a className="minimize">
                                            <i
                                                className="fa fa-minus text-primary"
                                                onClick={expandableUploadProductImage}
                                            />
                                        </a>
                                    </div>
                                    <div className="card-body card-body2">
                                        <div className="row g-3">
                                            {/* --------------UPLOAD IMAGES DROPIFY------------------ */}
                                            <div className="col-lg-12 mt-4">
                                                <div className="row g-3 mb-3">
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="small text-muted">
                                                            Note - <small>Max file size: 1MB</small> <sup className="text-danger">*</sup>
                                                        </div>
                                                        {id != undefined ? (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={1}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                url={details.imageURL1}
                                                                flag={id}
                                                                id="image1"
                                                                number="1"
                                                                handleDelete={handleImage1Delete}
                                                                onChange={(e) => handleFirstFileUpload(e)}
                                                            />
                                                        ) : (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={1}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                url={details.imageURL1}
                                                                handleDelete={handleImage1Delete}
                                                                flag={id}
                                                                number="1"
                                                                onChange={(e) => handleFirstFileUpload(e)}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="small text-muted">
                                                            Note - <small>Max file size: 1MB</small>
                                                        </div>
                                                        {id != undefined ? (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={2}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                flag={id}
                                                                url={details.imageURL2}
                                                                id="image2"
                                                                number="2"
                                                                handleDelete={handleImage2Delete}
                                                                onChange={(e) => handleSecondFileUpload(e)}
                                                            />
                                                        ) : (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={2}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                number="2"
                                                                url={details.imageURL2}
                                                                handleDelete={handleImage2Delete}
                                                                flag={id}
                                                                onChange={(e) => handleSecondFileUpload(e)}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="small text-muted">
                                                            Note - <small>Max file size: 1MB</small>
                                                        </div>
                                                        {id != undefined ? (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={3}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                url={details.imageURL3}
                                                                number="3"
                                                                id="image3"
                                                                flag={id}
                                                                handleDelete={handleImage3Delete}
                                                                onChange={(e) => handleThirdFileUpload(e)}
                                                            />
                                                        ) : (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={3}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                number="3"
                                                                url={details.imageURL3}
                                                                handleDelete={handleImage3Delete}
                                                                flag={id}
                                                                onChange={(e) => handleThirdFileUpload(e)}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="small text-muted">
                                                            Note - <small>Max file size: 1MB</small>
                                                        </div>
                                                        {id != undefined ? (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={4}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                flag={id}
                                                                url={details.imageURL4}
                                                                number="4"
                                                                id="image4"
                                                                handleDelete={handleImage4Delete}
                                                                onChange={(e) => handleFourthFileUpload(e)}
                                                            />
                                                        ) : (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={4}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                url={details.imageURL4}
                                                                handleDelete={handleImage4Delete}
                                                                flag={id}
                                                                number="4"
                                                                onChange={(e) => handleFourthFileUpload(e)}
                                                            />
                                                        )}
                                                    </div>

                                                    {/* 6 New images for upload */}
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="small text-muted">
                                                            Note - <small>Max file size: 1MB</small> <sup className="text-danger">*</sup>
                                                        </div>
                                                        {id != undefined ? (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={5}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                url={details.imageURL5}
                                                                flag={id}
                                                                id="image5"
                                                                number="5"
                                                                handleDelete={handleImage5Delete}
                                                                onChange={(e) => handleFifthFileUpload(e)}
                                                            />
                                                        ) : (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={5}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                url={details.imageURL5}
                                                                handleDelete={handleImage5Delete}
                                                                flag={id}
                                                                number="5"
                                                                onChange={(e) => handleFifthFileUpload(e)}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="small text-muted">
                                                            Note - <small>Max file size: 1MB</small> <sup className="text-danger">*</sup>
                                                        </div>
                                                        {id != undefined ? (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={6}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                url={details.imageURL6}
                                                                flag={id}
                                                                id="image6"
                                                                number="6"
                                                                handleDelete={handleImage6Delete}
                                                                onChange={(e) => handleSixthFileUpload(e)}
                                                            />
                                                        ) : (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={6}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                url={details.imageURL6}
                                                                handleDelete={handleImage6Delete}
                                                                flag={id}
                                                                number="6"
                                                                onChange={(e) => handleSixthFileUpload(e)}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="small text-muted">
                                                            Note - <small>Max file size: 1MB</small> <sup className="text-danger">*</sup>
                                                        </div>
                                                        {id != undefined ? (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={7}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                url={details.imageURL7}
                                                                flag={id}
                                                                id="image7"
                                                                number="7"
                                                                handleDelete={handleImage7Delete}
                                                                onChange={(e) => handleSeventhFileUpload(e)}
                                                            />
                                                        ) : (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={7}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                url={details.imageURL8}
                                                                handleDelete={handleImage7Delete}
                                                                flag={id}
                                                                number="8"
                                                                onChange={(e) => handleSeventhFileUpload(e)}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="small text-muted">
                                                            Note - <small>Max file size: 1MB</small> <sup className="text-danger">*</sup>
                                                        </div>
                                                        {id != undefined ? (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={8}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                url={details.imageURL8}
                                                                flag={id}
                                                                id="image8"
                                                                number="8"
                                                                handleDelete={handleImage8Delete}
                                                                onChange={(e) => handleEighthFileUpload(e)}
                                                            />
                                                        ) : (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={8}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                url={details.imageURL1}
                                                                handleDelete={handleImage8Delete}
                                                                flag={id}
                                                                number="8"
                                                                onChange={(e) => handleEighthFileUpload(e)}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="small text-muted">
                                                            Note - <small>Max file size: 1MB</small> <sup className="text-danger">*</sup>
                                                        </div>
                                                        {id != undefined ? (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={9}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                url={details.imageURL9}
                                                                flag={id}
                                                                id="image9"
                                                                number="9"
                                                                handleDelete={handleImage9Delete}
                                                                onChange={(e) => handleNinthFileUpload(e)}
                                                            />
                                                        ) : (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={9}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                url={details.imageURL9}
                                                                handleDelete={handleImage9Delete}
                                                                flag={id}
                                                                number="9"
                                                                onChange={(e) => handleNinthFileUpload(e)}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="col-xl-3 col-sm-6">
                                                        <div className="small text-muted">
                                                            Note - <small>Max file size: 1MB</small> <sup className="text-danger">*</sup>
                                                        </div>
                                                        {id != undefined ? (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={10}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                url={details.imageURL10}
                                                                flag={id}
                                                                id="image10"
                                                                number="10"
                                                                handleDelete={handleImage10Delete}
                                                                onChange={(e) => handleTengthFileUpload(e)}
                                                            />
                                                        ) : (
                                                            <DragAndDropFileUpload
                                                                isDaksh={isDaksh}
                                                                thumbnail={details.thumbnail}
                                                                thumbnailId={10}
                                                                handleSetThumbnail={handleSetThumbnail}
                                                                url={details.imageURL10}
                                                                handleDelete={handleImage10Delete}
                                                                flag={id}
                                                                number="10"
                                                                onChange={(e) => handleTengthFileUpload(e)}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="col-xl-8 col-sm-12">
                                                        <div className="small text-muted">Video url</div>
                                                        <input
                                                            type="text"
                                                            id=""
                                                            className="form-control"
                                                            disabled={!isDaksh}
                                                            value={details.videoURL}
                                                            onChange={(e) =>
                                                                setDetails({
                                                                    ...details,
                                                                    videoURL: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ----------------------------------------------------- */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-12">
                                <div className="card card4">
                                    <div className="card-header">
                                        <h6 className="card-title m-0">Upload Product Image / Video </h6>
                                        <a className="minimize">
                                            <i
                                                className="fa fa-plus text-primary"
                                                onClick={expandableUploadProductImage}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}
                        {expandBasePrice === false ? (
                            <div className="col-12">
                                <div className="card card3">
                                    <div className="card-header">
                                        <h6 className="card-title m-0">Price Information Base </h6>
                                        <a className="minimize">
                                            <i
                                                className="fa fa-minus text-primary"
                                                onClick={expandableBasePrice}
                                            />
                                        </a>
                                    </div>
                                    <div className="card-body card-body3 pt-0">
                                        <div className="row">
                                            {isDaksh ? (
                                                <>
                                                    <div className="col-lg-2 col-6">
                                                        <label className="form-label pt-3">
                                                            Base Price <sup className="text-danger">*</sup>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            disabled={!isDaksh}
                                                            className="form-control form-control"
                                                            placeholder={0}
                                                            value={details.basePrice}
                                                            onChange={(e) => {
                                                                setDetails({
                                                                    ...details,
                                                                    basePrice: e.target.value,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-lg-2 col-6">
                                                        <label className="form-label pt-3">GST %</label>
                                                        <input
                                                            type="number"
                                                            className="form-control form-control"
                                                            placeholder="0%"
                                                            max={100}
                                                            min={0}
                                                            value={details.gst}
                                                            onChange={(e) =>
                                                                setDetails({
                                                                    ...details,
                                                                    gst: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-lg-2 col-6">
                                                        <label className="form-label pt-3">Transportation{" % "}</label>
                                                        <input
                                                            type="number"
                                                            className="form-control form-control"
                                                            max={100}
                                                            min={0}
                                                            minLength={0}
                                                            placeholder="0%"
                                                            value={details.transportation}
                                                            onChange={(e) =>
                                                                setDetails({
                                                                    ...details,
                                                                    transportation: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-lg-2 col-6">
                                                        <label className="form-label pt-3">Discount %</label>
                                                        <input
                                                            type="number"
                                                            className="form-control form-control"
                                                            placeholder="0%"
                                                            max={100}
                                                            min={0}
                                                            value={details.discount}
                                                            onChange={(e) =>
                                                                setDetails({
                                                                    ...details,
                                                                    discount: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-lg-2 col-6">
                                                        <label className="form-label pt-3">Total Buying </label>
                                                        <input
                                                            type="number"
                                                            className="form-control form-control"
                                                            readOnly
                                                            value={details.totalBuying}
                                                            placeholder="Total Buying"
                                                            style={{ cursor: "not-allowed" }}
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="col-lg-2 col-6">
                                                        <label className="form-label pt-3">Base Price</label>
                                                        <input
                                                            type="number"
                                                            className="form-control form-control"
                                                            placeholder="0%"
                                                            value={sellerPriceInfo?.baseRate}
                                                            disabled
                                                        />
                                                    </div>

                                                    <div className="col-lg-2 col-6">
                                                        <label className="form-label pt-3">Margin (%)</label>
                                                        <input
                                                            min="0"
                                                            type="number"
                                                            className="form-control form-control"
                                                            placeholder="0%"
                                                            value={sellerPriceInfo?.sellerMargin}
                                                            onChange={(e) => handleSellerMarginChange(e)}
                                                        />
                                                    </div>
                                                    <div className="col-lg-2 col-6">
                                                        <label className="form-label pt-3">Selling Price</label>
                                                        <input
                                                            min="0"
                                                            type="number"
                                                            className="form-control form-control"
                                                            placeholder="0%"
                                                            value={sellerPriceInfo?.sellerPrice}
                                                            onChange={(e) => handleSellerPriceChange(e)}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-12">
                                <div className="card card4">
                                    <div className="card-header">
                                        <h6 className="card-title m-0">Price Information Base </h6>
                                        <a className="minimize">
                                            <i
                                                className="fa fa-plus text-primary"
                                                onClick={expandableBasePrice}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}

                        {expand === false && isDaksh ? (
                            <div className="col-12">
                                <div className="card card4">
                                    <div className="card-header">
                                        <h6 className="card-title m-0">Price Information Citywise </h6>
                                        <a className="minimize">
                                            <i
                                                className="fa fa-minus text-primary"
                                                onClick={expandable}
                                            />
                                        </a>
                                    </div>
                                    <div className="card-body card-body4">
                                        <div className="table-responsive">
                                            <table
                                                id="myDataTable"
                                                style={{
                                                    width: "100%",
                                                    borderSpacing: "0 !important",
                                                }}
                                                className="myDataTable table align-middle table-bordered mb-0 card-table dataTable citiwise-table">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            width="20%"
                                                            className="border-top-0 "
                                                            rowSpan={2}
                                                            style={{ verticalAlign: "baseline" }}>
                                                            City
                                                        </th>
                                                        <th
                                                            className="border-top-0 wholesale text-center"
                                                            colSpan={3}>
                                                            Wholesaler
                                                        </th>
                                                        <th
                                                            className="border-top-0 nursery  text-center"
                                                            colSpan={3}>
                                                            Nursery
                                                        </th>
                                                        <th
                                                            className="border-top-0 godown  text-center"
                                                            colSpan={3}>
                                                            Factory Outlet
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Active</th>
                                                        <th width="15%">Margin</th>
                                                        <th width="12%">Price</th>
                                                        <th>Active</th>
                                                        <th width="15%">Margin</th>
                                                        <th width="12%">Price</th>
                                                        <th>Active</th>
                                                        <th width="15%">Margin</th>
                                                        <th width="12%">Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {priceInformation?.map((data) => (
                                                        <tr>
                                                            <td>{data.cityName}</td>
                                                            <td>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={data.isWholesaleActive}
                                                                    onChange={(e) => {
                                                                        wholeSaleCheckBoxHandler(data.cityId, e.target.checked);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <div className="input-group">
                                                                    <input
                                                                        type="number"
                                                                        disabled={!data.isWholesaleActive}
                                                                        className="form-control"
                                                                        placeholder="0"
                                                                        value={data.wholesaleMargin}
                                                                        onChange={(e) => {
                                                                            wholeSaleMarginHandler(data.cityId, e.target.value);
                                                                        }}
                                                                    />
                                                                    <span
                                                                        className="input-group-text"
                                                                        id="basic-addon1">
                                                                        %
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td disabled={!data.isWholesaleActive}>
                                                                <span>{data.wholesalePrice}</span>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={data.isNurseryActive}
                                                                    onChange={(e) => nurserySaleCheckBoxHandler(data.cityId, e.target.checked)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <div className="input-group">
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        disabled={!data.isNurseryActive}
                                                                        placeholder="0"
                                                                        value={data.nurseryMargin}
                                                                        onChange={(e) => {
                                                                            nurserySaleMarginHandler(data.cityId, e.target.value);
                                                                        }}
                                                                    />
                                                                    <span
                                                                        className="input-group-text"
                                                                        id="basic-addon1">
                                                                        %
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td disabled={!data.isNurseryActive}>
                                                                <span>{data.nurseryPrice}</span>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={data.isFactoryOutletActive}
                                                                    onChange={(e) => factorySaleCheckBoxHandler(data.cityId, e.target.checked)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <div className="input-group">
                                                                    <input
                                                                        type="number"
                                                                        disabled={!data.isFactoryOutletActive}
                                                                        placeholder="0"
                                                                        className="form-control"
                                                                        value={data.factoryOutletMargin}
                                                                        onChange={(e) => {
                                                                            factorySaleMarginHandler(data.cityId, e.target.value);
                                                                        }}
                                                                    />
                                                                    <span
                                                                        className="input-group-text"
                                                                        id="basic-addon1">
                                                                        %
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td disabled={!data.isFactoryOutletActive}>{data.factoryOutletPrice}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-12">
                                {isDaksh ? (
                                    <div className="card card4">
                                        <div className="card-header">
                                            <h6 className="card-title m-0">Price Information Citywise </h6>
                                            <a className="minimize">
                                                <i
                                                    className="fa fa-plus text-primary"
                                                    onClick={expandable}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        )}

                        <div className="col-12">
                            <div className="card-footer">
                                {isDaksh ? (
                                    <>
                                        <button
                                            type="button"
                                            className="btn btn-outline-warning text-dark me-2"
                                            number="1"
                                            disabled={isLoading}
                                            onClick={(e) => handleSave(e.target.id)}>
                                            Save & Add New
                                        </button>

                                        <button
                                            type="button"
                                            className="btn btn-warning"
                                            id="0"
                                            onClick={() => handleSave()}
                                            disabled={isLoading}>
                                            Save
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-warning"
                                        id="0"
                                        onClick={() => handleSave()}
                                        disabled={isLoading}>
                                        Save
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
