import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

export default function Example({ show, handleContinue }) {
    return (
        <>
            <Modal centered={true} animation={true} show={show} onHide={handleContinue}>
                <div className="modal fade show" id="deletextra" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: "block" }}>
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body p-3 py-5">
                                <h4>Success</h4>
                                <p className="text-muted small mb-4">Product Deleted Successfully.</p>
                                <center>
                                    <a className="btn btn-success" data-bs-dismiss="modal" aria-label="Close" onClick={handleContinue}>
                                        Continue
                                    </a>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
