import { GetCityUserType } from "../../services/customerService";

const CheckValidUser = async(cityName,userType) => {
  try {
        const response = await GetCityUserType(cityName, userType);
        if (response.data.statusCode == 200) {
            localStorage.setItem("cityName", cityName);
            localStorage.setItem("userTypeName", userType);
            localStorage.setItem("cityId", response.data.result.cityId); 
            localStorage.setItem("userTypeId", response.data.result.userTypeId); 
            localStorage.setItem("headerCityName", response.data.result.cityName); 
            localStorage.setItem("headerUserType", response.data.result.userTypeName); 
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log("error", error);
        return false;
    }
  };

export {CheckValidUser};