import React, { useEffect, useState } from "react";
import { getOrdersCounter } from "../../services/orderService";

const OrderSummary = ({sellerId}) => {
  const [orderCount, setOrderCount] = useState();

  const orderCounters = async () => {
    const response = await getOrdersCounter(sellerId);
    setOrderCount(response);
  };

  useEffect(() => {
    orderCounters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="col-12">
        <div className="row  cols-xl-6 row-cols-lg-12 row-cols-md-3 row-cols-sm-2 row-cols-1 g-2 mb-2 dashboard-widgets">
          <div className="col">
            <div className="card p-3">
              <span>
                <div className="card-body text-center p-0">
                  <div className="fs-3 text-success">
                    {orderCount?.ordersReceived}
                  </div>
                  <span className="text-yellow pb-1">Orders Received</span>
                </div>
              </span>
            </div>
          </div>
          <div className="col">
            <div className="card p-3">
              <span>
                <div className="card-body text-center p-0">
                  <div className="fs-3 text-success">{orderCount?.openOrders}</div>
                  <span className="text-yellow pb-1">Open Orders</span>
                </div>
              </span>
            </div>
          </div>
          <div className="col">
            <div className="card p-3">
              <span>
                <div className="card-body text-center p-0">
                  <div className="fs-3 text-success">
                    {orderCount?.ordersDispatched}
                  </div>
                  <span className="text-yellow pb-1">Orders Delivered</span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSummary;
