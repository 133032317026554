/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { handleLogin } from "../../services/LoginService/login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../components/Form/Form.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Loader from "../Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "../../redux/features/authSlice";
import jwt_decode from "jwt-decode";
import checkPageUrl from "../../helpers";
import ApiConstants from "../../Constants/ApiConstants";
import { getVendorDetailApi } from "../../redux/features/vendorSlice";

export default function Login() {
    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isClicked, setIsClicked] = useState(false);
    const [isChecked, setIsCheked] = useState(false);
     let currentUrl = window.location.hostname;
    //  currentUrl = process.env.REACT_APP_DOMAIN;
    let credential = { email, password, url: currentUrl.replace("www.", "") };
    const [flagForPage, setFlagForPage] = useState();
    const dispatch = useDispatch();

    let urlFlag = checkPageUrl(currentUrl);
    if (urlFlag) {
        currentUrl = ApiConstants.DOMAIN_URL5;
    }
    useEffect(() => {
        dispatch(getVendorDetailApi(currentUrl));
        setFlagForPage(urlFlag);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentUrl]);

    const content = useSelector((state) => state.vendor.vendorDetail);

    const handleCheckbox = () => {
        setIsCheked(!isChecked);
        if (!isChecked === true) {
            //setIsCheked(true);
            Cookies.set("email", email, { expires: 7 }); // Expires in 7 days
            Cookies.set("password", password, { expires: 7 });
        } else {
            //setEmail("");
            //setPassword("");
            Cookies.remove("email");
            Cookies.remove("password");
        }
    };

    const handleIsClicked = () => {
        setIsClicked(false);
    };

    useEffect(() => {
        const email = Cookies.get("email");
        const password = Cookies.get("password");
        if (email && password) {
            //
            // Log the user in with stored credentials
            setEmail(email);
            setPassword(password);
            setIsCheked(true);
        }
    }, []);

    useEffect(() => {
        setTimeout(handleIsClicked, 10000);
    }, [isClicked]);

    const proceedLogin = async (e) => {
        setIsLoader(true);
        e.preventDefault();
        setIsClicked(true);
        let validator = await validate();
        if (validator.length === 0) {
            let response = await handleLogin(credential);
            if (response.success === true) {
                localStorage.setItem("token", response.obj.jwtToken);
                localStorage.setItem("isDaksh", response.obj.isDaksh);
                const decode = jwt_decode(response.obj.jwtToken);
                localStorage.setItem("sellerId", decode.sellerId);
                dispatch(
                    setLogin({
                        isDaksh: response?.obj?.isDaksh,
                        sellerId: decode?.sellerId,
                    })
                );
                if (response?.obj?.isDaksh) {
                    navigate("/admin/dashboard");
                } else if (response?.obj?.isDaksh === false) {
                    navigate("/seller-admin/order-management");
                }
            } else {
                toast.error("Invalid Username or Password");
                // navigate("/signin");
            }
        } else {
            validator.map((data) => {
                toast.error(data.ErrorMessage);
            });
            //   toast.error("Invalid credentials");
        }
        setIsLoader(false);
    };
    const validate = async () => {
        let result = [];
        let validEmail = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
        if (email.trim().length === 0) {
            result.push({ ErrorMessage: "Please Enter Email" });
        } else if (!validEmail.test(email)) {
            console.log(validEmail.test(email));
            result.push({ ErrorMessage: "Please Enter Valid Email" });
        }
        if (password === "" || password === "null") {
            result.push({ ErrorMessage: "Please Enter Password" });
        }

        return result;
    };

    return (
        <>
            {/* start: body area */}
            <div className="wrapper">
                {isLoader && <Loader />}
                {/* Sign In version 1 */}
                {/* start: page body */}
                <div className="page-body auth px-xl-4 px-sm-2 px-0 py-lg-2 py-1">
                    <div className="container-fluid">
                        <div className="row g-0">
                            <div className="col-lg-6 col-md-8 d-lg-flex justify-content-center align-items-center">
                                <div style={{ maxWidth: "25rem" }}>
                                    <div className="mb-4">
                                        {/* ---------------------- DI Logo -------------------------- */}
                                        <img
                                            src={content?.footerLogo}
                                            alt="daksh_india_logo"
                                            width="100%"
                                        />
                                    </div>
                                    {/* List Checked */}
                                    <ul className="list-unstyled mb-5">
                                        <li className="mb-4">
                                            <span className="color-600">Amazing products to give your gardening life beauty, comfort &amp; satisfaction</span>
                                        </li>
                                        <li>
                                            <span className="d-block mb-1 fs-4 fw-light">Easily add &amp; manage your products and orders</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex justify-content-center align-items-center">
                                <ToastContainer />
                                <div
                                    className="card shadow-sm  p-4 p-md-5 mt-5"
                                    style={{ width: "32rem" }}>
                                    {/* <!-- Form --> */}
                                    <form className="row g-3">
                                        <div className="col-12 text-center mb-5">
                                            <h1>Sign in</h1>
                                            <span className="text-muted">Access Dashboard</span>
                                        </div>

                                        <div className="col-12">
                                            <div className="mb-2">
                                                <label className="form-label">Email address</label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-lg"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="name@example.com"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="mb-2">
                                                <div className="form-label">
                                                    <span className="d-flex justify-content-between align-items-center"> Password</span>
                                                </div>
                                                <input
                                                    id="password"
                                                    className="form-control form-control-lg"
                                                    type="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    maxLength={10}
                                                    placeholder="Enter the password"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    id="flexCheckDefault"
                                                    onChange={handleCheckbox}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckDefault">
                                                    {" "}
                                                    Remember me{" "}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center mt-4">
                                            <button
                                                className="btn btn-block btn-primary lift text-uppercase"
                                                id="SignIn"
                                                title=""
                                                onClick={proceedLogin}
                                                disabled={isClicked}>
                                                SIGN IN
                                            </button>
                                        </div>
                                    </form>
                                    {/* <!-- End Form --> */}
                                </div>
                            </div>
                        </div>{" "}
                        {/* End Row */}
                    </div>
                </div>
            </div>
        </>
    );
}
