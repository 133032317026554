import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import Loader from "../Loader";
import "./GlobalSearchSuggestion.css"

function GlobalSearchSuggestion({ data, cityName, userType, searchText, isLoader, handleClick, urlFlag, content }) {
    const [containerWidth, setContainerWidth] = useState({ height: "650px" });
    // useEffect(() => {
    //     data?.products?.length > 3 ? setContainerWidth({ height: "650px" }) : setContainerWidth({ height: "400px" });
    // }, [data?.products]);
    return (
        <>
            <div className="header-search-suggestion ">
                <div className="search-suggestion-heading px-2 pt-2">Products</div>
                <div className="line-div"></div>
                <div className="search-suggestion-products">
                    {data?.length > 0 ?
                        (data?.map((item, index) => (

                            <Link
                                onClick={() => {
                                    urlFlag && content?.sellerId ? window.open(`/${cityName}/${userType}/product-details/${item.id}`, "_blank") : window.open(`/product-details/${item.id}`, "_blank");
                                }}>
                                <div key={index} className="px-3">

                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 mt-1 p-0">
                                            <img src={item.thumbnail} alt="product" className="px-2" />
                                        </div>
                                        <div className="col-lg-9 col-md-9 col-sm-9 col-9 mt-1 ps-1">
                                            <div className="row">
                                                <div className=" col-lg-12 col-md-12 col-sm-12 col-12 product-name">{item.productName}</div>
                                                <div className="d-flex align-items-center">
                                                    {
                                                        item.discount > 0 ? (
                                                            <>
                                                                <div className="discount ">Save {item.discount}%</div>
                                                                <div className="price p-1 px-2">₹ {item.actualPrice}</div>
                                                                <div className="discounted-price px-2 p-1">₹ {item.price}</div>

                                                            </>
                                                        ) : (
                                                            <div className="discounted-price pe-2">₹ {item.price}</div>
                                                        )
                                                    }
                                                    <div className="per-peice px-0 p-1">/per piece</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </div>
                            </Link>

                        ))) : (
                             <p className="text-center No-Product" >No results for {searchText}</p>
                        )
                    }
                </div>
                <div className="line-div"></div>
                {
                    data.length > 0 ? (
                        <div className="search-suggestion-bottom-heading text-center pb-1" onClick={() => handleClick(searchText)}>View All</div>

                    ) : (
                        null
                    )
                }
            </div>

        </>
    );
}

export default GlobalSearchSuggestion;
