import { Carousel } from 'react-bootstrap';
import './DailyOrderManagement.css'
import { useEffect, useRef, useState } from 'react';
import { GetDailyOrderManagement } from '../../services/orderService';
import { darken } from 'polished';

export const DailyOrderManagement = () => {


    const [isPlaying, setIsPlaying] = useState(true);

    const handlePlayPauseToggle = () => {
        setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    };

    const [data, setData] = useState();
    const getDailyOrder = async () => {
        try {
            const response = await GetDailyOrderManagement();
            setData(prevData => {
                return response?.result;
            });

        } catch (error) {
            console.warn("Something went wrong", error.message);
        }
    };

    useEffect(() => {
        getDailyOrder();
        const intervalId = setInterval(() => {
            getDailyOrder();
            window.location.reload();
        }, 15 * 60 * 1000);
        return () => clearInterval(intervalId);
    }, []);



    const originalArray = data?.orderAssignmentsResponse;

    const chunkSize = 6;

    const [splitArrays, setSplitArrays] = useState([]);

    useEffect(() => {
        const splitArray = [];


        for (let i = 0; i < originalArray?.length; i += chunkSize) {
            const chunk = originalArray.slice(i, i + chunkSize);
            splitArray.push(chunk);
        }
        setSplitArrays(splitArray);

    }, [originalArray, chunkSize]);

    return (
        <>
            <section className='main-content-wrapper cart daily-order dom-layout-2' >
                <div className='container-fluid'>
                    <div className="row g-1 row-deck">

                        <div className="col-12 mt-2 mb-0">
                            <div className='card'>
                                {<div className='dom-card-header pt-2 pb-1'>
                                    <h6 className="dom-card-title m-0 pt-0">DAILY ORDER MANAGEMENT</h6>
                                    <img src="../../assets/img/daksh-logo-dark.png" alt='Daksh India' width="12%" ></img>
                                </div>
                                }
                                <div className="card-body">
                                    <div className='table-responsive custom_scroll-x'>
                                        <table id='inventory_list' className="dom-table card-table align-middle mb-0">
                                            <thead className='custom-border-color'>

                                                <tr className='custom-border-color'>
                                                    {data?.orderMgtStatusDetailsResponse.map((statusDetails, index) => (

                                                        <th width="10%" className="border-top-0 custom-border-color">
                                                            <div className='d-flex align-items-center'>
                                                                <div className='flex-fill'>
                                                                    <div className="dom-order-heading">{statusDetails.status}</div>
                                                                    <span>
                                                                        <span className='dom-order-heading'>ON: </span>
                                                                        <span className="h5 mb-0 text-primary order-number me-2">{statusDetails.onlineOrders}</span>
                                                                        <span className="dom-order-heading ms-2">OFF: </span>
                                                                        <span className="h5 mb-0 text-primary order-number">{statusDetails.offlineOrders}</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </th>

                                                    ))}

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {data?.orderMgtOrderStatusReponse.map((item) => (
                                                        <td className="border-bottom-0 ">

                                                            <div className=' order-number-list1'>
                                                                <div className={item.orderDetailsJSON.length > 6 ? 'marquee-inner marquee-scroll' : ''}>
                                                                    {item?.orderDetailsJSON.map((innerItem) => (
                                                                        <span className="badge spanClass" style={{ backgroundColor: innerItem.assignedColor, color: darken(0.4, innerItem.assignedColor), border: '2px solid ' + darken(0.4, innerItem.assignedColor), fontFamily: "nunito,sans-serif !important" }}>{innerItem.orderNo}</span>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    ))}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className='card'>
                                <div className='dom-card-header pt-2 pb-1 d-flex justify-content-between'>
                                    <h6 className="dom-card-title m-0">STAFF</h6>

                                    <i onClick={handlePlayPauseToggle} className={`fa ${isPlaying ? 'fa-pause' : 'fa-play'} play-pause-action `}></i>

                                </div>
                                <div className='card-body'>
                                    <Carousel interval={isPlaying ? 10000 : null}>
                                        {
                                            splitArrays?.map((array, index) => (
                                                <Carousel.Item  >
                                                    <div className='table-responsive custom_scroll-x'>
                                                        <table className='dom-table align-middle mb-0 card-table' id='order_list' style={{ borderCollapse: "collapse !important" }}>
                                                            <thead>
                                                                <tr >
                                                                    {array?.map((assignment, index) => (
                                                                        <th key={index} width="10%" style={{ backgroundColor: assignment.color, color: darken(0.4, assignment.color), border: '2px solid ' + darken(0.2, assignment.color) + ' !important', borderCollapse: "collapse !important" }}>
                                                                            <div className='flex-fill'>
                                                                                <div className='dom-order-heading d-flex justify-content-between' >
                                                                                    {assignment.staffName}
                                                                                    <span className='h5 mb-0 order-number1' >({assignment.ordersAssigned})</span>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    {array?.map((assignment, index) => (
                                                                        <td className='border-bottom-0' style={{ border: "10px solid #000000 !important", borderCollapse: "collapse !important" }}>
                                                                            <div className='dom-table-2 order-list-numder1'>

                                                                                <div className={assignment?.orderAssignmentJSON.length > 3 ? 'marquee-inner' : ''}>

                                                                                    {assignment?.orderAssignmentJSON.map((innerAssignment, index) => (

                                                                                        <span class="badge spanClass" style={{ backgroundColor: assignment.color, color: darken(0.4, assignment.color), border: '2px solid ' + darken(0.2, assignment.color) }}  >{innerAssignment.orderId}</span>
                                                                                    ))}

                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    ))}

                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </Carousel.Item>
                                            ))
                                        }
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>
        </>
    )
}



