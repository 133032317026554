import React from 'react'
import { FaStar,FaStarHalfAlt } from 'react-icons/fa';
import {AiOutlineStar} from "react-icons/ai";
import styled from "styled-components"
export default function Star({stars}) {
    const ratingStar = Array.from({length:5},(element,index)=>{
        let number = index+0.5;
    
  return <span key={index}>
  {
   stars>=index + 1 ? <FaStar className="icon"/> : stars>= number ? <FaStarHalfAlt className="icon"/> : <AiOutlineStar className="icon"/>
  }
   </span> 
    })
    return (
        <Wrapper>
           <div className='icon-style' style={{float:"left", marginTop:"-25px"}}>
            {ratingStar}
           </div>
        </Wrapper>
    
    )
};

const Wrapper = styled.section`
 .icon-style {
    display : flex;
    gap : 0.2 rem;
    align-item: center;
    justify-content:center;

    .icon{
        font-size:1rem;
        color:orange;
    }
    .empty-icon{
        font-size:2.6rem
    }
 };`
