/* eslint-disable eqeqeq */
export const productAddValidation = (name, category, popularity, MOQ, basePrice, gst, materialId, shapeId, size, vendor, image1, image2, image3, image4, image5, image6, image7, image8, image9, image10 ,thumbnail, productCode, url1, url2, url3, url4, id) => {
    const ValidationErrors = [];
    let regName = new RegExp("^[A-Za-z0-9]");
    let regNum = new RegExp("^[0-9]{1,}");
    if (category == 0 || category == "") {
        ValidationErrors.push({ ErrorField: "Category", ErrorMessage: "Category should not be empty" });
    }
    if (vendor == 0 || vendor == "") {
        ValidationErrors.push({ ErrorField: "Vendor", ErrorMessage: "Vendor should not be empty" });
    }
    if (productCode === "" || productCode.length > 8) {
        ValidationErrors.push({ ErrorField: "ProductCode", ErrorMessage: "Product Code cannot be of more than 8 characters" });
    }
    if (!regName.test(name) || name === "") {
        ValidationErrors.push({ ErrorField: "Name", ErrorMessage: "Name should not be empty" });
    }
    if (!regNum.test(MOQ) || MOQ === "" || MOQ.length > 4) {
        ValidationErrors.push({ ErrorField: "Minimum Order Quantity", ErrorMessage: "Quantity should not be empty " });
    }
    if (!regNum.test(basePrice) || basePrice === "" || basePrice.length > 5) {
        ValidationErrors.push({ ErrorField: "BasePrice", ErrorMessage: "Base Price should not be empty" });
    }
    // if (!regNum.test(gst) || gst === "" || gst < 0) {
    //     ValidationErrors.push({ ErrorField: "GST", ErrorMessage: "GST should not be empty" });
    // }
    if (!regNum.test(popularity) || popularity === "" || popularity === 0) {
        ValidationErrors.push({ ErrorField: "Ratings", ErrorMessage: "Ratings should not be empty" });
    }
    if (!regNum.test(materialId) || materialId === "" || materialId === 0) {
        ValidationErrors.push({ ErrorField: "Material", ErrorMessage: "Material should not be empty" });
    }
    if (!regNum.test(shapeId) || shapeId === "" || shapeId === 0) {
        ValidationErrors.push({ ErrorField: "Shape", ErrorMessage: "Shape should not be empty" });
    }
    if (!regNum.test(size) || size === "" || size === 0) {
        ValidationErrors.push({ ErrorField: "Size", ErrorMessage: "Size should not be empty" });
    }
    if (image1 === null || image1 === "") {
        ValidationErrors.push({ ErrorField: "Image", ErrorMessage: "Image 1 is mandatory" });
    }
    let thumbnailError = [];
    if (id === undefined) {
        thumbnailError = thumbnailValidation(image1, image2, image3, image4, image5, image6, image7, image8, image9, image10 ,thumbnail);
    }
    if (thumbnailError.length != 0) {
        ValidationErrors.push(...thumbnailError);
    }
    console.log(ValidationErrors);
    return ValidationErrors;
};

export const updateProductAddValidation = (name, category, popularity, MOQ, basePrice, gst, materialId, shapeId, size, vendor, image1, image2, image3, image4, image5,image6,image7,image8,image9,image10,thumbnail, productCode, url1, url2, url3, url4, id) => {
    // // 
    const ValidationErrors = [];
    let regName = new RegExp("^[A-Za-z]");
    let regNum = new RegExp("^[0-9]{1,}");
    if (category == 0 || category == "") {
        ValidationErrors.push({ ErrorField: "Category", ErrorMessage: "Category should not be empty" });
    }
    if (vendor == 0 || vendor == "") {
        ValidationErrors.push({ ErrorField: "Vendor", ErrorMessage: "Vendor should not be empty" });
    }
    // if (productCode === "" || productCode.length > 8) {
    //     ValidationErrors.push({ ErrorField: "ProductCode", ErrorMessage: "Product Code cannot be of more than 8 characters" });
    // }
    if (!regName.test(name) || name === "") {
        ValidationErrors.push({ ErrorField: "Name", ErrorMessage: "Name should not be empty" });
    }
    if (!regNum.test(MOQ) || MOQ === "" || MOQ.length > 4) {
        ValidationErrors.push({ ErrorField: "Minimum Order Quantity", ErrorMessage: "Quantity should not be empty " });
    }
    if (!regNum.test(basePrice) || basePrice === "" || basePrice.length > 5) {
        ValidationErrors.push({ ErrorField: "BasePrice", ErrorMessage: "Base Price should not be empty" });
    }
    if (!regNum.test(popularity) || popularity === "" || popularity === 0) {
        ValidationErrors.push({ ErrorField: "Ratings", ErrorMessage: "Ratings should not be empty" });
    }
    if (!regNum.test(materialId) || materialId === "" || materialId === 0) {
        ValidationErrors.push({ ErrorField: "Material", ErrorMessage: "Material should not be empty" });
    }
    if (!regNum.test(shapeId) || shapeId === "" || shapeId === 0) {
        ValidationErrors.push({ ErrorField: "Shape", ErrorMessage: "Shape should not be empty" });
    }
    if (!regNum.test(size) || size === "" || size === 0) {
        ValidationErrors.push({ ErrorField: "Size", ErrorMessage: "Size should not be empty" });
    }
    if ((image1 === null || image1 === "") & (url1 === undefined || url1 === "")) {
        ValidationErrors.push({ ErrorField: "Image", ErrorMessage: "Image 1 is mandatory" });
    }
    let thumbnailError = [];
    if (id === undefined) {
        thumbnailError = thumbnailValidation(image1, image2, image3, image4, image5,image6,image7,image8,image9,image10,thumbnail);
    }
    if (thumbnailError.length != 0) {
        ValidationErrors.push(...thumbnailError);
    }
    console.log(ValidationErrors);
    return ValidationErrors;
};

export const thumbnailValidation = (image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, thumbnail) => {
    // // 
    const thumbnailValidationErrors = [];
    if ((image1 === "") & (thumbnail === 1)) {
        // if(url1)
        thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
    }
    if ((image2 === "") & (thumbnail === 2)) {
        thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
    }
    if ((image3 === "") & (thumbnail === 3)) {
        thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
    }
    if ((image4 === "") & (thumbnail === 4)) {
        thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
    }
    if ((image5 === "") & (thumbnail === 5)) {
        thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
    }
    if ((image6=== "") & (thumbnail === 6)) {
        thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
    }
    if ((image7 === "") & (thumbnail === 7)) {
        thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
    }
    if ((image8 === "") & (thumbnail === 8)) {
        thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
    }
    if ((image9 === "") & (thumbnail === 9)) {
        thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
    }
    if ((image10 === "") & (thumbnail === 10)) {
        thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
    }
    if (thumbnail === 0 || thumbnail === null) {
        thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Thumbnail should not be empty" });
    }
    return thumbnailValidationErrors;
};

export const updateProductThumbnailValidation = (image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, thumbnail) => {
    // 
    const thumbnailValidationErrors = [];
    if (thumbnail === 1) {
        if (image1 === null ) {
            // 
            thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
        }
    }
    if (thumbnail === 2) {
        if (image2 === null) {
            thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
        }
    }
    if (thumbnail === 3) {
        if (image3 === null) {
            thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
        }
    }
    if (thumbnail === 4) {
        if (image4 === null) {
            thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
        }
    }

    if (thumbnail === 5) {
        if (image5 === null) {
            thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
        }
    }
    if (thumbnail === 6) {
        if (image6 === null) {
            thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
        }
    }
    if (thumbnail === 7) {
        if (image7 === null) {
            thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
        }
    }
    if (thumbnail === 8) {
        if (image8 === null) {
            thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
        }
    }
    if (thumbnail === 9) {
        if (image9 === null) {
            thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
        }
    }
    if (thumbnail === 10) {
        if (image10 === null) {
            thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Image not found to set as thumbnail" });
        }
    }

    if (thumbnail === 0 || thumbnail === null) {
        thumbnailValidationErrors.push({ ErrorField: "Thumbnail", ErrorMessage: "Thumbnail should not be empty" });
    }
    return thumbnailValidationErrors;
};
