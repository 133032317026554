/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "../Product/Product.css";
import Header from "../Common/Header/Header";
import { useParams, useNavigate } from "react-router";
import { CheckValidUser } from "../Common/CheckForValidUser";
import checkPageUrl from "../../helpers";
import { useSelector } from "react-redux";

export default function Aboutus() {
  // const [isLoader, setIsLoader] = useState(false);
  // const [item, setItem] = useState();
  // const { cityName, userType } = useParams();
  // const [validUser, setValidUser] = useState(false);
  // const [headerCityName, setHeaderCityName] = useState("");
  // const [headerUserType, setHeaderUserType] = useState("");
  // const [cityId, setCityId] = useState();
  // const [userTypeId, setUserTypeId] = useState();
  // const [searchText, setSearchText] = useState("");
  // let urlFlag = checkPageUrl(window.location.hostname);
  // const [flagForPage, setFlagForPage] = useState(urlFlag);
  // const navigate = useNavigate();
  // useEffect(() => {
  //     if (flagForPage === true) {
  //         checkForValidUser();
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const content = useSelector((state) => state.vendor.vendorDetail);

  // const checkForValidUser = async () => {

  //     setIsLoader(true);
  //     const checkValidUser = await CheckValidUser(cityName, userType);
  //     // if (urlFlag && !checkValidUser){
  //     //     navigate("/AboutUs");
  //     //   }
  //     setValidUser(checkValidUser);

  //     if (!checkValidUser) {
  //         navigate("/about-us");
  //     } else {
  //         setCityId(localStorage.getItem("cityId"));
  //         setUserTypeId(localStorage.getItem("userTypeId"));
  //         setHeaderCityName(localStorage.getItem("headerCityName"));
  //         setHeaderUserType(localStorage.getItem("headerUserType"));
  //     }
  //     setIsLoader(false);
  // };

  // const handleClick = () => {
  //     setIsLoader(true);
  //     if (searchText.trim().length !== 0) {
  //         if (urlFlag && content?.sellerId) {
  //             navigate(`/${cityName}/${userType}/category?searchText=${searchText}`, {
  //                 state: {
  //                     searchText: searchText,
  //                     isGlobalSearchNavigation: true,
  //                 },
  //             });
  //         } else if (!urlFlag && content?.sellerId) {
  //             navigate(`/category?searchText=${searchText}`, {
  //                 state: {
  //                     searchText: searchText,
  //                     isGlobalSearchNavigation: true,
  //                 },
  //             });
  //         }
  //     }
  //     setIsLoader(false);
  // };

  // const handleChange = (e) => {
  //     setSearchText(e.target.value);
  // };

  window.scrollTo(0, 0); // Scrolls to the top of the page

  return (
    <>
      {/* <Header
                item={item}
                searchText={searchText}
                cityName={cityName}
                userType={userType}
                headerCityName={headerCityName}
                headerUserType={headerUserType}
                handleClick={handleClick}
                handleChange={handleChange}
                validUser={validUser}
            /> */}
      <div className="container">
        <h1 className="mt-4 fs-3 fw-bolder mb-5 text-center">About Us</h1>
        <p className="mb-5">
          Welcome to Daksh India, where we transform spaces into lush, vibrant, and inviting environments. We are a passionate team of horticultural
          enthusiasts and design aficionados, dedicated to creating harmonious and aesthetically pleasing spaces through the power of plants and
          decoration.
        </p>

        <p className="mb-5">
          Our journey began with a simple idea: to bring nature indoors and enhance the beauty and functionality of homes, offices, and public spaces.
          We understand that greenery and well-thought-out decor can have a profound impact on our daily lives, influencing our moods, productivity,
          and overall well-being. With this belief at our core, we set out to provide unique, tailored solutions for all your plant and decoration
          needs.
        </p>

        <h2 className="mb-4">
          <strong>What Sets Us Apart:</strong>
        </h2>
        <ol className="">
          <li>
            Expertise: Our team consists of skilled horticulturists and interior designers who bring their knowledge and creativity to every project.
            Whether it's selecting the perfect plants for your space or curating exquisite decor items, our experts will guide you every step of the
            way.
          </li>

          <li>
            {" "}
            Quality and Variety: We take pride in offering an extensive range of high-quality plants, from lush tropical specimens to low-maintenance
            succulents. Our handpicked collection of decor items, from vases to art pieces, ensures that you have plenty of options to choose from.
          </li>

          <li>
            {" "}
            Customization: We understand that every space is unique, and so are your preferences. We work closely with you to tailor our offerings to
            suit your specific needs, whether it's for a minimalist urban apartment, a corporate office, or a lavish event.
          </li>

          <li>
            Sustainable Practices: We are committed to environmentally responsible practices. Our plants are sourced from sustainable growers, and we
            promote eco-friendly decor choices. We also offer guidance on plant care to help you maintain a thriving green space.
          </li>

          <li>
            {" "}
            Customer-Centric Approach: Your satisfaction is our top priority. We value your input and work diligently to exceed your expectations,
            ensuring that every project is a true reflection of your vision.
          </li>
        </ol>

        <h2 className="mt-5 mb-4">
          <strong>Our Services:</strong>
        </h2>
        <ol>
          <li>Elegant Planters</li>
          <li>Interior Plant Design and Installation</li>
          <li>Landscape Design and Maintenance</li>
          <li>Event Decoration and Rentals</li>
          <li>Plant Rental and Leasing</li>
          <li>Customized Decor Solutions</li>
          <li>Plant Care and Maintenance Consultation</li>
        </ol>

        <p className="mt-5 mb-4">
          Whether you're looking to revitalize your living space, create a more inviting office environment, or add a touch of natural elegance to
          your special event, Daksh India is here to bring your vision to life. We are your trusted partner in the world of plants and decoration.
        </p>

        <p className="mb-5">
          Contact us today to explore the possibilities and embark on a journey to a greener, more beautiful, and inspiring world. Welcome to a world
          of possibilities – welcome to Daksh India
        </p>
      </div>
    </>
  );
}
