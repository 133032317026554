import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { deleteSeller, getSellerOnboardingList } from "../services/sellerOnboardingService";
import { DatePicker } from "antd";
import { Link } from "react-router-dom";
import DeleteAskModal from "../components/Modal/DeleteAskModal";
import { ToastContainer } from "react-toastify";

const { RangePicker } = DatePicker;

function SellerManagement() {
    const [sellers, setSellers] = useState([]);
    const [initialSellers, setInitialSellers] = useState([]);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [firmName, setFirmName] = useState("");
    const [dateOfOnboarding, setDateOfOnboarding] = useState("");
    const [status, setStatus] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [sortField, setSortField] = useState("");
    const [toDate, setToDate] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [placeholder] = useState(["Start Date", "End Date"]);
    const [show, setShow] = useState(false);
    const [deleteSellerId, setDeleteSellerId] = useState();

    const handleSort = (field) => {
        if (field === sortField) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortField(field);
            setSortOrder("asc");
        }
        let sortedSellers = [...sellers];
        sortedSellers.sort((a, b) => {
            const valueA = a[field].toLowerCase();
            const valueB = b[field].toLowerCase();

            if (sortOrder === "asc") {
                return valueA.localeCompare(valueB);
            } else {
                return valueB.localeCompare(valueA);
            }
        });
        setSellers(sortedSellers);
    };

    const sellerList = async () => {
        try {
            const response = await getSellerOnboardingList(1, 100);
            if (response && response.sellerList) {
                setSellers(response.sellerList);
                setInitialSellers(response.sellerList);
            } else {
                console.error("Invalid response");
            }
        } catch (error) {
            console.error("Error fetching seller list:", error.message);
        }
    };

    useEffect(() => {
        sellerList();
    }, []);

    // const handleDelete = async (id) => {
    //   try {
    //     await deleteSeller(id);
    //     setSellers((prevSellers) =>
    //       prevSellers.filter((seller) => seller.id !== id)
    //     );
    //   } catch (error) {
    //     console.error("Error deleting seller:", error);
    //   }
    // };

    // const handleFilter = () => {
    //   const filteredSellers = initialSellers.filter((seller) => {
    //     const nameMatch = seller.name.toLowerCase().includes(name.toLowerCase());
    //     const phoneMatch = seller.phone.toString().includes(phone);
    //     const firmNameMatch = seller.firmName
    //       .toLowerCase()
    //       .includes(firmName.toLowerCase());
    //     const dateMatch = dateOfOnboarding
    //       ? seller.dateOfOnboarding === dateOfOnboarding
    //       : true;
    //     const statusMatch = status
    //       ? seller.status.toLowerCase() === status.toLowerCase()
    //       : true;

    //     return (
    //       nameMatch && phoneMatch && firmNameMatch && dateMatch && statusMatch
    //     );
    //   });

    //   setSellers(filteredSellers);
    // };

    const handleFilter = () => {
        const filteredSellers = initialSellers.filter((seller) => {
            const nameMatch = seller.name.toLowerCase().includes(name.toLowerCase());
            const phoneMatch = seller.phone.toString().includes(phone);
            const firmNameMatch = seller.firmName.toLowerCase().includes(firmName.toLowerCase());
            const dateMatch = fromDate && toDate ? new Date(seller.dateOfOnboarding) >= new Date(fromDate) && new Date(seller.dateOfOnboarding) <= new Date(toDate) : true;
            console.log("From Date:", fromDate);
            console.log("To Date:", toDate);

            const statusMatch = status ? parseInt(seller.status, 20) === parseInt(status, 20) : true;
            console.log("Status:", status);

            return nameMatch && phoneMatch && firmNameMatch && dateMatch && statusMatch;
        });

        setSellers(filteredSellers);
    };

    const handleDate = async (v) => {
        if (v != null) {
            let from = await formatDate(v[0].$d);
            let to = await formatDate(v[1].$d);
            setFromDate(from);
            setToDate(to);
        } else {
            setFromDate("");
            setToDate("");
        }
    };

    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }

    const handleClear = () => {
        setName("");
        setPhone("");
        setFirmName("");
        setDateOfOnboarding("");
        setStatus("");
        setSellers(initialSellers);
    };

    const handleDeleteModal = (id) => {
        setShow(true);
        setDeleteSellerId(id);
    };

    const DeleteSellerHandler = async (id) => {
        try {
            const response = await deleteSeller(id);
            if (response?.statusCode === 204) {
                return response;
            } else {
                console.error("Invalid response");
            }
        } catch (error) {
            console.error("Error deleting seller:", error);
        }
    };

    const handleModalClose = () => {
        setShow(false);
    };

    return (
        <>
            <ToastContainer />
            <DeleteAskModal
                show={show}
                handleClose={handleModalClose}
                apiCall={DeleteSellerHandler}
                id={deleteSellerId}
                productListHandler={sellerList}
            />

            <div className="row-title px-2 m-4">
                <div>
                    <h5 className="fw-bold">Seller List</h5>
                </div>
                {/* <Link to="/admin/seller-onboarding">
                    <button className="btn btn-outline-warning text-dark">
                        <i className="fa fa-plus"></i> Add New
                    </button>
                </Link> */}
            </div>

            <div className="card m-4">
                <div className="card-body">
                    <div className="row">
                        <div>
                            <div className="row">
                                <div className="col-lg-2 col-md-1 col-sm-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-3">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Phone No."
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Firm Name"
                                        value={firmName}
                                        onChange={(e) => setFirmName(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-2">
                                    <RangePicker
                                        placeholder={placeholder}
                                        dateFormat={"YYYY - MM - DD"}
                                        onChange={(v) => {
                                            handleDate(v);
                                        }}
                                    />
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-2">
                                    <select
                                        className="form-control form-select"
                                        tabindex="-98"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}>
                                        <option
                                            value="11"
                                            disabled={true}>
                                            -- Select Status --
                                        </option>
                                        <option value="">Active</option>
                                        <option value="20">Inactive</option>
                                    </select>
                                </div>

                                <div className="col-lg-2 col-md-2 col-sm-2 d-flex">
                                    <button
                                        className="btn btn-sm btn-light-success me-2"
                                        onClick={handleFilter}>
                                        Filter
                                    </button>
                                    <button
                                        className="btn btn-light-secondary btn-sm"
                                        onClick={handleClear}>
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-item-center bg-light vh-50 m-4">
                <div className="w-100 rounded bg-white border p-4">
                    <Table responsive="lg">
                        <thead>
                            <tr>
                                <th onClick={() => handleSort("name")}> Name</th>
                                <th>Phone No.</th>
                                <th onClick={() => handleSort("firmName")}>Firm Name</th>
                                <th onClick={() => handleSort("dateOfOnboarding")}>Date Of Onboarding</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sellers.map((seller, index) => (
                                <tr key={index}>
                                    <td>{seller?.name}</td>
                                    <td>{seller?.phone}</td>
                                    <td>{seller?.firmName}</td>
                                    <td>{seller?.dateOfOnboarding}</td>
                                    <td>{seller?.status}</td>
                                    <td className="d-flex">
                                        {/* <Link
                                            className="me-3"
                                            to={`/admin/seller-onboarding/${seller?.id}`}>
                                            <i className="fa fa-pencil text-teritary " />
                                        </Link> */}
                                        {/* <Link classname="me-3">
                                            <i
                                            className="fa fa-trash text-danger"
                                            onClick={() => handleDeleteModal(seller?.id)}
                                            style={{ cursor: "pointer" }}
                                            aria-hidden="true"></i>
                                        </Link> */}
                                        <center>
                                            <Link
                                                to={`/admin/seller-product-management/${seller?.id}`}
                                                state={{ sellerName: seller?.name }}
                                                classname="me-3">
                                                <i className="fa-solid fa-gear" />
                                            </Link>
                                        </center>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    );
}

export default SellerManagement;
