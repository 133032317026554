import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AddItemsToCart } from "../../services/handleCartService";

const initialState = {
    CartCount: (JSON.parse(localStorage.getItem("cartItemCount"))) || 0,
    isLoading: false,
    error: null
};

export const getCartCount = createAsyncThunk(
    'Cart/getCartCount',
    async () => {
      try {
        // Fetch the cart count from local storage
        const cartCount = JSON.parse(localStorage.getItem("cartItemCount")) || 0;
        return cartCount;
      } catch (error) {
        // Handle errors if any
        throw Error('Error fetching cart count');
      }
    }
  );

const CartSlice = createSlice({
    name: "Cart",
    initialState,
    reducers: {
        setInitCartDetail: (state) => {
            state.CartCount = 0;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCartCount.pending, (state, action) => {
            state.isLoading = true;
        });

        builder.addCase(getCartCount.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CartCount = action.payload;
        });
        builder.addCase(getCartCount.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message
        });
    },
});

const { reducer } = CartSlice;
export const { setInitCartDetail } = CartSlice.actions;
export default reducer;
