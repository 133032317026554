import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Loader from "../Common/Loader";
import checkPageUrl from "../../helpers";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css";
import "./ProductCategory.css"

function ProductCategory() {
  const [productCategory, setProductCategory] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();
  let urlFlag;
  urlFlag = checkPageUrl(window.location.hostname);


  const content = useSelector((state) => state.Home);


  const product = async () => {
    setIsLoader(true);
    try {
      setProductCategory(content.ProductCategories);
    } catch (error) {
      console.log("Something went wrong", error.message);
    }
    setIsLoader(false);
  };

  const toCategoryPage = (id) => {
    setIsLoader(true);
    if (urlFlag === true && content?.HomeDetail?.sellerId) {
      navigate(`/${localStorage.getItem("cityName")}/${localStorage.getItem("userTypeName")}/category/${id}`)
    } else if (urlFlag === false && content?.sellerId) {
      navigate(`/category/${id}`);
    }

    setIsLoader(false);
  };

  useEffect(() => {
    product();
  }, [content]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1023 },
      items: 9,
      slidesToSlide: 1// optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 6,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 3,
      slidesToSlide: 2 // optional, default to 1.
    },
    smallMobile: {
      breakpoint: { max: 463, min: 100 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    }
  };
  return (
    <>
      <div className="container">
        <div className="frame-wrapper1">
          <div className="categories-parent">
            <Carousel
              responsive={responsive}
              autoPlay={true}
              swipeable={true}
              draggable={true}
              showDots={true}
              infinite={true}
              partialVisible={false}
              dotListClass="custom-dot-list-style"
            >
              {productCategory.map((category, index) => {
                if (category.id !== 0) {
                  return (
                    <div className="slider categories" key={index} onClick={() => toCategoryPage(category.id)}>
                      <img src={category.imageURL} alt={category.name} className="categories-child" />
                      <div className="categories-wrapper">
                        <div className="categories1">{category.name}</div>
                      </div>
                    </div>
                  );
                } else {
                  return null; // If index is 0, don't render anything
                }
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCategory;
