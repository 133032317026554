import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../Modal/DeleteModal";

export default function DeleteAskModal({
  show,
  handleClose,
  apiCall,
  id,
  productListHandler,
}) {
  const [modalShow, setModalShow] = useState(false);
  const handleContinue = () => {
    setModalShow(false);
    productListHandler();
  };

  const handleApi = async () => {
    try {
      const response = await apiCall(id);
      if (response.statusCode == 204) {
        handleClose();
        setModalShow(true);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      handleClose();
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <DeleteModal show={modalShow} handleContinue={handleContinue} />
      <ToastContainer />
      <Modal centered={true} animation={true} show={show} onHide={handleClose}>
        {/* <div className="modal fade show"> */}
        <div
          className="modal fade show"
          id="deletextra"
          tabIndex="-1"
          aria-modal="true"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body p-3 py-5">
                <button
                  type="button"
                  className="btn-close position-absolute top-0 end-0 mt-3 me-3"
                  onClick={handleClose}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="info-icon">
                  <p>!</p>
                </div>
                <p className="text-muted small mb-4">
                  Are you sure you want to delete this product ?
                </p>
                <div className="modal-buttons">
                  <button
                    className="btn btn-danger me-2"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleApi}
                  >
                    Yes, Delete
                  </button>
                  <button
                    className="btn btn-outline-success"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
