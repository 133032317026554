import { createSlice } from "@reduxjs/toolkit";
// global state
const initialState = {
    isAuthenticated: !!localStorage.getItem('token'),
    isDaksh: (localStorage.getItem('isDaksh') === "false" ? false : true),
    sellerId: localStorage.getItem('sellerId')
};

const authSlice = createSlice({
  name: "auth",
  initialState,

  // modifying global state
  reducers: {
    setLogin: (state, action) => {
        state.isAuthenticated = true;
        state.isDaksh = action.payload.isDaksh;
        state.sellerId = action.payload.sellerId;

    },
  },
});

export const { setLogin } = authSlice.actions;
export default authSlice.reducer;
