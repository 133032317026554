import React from 'react'
import { Modal, Dropdown } from 'react-bootstrap';
import { useState } from "react";
import { DatePicker, Space } from 'antd';
import '../Coupon/Coupon.css'
import Select from "react-select";

const { RangePicker } = DatePicker;

const AddCouponModal = ({ show, handleClose }) => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedDates, setSelectedDates] = useState([]);

    const handleDateChange = (dates) => {
        // Extract start and end dates from the dates array
        const [startDate, endDate] = dates;
    
        // Convert the start date to a JavaScript Date object
        const jsStartDate = new Date(startDate.$d);
    
        // Calculate the end date by adding one day to the start date
        const jsEndDate = new Date(endDate.$d);
        jsEndDate.setDate(jsEndDate.getDate() + 1);
    
        // Log the extracted start and end dates
        console.log('Start Date:', jsStartDate);
        console.log('End Date:', jsEndDate);
    
        // Set the selected dates in the state
        setSelectedDates(dates);
    };

    const renderEstimatedPoint = () => {
        const estimationPoints = [
            { label: "All", value: "0" },
            { label: "Rock-Pot", value: "1" },
            { label: "Mini Square Bonsai", value: "2" },
            { label: "Fence Around", value: "3" },
        ];

        return estimationPoints;
    };

    const handleSubmit = () => {
        const [startDate, endDate] = selectedDates;
        console.log('Start Date:', startDate);
        console.log('End Date:', endDate);
        handleClose();
    };


    const valueType = [
        { value: '0', label: 'Select' },
        { value: '1', label: 'Amount' },
        { value: '2', label: 'Percentage' }
    ];

    return (
        <div>
            <Modal show={show} onHide={handleClose}  >
                <Modal.Header closeButton>
                    <Modal.Title>Add Coupon</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="row">
                        <div className="col-4">
                            <span className="mx-3">Name</span>
                            <input
                                type="text" class="form-control"
                                aria-label="Text input with checkbox"
                                placeholder='Name'
                                maxLength={150}
                            />
                        </div>
                        <div className="col-4">
                            <span className="mx-2">Coupon Code</span>
                            <input
                                type="text" class="form-control mx-2 "
                                aria-label="Text input with checkbox"
                                placeholder='#001'
                                maxLength={150}
                            />
                        </div>
                        <div className="col-4">
                            <span>Coupon Type</span>
                            <select
                                name=""
                                id=""
                                className="form-select form-control"
                                required>
                                <option value="0">Select</option>
                                <option value="0">Discount</option>
                                <option value="0">Gift</option>
                            </select>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-4 ">
                            <span>Value Type</span>
                            <select
                                name=""
                                id=""
                                className="form-select form-control"
                                required>
                                <option value="0">Select</option>
                                <option value="1">Amount</option>
                                <option value="2">Percentage</option>
                            </select>
                        </div>
                        <div className="col-4">
                            <span className="mx-2">Value</span>
                            <input
                                type="text" class="form-control mx-2 "
                                aria-label="Text input with checkbox"
                                placeholder=''
                                maxLength={150}
                            />
                        </div>
                        <div className="col-4 ">
                            <span>Discount For</span>
                            <select
                                name=""
                                id=""
                                className="form-select form-control"
                                required>
                                <option value="0">Select</option>
                                <option value="1">All</option>
                                <option value="2">Special</option>
                            </select>
                        </div>
                    </div>
                    <div className="row mt-2 mx-1">
                        <span>Coupon Text</span>
                        <input
                            type="text" class="form-control mx-2 "
                            aria-label="Text input with checkbox"
                            placeholder=''
                            style={{ width: '450px' }}
                        />
                    </div>
                    <div className="row mt-2">
                        <div className="col-4">
                            <label for="category" class="form-label">Select Products</label>
                            <Select options={renderEstimatedPoint()} isMulti={true} />
                        </div>
                        <div className="col-4">
                            <span className="mx-2">Select Categories</span>
                            <Select options={renderEstimatedPoint()} isMulti={true} />
                        </div>
                        <div className="col-4">
                            <span className="mx-2">Validity</span>
                            <div id="date-picker-wrapper">
                                <Space direction="vertical" size={12}>
                                    <RangePicker className='mt-2' onChange={handleDateChange} value={selectedDates} />
                                </Space>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleClose}>Cancel</button>
                    <button className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddCouponModal
