import ApiConstants from "../Constants/ApiConstants";
import { apiDelete, apiGetWithoutAuth, apiPost } from "./apiCall";

export const GetValidateOtp = async (mobileNumber,sellerId) => {
    const response = await apiGetWithoutAuth(`${ApiConstants.OTP_VALIDATE_MOBILE_FOR_CHECKOUT}?mobileNumber=${mobileNumber}&sellerId=${sellerId}`);
    return response;
};

export const GetCustomerRequestForRequestOrOrder = async (data) => {

    const response = await apiPost(`${ApiConstants.CUSTOMER_REQUEST_FOR_QUOTE_OR_ORDER}`, data)
    return response;
}

export const GetVerifyOtp = async (referenceNumber, otp) => {
    const response = await apiGetWithoutAuth(`${ApiConstants.VERIFY_OTP}?referenceNumber=${referenceNumber}&otp=${otp}`)
    return response;
}

export const DeleteAddress = async (id, mobileNumber) => {
    const response = await apiDelete(`${ApiConstants.DELETE_CUSTOMER_ADDRESS}?addressId=${id}&mobileNumber=${mobileNumber}`)
    return response;
}

