import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getVendor } from "../../services/vendorService";

const initialState = {
  vendorDetail: {},
  isLoading: false,
  state: null
};

export const getVendorDetailApi = createAsyncThunk(
  "vendor/getVendorDetail",
  async (currentUrl) => {
    const res = await getVendor(currentUrl);
    const data = await res.data;
    return data.result;
  }
);

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setInitVendorDetail: (state) => {
      state.vendorDetail = {};
 
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVendorDetailApi.pending, (state, action) => {
        state.isLoading = true;
      });

    builder.addCase(getVendorDetailApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.vendorDetail = action.payload;
    });
    builder.addCase(getVendorDetailApi.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message
      })
  },
});

const { reducer } = vendorSlice;
export const { setInitVendorDetail } = vendorSlice.actions;
export default reducer;