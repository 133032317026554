import React, { useEffect, useState } from "react";
import DragAndDropFileUpload from "../Common/DragAndDropFileUpload";
import { useSelector } from "react-redux";
import {
  apiDeleteBannerImages,
  apiGetBannerImages,
  apiPostBannerImages,
  apiUpdateBannerImages,
} from "../../services/adminService";
import { convertToBase64, validateFileSize } from "../../schema/ImageBase64";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../Common/Loader";
import {
  GetOriginalArraycount,
  bannerValidation,
  convertStructureOfConfigurationBanner,
} from "../../helpers/convertBodyStructure";

const Banner = () => {
  const sellerId = useSelector((state) => state.auth.sellerId);
  const [pageAction, setPageAction] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [productMedia, setProductMedia] = useState([
    {
      id: 0,
    },
    {
      id: 0,
    },
    {
      id: 0,
    },
    {
      id: 0,
    },
  ]);
  const defaultObject = {
    id: 0,
    url: "",
    mobileImageUrl: "",
    redirectPath: "",
    bannerText: "",
  };

  useEffect(() => {
    setPageAction(
      convertStructureOfConfigurationBanner(productMedia)?.length > 0
        ? "edit"
        : "save"
    );
  }, [productMedia]);

  useEffect(() => {
    getBannerImages(sellerId);
  }, [sellerId]);

  const handleSave = async () => {
    const tempBody = convertStructureOfConfigurationBanner(productMedia);
    setIsLoader(true);
    try {
      const body = {
        bannerImages: tempBody,
      };
      let validationErrors = [];
      validationErrors = bannerValidation(tempBody);
      if (validationErrors.length > 0) {
        validationErrors?.map((data, i) => {
          toast.error(data.ErrorMessage);
          // }
        });
      } else {
        const response =
          pageAction === "edit"
            ? await apiUpdateBannerImages(body, sellerId)
            : await apiPostBannerImages(body, sellerId);
        if (response.isError) {
          toast.error("Something went wrong! Please try again");
        } else {
          setIsLoader(true);
          getBannerImages(sellerId);
          toast.success(response?.message);
          // getBannerImages(sellerId);
          // setRequestBody([]);
        }
      }
    } catch (error) {
      toast.error(
        "Please fill in all required fields for the banners you have selected"
      );
    }
    setIsLoader(false);
  };

  const getBannerImages = async (id) => {
    try {
      const response = await apiGetBannerImages(id);
      const updatedResponse = response.map((element, index) => ({
        ...element,
        bannerId: `Banner_${index + 1}`,
        mobileBase64: element.mobileImageUrl,
        redirectUrl: element.redirectPath,
      }));
      setProductMedia();
      setPageAction(updatedResponse?.length > 0 ? "edit" : "save");
      const tempArray = [...productMedia];
      updatedResponse.forEach((d, i) => {
        tempArray[i] = d;
      });
      setProductMedia(tempArray);
    } catch (error) {
      console.log("Error fetching banner images:", error);
    }
  };

  const handleImageDelete = (i, id) => {
    const tempArray = [...productMedia];
    tempArray[i] = { ...defaultObject, id: 0, base64: "", url: "" };
    if (id > 0) handleDeleteApi(id);
    setProductMedia(tempArray);
  };

  const setBannerText = (e, i) => {
    const { value } = e.target;
    const tempArray = [...productMedia];
    tempArray[i].bannerText = value;
    setProductMedia(tempArray);
  };

  const setRedirectUrl = (e, i) => {
    const { value } = e.target;
    const tempArray = [...productMedia];
    tempArray[i].redirectPath = value;
    setProductMedia(tempArray);
  };

  const fileUploadHandler = async (e, i) => {
    const base64 = await convertToBase64(e, "any");
    const tempArray = [...productMedia];
    tempArray[i] = {
      ...defaultObject,
      ...tempArray[i],
      base64: base64,
      mobileBase64: base64,
    };
    setProductMedia(tempArray);
  };

  const handleDeleteApi = async (imageId) => {
    try {
      const response = await apiDeleteBannerImages(sellerId, imageId);
      response.statusCode = 200
        ? toast.success("Image deleted Success")
        : toast.error("Some error occured");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      {isLoader && <Loader />}
      <ToastContainer />
      <div className="row-title" style={{ marginLeft: "-6px" }}>
        <div>
          <h6 className="fw-bold mx-4 mt-4">Add Banner</h6>
          <div className="card m-4" style={{ borderRadius: "0" }}>
            <div className="card-body">
              <div className="row d-flex mt-5">
                {productMedia?.map((d, i) => (
                  <div
                    key={i}
                    className="col-3 py-2 mx-0"
                    style={{
                      borderRadius: "8px",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      padding: "20px",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    <h3 className="fw-bold text-center">{`Banner ${i + 1} (1920w x 450h)`}</h3>
                    <DragAndDropFileUpload
                      flag={d?.id}
                      thumbnailId={1}
                      show={"hideThumbnail"}
                      url={d?.url || d?.base64}
                      id="image1"
                      number="1"
                      handleDelete={() => handleImageDelete(i, d?.id)}
                      onChange={(e) => fileUploadHandler(e, i)}
                    />
                    <input
                      type="text"
                      className="form-control my-3"
                      id={`BannerText_${i + 1}`}
                      aria-label="Text input with checkbox"
                      placeholder="Banner Text"
                      value={d?.bannerText}
                      onChange={(e) => setBannerText(e, i)}
                      title="Please select an image."
                      disabled={!d.base64 && !d.url ? true : false}
                    />

                    <input
                      type="text"
                      className="form-control"
                      id={`RedirectURL_${i + 1}`}
                      aria-label="Text input with checkbox"
                      placeholder="Redirect Url"
                      value={d?.redirectPath}
                      onChange={(e) => setRedirectUrl(e, i)}
                      title="Please select an image."
                      disabled={!d.base64 && !d.url ? true : false}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-warning mt-3 mx-5"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default Banner;
