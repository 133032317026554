export const checkoutFormValidation = (userDetails, address, customerAddressId) => {
    const ValidationErrors = [];
    let regName = new RegExp("^[A-Za-z0-9]");
    let regNum = new RegExp("^[0-9]{1,}");
    let regMobileNumber = new RegExp(/^\d{10}$/);
    let regEmail = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    if (!regName.test(userDetails.name) || userDetails.name === "") {
        ValidationErrors.push({ Field: "Name", ErrorMessage: "Name should not be empty", isError: true });
    } else {
        ValidationErrors.push({ Field: "Name", ErrorMessage: "Name should not be empty", isError: false });
    }
    if (!regMobileNumber.test(userDetails.mobileNumber) || userDetails.mobileNumber === "" || userDetails.mobileNumber.length > 10 || userDetails.mobileNumber.length < 10) {
        ValidationErrors.push({ Field: "Mobile", ErrorMessage: "Mobile should not be empty ", isError: true });
    } else {
        ValidationErrors.push({ Field: "Mobile", ErrorMessage: "Mobile should not be empty ", isError: false });
    }
    if (!regEmail.test(userDetails.email) || userDetails.email === "" || userDetails.email === 0) {
        ValidationErrors.push({ Field: "Email", ErrorMessage: "Email should not be empty", isError: true });
    } else {
        ValidationErrors.push({ Field: "Email", ErrorMessage: "Email should not be empty", isError: false });
    }
    if (!regNum.test(customerAddressId) || customerAddressId === "" || customerAddressId < 0) {
        ValidationErrors.push({ Field: "CustomerAddressId", ErrorMessage: "Please select an address.", isError: true });
    } else {
        ValidationErrors.push({ Field: "CustomerAddressId", ErrorMessage: "Please select an address.", isError: false });
    }
    address.length > 0 ?
        address.map((d, i) => {
            if (d?.addressLine1 === "" || d?.addressLine1 === 0 || d?.addressLine1?.trim().length < 1) {
                ValidationErrors.push({ Field: `AddressLine1[${i}]`, ErrorMessage: `AddressLine1 should not be empty`, isError: true });
            } else {
                ValidationErrors.push({ Field: `AddressLine1[${i}]`, ErrorMessage: `AddressLine1 should not be empty`, isError: false });
            }
            if (!regNum.test(d?.state) || d?.state === "" || d?.state === 0) {
                ValidationErrors.push({ Field: `State[${i}]`, ErrorMessage: `State should not be empty`, isError: true });
            } else {
                ValidationErrors.push({ Field: `State[${i}]`, ErrorMessage: `State should not be empty`, isError: false });
            }
            if (d?.pincode === "" || d?.pincode === 0 || d.pincode?.trim().length < 6) {
                ValidationErrors.push({ Field: `Pincode[${i}]`, ErrorMessage: `Pincode should not be empty`, isError: true });
            } else {
                ValidationErrors.push({ Field: `Pincode[${i}]`, ErrorMessage: `Pincode should not be empty`, isError: false });
            }
            return null;
        }) : ValidationErrors.push({ Field: `AddressDiv`, ErrorMessage: `Pincode should not be empty`, isError: false })
    return ValidationErrors;
};