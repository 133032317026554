export const sellerOnboardingValidation = (data) => {
    const ValidationErrors = [];
    let regName = new RegExp("^[A-Za-z0-9]");
    let regNum = new RegExp("^[0-9]{1,}");
    let regMobileNumber = new RegExp(/^\d{10}$/);
    let regEmail = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    let regUsername = new RegExp(/^[a-zA-Z0-9_-]{3,16}$/);
    if (!regName.test(data?.name) || data?.name === "") {
        ValidationErrors.push({ Field: "Name", ErrorMessage: "Name should not be empty", isError: true });
    } else {
        ValidationErrors.push({ Field: "Name", ErrorMessage: "Name should not be empty", isError: false });
    }
    if (!regUsername.test(data?.title) || data?.title === "") {
        ValidationErrors.push({ Field: "Title", ErrorMessage: "Name should not be empty", isError: true });
    } else {
        ValidationErrors.push({ Field: "Title", ErrorMessage: "Name should not be empty", isError: false });
    }
    if (!regMobileNumber.test(data?.phone) || data?.phone === "" || data?.phone.length > 10 || data?.phone.length < 10) {
        ValidationErrors.push({ Field: "Phone", ErrorMessage: "Phone should not be empty ", isError: true });
    } else {
        ValidationErrors.push({ Field: "Phone", ErrorMessage: "Phone should not be empty ", isError: false });
    }
    if (!regEmail.test(data?.email) || data?.email === "" || data?.email === 0) {
        ValidationErrors.push({ Field: "Email", ErrorMessage: "Email should not be empty", isError: true });
    } else {
        ValidationErrors.push({ Field: "Email", ErrorMessage: "Email should not be empty", isError: false });
    }
    if (!regUsername.test(data?.username) || data?.username === "" || data?.username === 0) {
        ValidationErrors.push({ Field: "Username", ErrorMessage: "Username should not be empty", isError: true });
    } else {
        ValidationErrors.push({ Field: "Username", ErrorMessage: "Username should not be empty", isError: false });
    }
    if (!regEmail.test(data?.address) || data?.address === "" || data?.address === 0) {
        ValidationErrors.push({ Field: "Address", ErrorMessage: "Address should not be empty", isError: true });
    } else {
        ValidationErrors.push({ Field: "Address", ErrorMessage: "Address should not be empty", isError: false });
    }
    if (!regNum.test(data?.state) || data?.state === "" || data?.state === 0) {
        ValidationErrors.push({ Field: "State", ErrorMessage: "State should not be empty", isError: true });
    } else {
        ValidationErrors.push({ Field: "State", ErrorMessage: "State should not be empty", isError: false });
    }
    if (!regEmail.test(data?.pincode) || data?.pincode === "" || data.pincode?.trim().length < 6) {
        ValidationErrors.push({ Field: "Pincode", ErrorMessage: "Pincode should not be empty", isError: true });
    } else {
        ValidationErrors.push({ Field: "Pincode", ErrorMessage: "Pincode should not be empty", isError: false });
    }
    if (!regEmail.test(data?.marginPercentage) || data?.marginPercentage === "" || data?.marginPercentage === 0) {
        ValidationErrors.push({ Field: "Margin-Percentage", ErrorMessage: "Margin Percentage should not be empty", isError: true });
    } else {
        ValidationErrors.push({ Field: "Margin-Percentage", ErrorMessage: "Margin Percentage should not be empty", isError: false });
    }
    if (!regNum.test(data?.minimumSellingAmount) || data?.minimumSellingAmount === "" || data?.minimumSellingAmount === 0) {
        ValidationErrors.push({ Field: "MinimumSellingAmount", ErrorMessage: "Minimum Selling Amount should not be empty.", isError: true });
    } else {
        ValidationErrors.push({ Field: "MinimumSellingAmount", ErrorMessage: "Minimum Selling Amount should not be empty.", isError: false });
    }
    if (!regNum.test(data?.webUrl) || data?.webUrl === "" || data?.webUrl === 0) {
        ValidationErrors.push({ Field: "Domain", ErrorMessage: "Margin Percentage should not be empty", isError: true });
    } else {
        ValidationErrors.push({ Field: "Domain", ErrorMessage: "Margin Percentage should not be empty", isError: false });
    }
    return ValidationErrors;
};